import React, { useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import InputField from "../../../../components/utils/InputComponent";

const VariantDetails = (props) => {
  const { item, index, register, errors, variantRemove } = props;
  return (
    <React.Fragment>
      <Row>
        <Col md="6" lg="6">
          <InputField
            inputName={`productVariants[${index}].name`}
            labelText="Name"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col md="3" lg="3">
          <InputField
            inputName={`productVariants[${index}].baseRate`}
            labelText="Base Price"
            placeholder="0000"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col md="3" lg="3">
          <InputField
            inputName={`productVariants[${index}].finalRate`}
            labelText="Final Price"
            placeholder="0000"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>{" "}
      {index >= 0 ? (
        <Button
          color="danger"
          className="mb-3"
          onClick={() => variantRemove(index)}
        >
          {" "}
          Delete Varaint
        </Button>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default VariantDetails;
