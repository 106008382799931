import React, { useEffect, useState, useLayoutEffect } from "react";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";

const Layout = (props) => {
  const { menuData, routes, hideSidebar = false } = props;

  //Sidebar
  const [mobileView, setMobileView] = useState();
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    main: "default",
    sidebar: "white",
    header: "white",
    skin: "light",
  });

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === "dark" ? "dark-mode" : ""
    }`;
  }, [themeState.skin]);

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!visibility) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener("load", viewChange);
  window.addEventListener("resize", viewChange);

  const sidebarClass = classNames({
    "nk-sidebar-mobile": mobileView,
    "nk-sidebar-active": visibility && mobileView,
  });

  function renderSidebar() {
    if (hideSidebar) return null;

    return (
      <React.Fragment>
        <Sidebar
          sidebarToggle={toggleSidebar}
          fixed
          menuData={menuData}
          mobileView={mobileView}
          theme={themeState.sidebar}
          className={sidebarClass}
        />
        {visibility && mobileView && (
          <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="nk-main">
          {renderSidebar()}
          <div className="nk-wrap">
            <Header
              sidebarToggle={toggleSidebar}
              fixed
              setVisibility={setVisibility}
              theme={themeState.header}
            />
            {routes}
            <Footer />
          </div>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
