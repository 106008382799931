const EGG_TRACKING_APP_PERMISSIONS = {
  READ_EGG_TRACKING: "READ_EGG_TRACKING",
};

const CORE_APP_PERMISSIONS = {
  CREATE_USER: "CREATE_USER",
  READ_USER: "READ_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
};

const HOTEL_APP_PERMISSIONS = {
  CREATE_TEAM_MEMBER: "CREATE_TEAM_MEMBER",
  READ_TEAM_MEMBER: "READ_TEAM_MEMBER",
  UPDATE_TEAM_MEMBER: "UPDATE_TEAM_MEMBER",
  DELETE_TEAM_MEMBER: "DELETE_TEAM_MEMBER",
  CREATE_STOCK_POINT: "CREATE_STOCK_POINT",
  LIST_STOCK_POINT: "LIST_STOCK_POINT",
  CREATE_VEHICLE: "CREATE_VEHICLE",
};

export const PERMISSIONS = {
  ...CORE_APP_PERMISSIONS,
  ...HOTEL_APP_PERMISSIONS,
  ...EGG_TRACKING_APP_PERMISSIONS,
};
