import { BlockHeadContent, BlockTitle } from "../../../../components/Component";
import { Card, Row } from "reactstrap";
import "./style.css";

const DashboardItemCountList = (props) => {
  const { itemCount } = props;
  return (
    <>
      <BlockHeadContent>
        <BlockTitle className="mt-3 mb-3" style={{ color: "deepskyblue" }} small>
            Highest Order{" "}
            <span style={{ color: "deepskyblue" }}>(itemwise)</span>
        </BlockTitle>
      </BlockHeadContent>
      <Row className="mt-3 mb-2">
        {itemCount &&
          Object.keys(itemCount).map((itemKey) => {
            return (
              <div key={itemKey} className="col-md-2 mb-3">
                <Card className="m-0" style={{ borderRadius: "10px" }}>
                  <div className="team ">
                    <div className="user-card user-card-s2 ">
                      <span>{itemKey}</span>
                      <p
                        style={{ fontWeight: "bolder", color: "blue" }}
                      >{`${itemCount[itemKey].value} ${itemCount[itemKey].unit}`}</p>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
      </Row>
    </>
  );
};
export default DashboardItemCountList;
