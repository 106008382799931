import React from "react";
import { PERMISSIONS } from "../../../../config/AppPermission";
import useAuthorization from "../../../../hooks/useAuthorization";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import StockPointCreatePage from "./StockPointCreatePage";
import { withProvider } from "../../../../hoc/withProvider";
import StockpointProvider from "../../providers/StockpointProvider";

const StockpointCreateContainer = (props) => {
  return (
    <React.Fragment>
      <Head title="Create Stockpoint | Core App" />
      <Content>
        <StockPointCreatePage />
      </Content>
    </React.Fragment>
  );
};
export default withProvider(StockpointProvider, StockpointCreateContainer);
