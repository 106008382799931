/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Badge, ListGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../../components/Component";
import CustomTable from "../../../../components/table/CustomTable";
import Guard from "../../../../components/Guard";
import { PERMISSIONS } from "../../../../config/AppPermission";
import { withProvider } from "../../../../hoc/withProvider";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import VehicleMasterProvider, {
  VehicleMasterContext,
} from "../../providers/VehicleMasterProvider";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { format } from "date-fns";

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

const VehicleMasterPage = (props) => {
  const vehicleMasterContext = React.useContext(VehicleMasterContext);

  const page = 1,
    size = 10;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 10);
  const [searchTerm, setSearchTerm] = useState("");

  const [sm, updateSm] = React.useState(false);

  const vehicles = vehicleMasterContext.vehiclesList.vehicles || [];
  const renderActionButtons = (row) => {
    return (
      <div style={{ display: "block", width: "100%" }}>
        <ListGroup>
          <a href={`/hotels/vehicle-masters/${row.id}/edit`}>
            <Button color="warning">Edit</Button>
          </a>
        </ListGroup>
      </div>
    );
  };

  /** @type {import("react-data-table-component").TableColumn[]} */
  const dataTableColumns = [
    {
      name: "Slno",
      selector: (row) => row.id,
      width: "75px",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "Vehicle Name",
      selector: (row) => row.name,
      sortable: true,
      width: "150px"
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicleNumber,
      sortable: true,
      width: "150px"
    },
    {
      name: "Chassis Number",
      selector: (row) => row.chassisNumber,
      width: "150px"
    },
    {
      name: "Vehicle Type",
      selector: (row) => row.vehicleType,
      width: "150px"
    },
    {
      name: "Insurance Expiry Dt",
      selector: (row) => row.insuranceExpiryDate,
      cell: (row) => renderDateFormat(row.insuranceExpiryDate),
      width: "150px"
    },
    {
      name: "Last Service Dt",
      selector: (row) => row.lastServiceDate,
      cell: (row) => renderDateFormat(row.lastServiceDate),
      width: "150px"
    },
    {
      name: "PUC Expiry Dt",
      selector: (row) => row.pucExpiryDate,
      cell: (row) => renderDateFormat(row.pucExpiryDate),
      width: "150px"
    },
    {
      name: "Stockpoint",
      selector: (row) => row?.vehicleStockpoint?.name,
      width: "150px"
    },
    {
      name: "Maximum Weight Limit",
      selector: (row) => row.weightLimit,
    },
    {
      name: "Buffer Limit",
      selector: (row) => row.weightBuffer,
    },
    {
      name: "GPS",
      selector: (row) => row.gpsInstalled,
      cell: (row) => (row?.gpsInstalled ? <span>Yes</span> : <span>No</span>),
    },
    {
      name: "Expiry Date Alert",
      selector: (row) => row.insuranceExpiryDate,
      width: "170px",
      cell: (row) => renderExpiryDateAlert(row.insuranceExpiryDate),
    },
    {
      name: "Action",
      selector: (row) => row,
      cell: (row) => renderActionButtons(row),
    },
  ];

  React.useEffect(() => {
    vehicleMasterContext.getVehicles({
      page: currentPage,
      size: currentPageSize,
    });
  }, []);

  const renderDateFormat = (date) => {
    return format(new Date(date || null), "dd/MMM/yyyy");
  };

  const renderExpiryDateAlert = (date) => {
    const today = new Date();
    const expiryDate = new Date(date);
    const days =
      expiryDate >= today
        ? Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24))
        : Math.floor((today - expiryDate) / (1000 * 60 * 60 * 24));
    return expiryDate <= today ? (
      <Badge color="danger">{`Expired ${days} days back`}</Badge>
    ) : (
      <Badge color="success">{`Expiring in ${days} days`}</Badge>
    );
  };
  const totalVehicles = vehicleMasterContext.vehiclesList.total || 0;

  function handlePageChange(page) {
    setCurrentPage(page);

    vehicleMasterContext.getVehicles({
      page: page,
      size: currentPageSize,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);

    vehicleMasterContext.getVehicles({
      page: currentPage,
      size: showSize,
    });
  }

  function handleSort(sort) {
    console.log("sort :>> ", sort);
  }

  function handleSearchChange(searchText) {
    setSearchTerm(searchText)
    vehicleMasterContext.getVehicles({
      search: searchText,
      page: currentPage,
      size: currentPageSize,
    });
  }
  
  function renderVehicleList() {
    return (
      <Block>
        <CustomTable
          data={vehicles}
          columns={dataTableColumns}
          pagination
          onSort={handleSort}
          onSearchChange={handleSearchChange}
          onPageChange={handlePageChange}
          pageShowSize = {currentPageSize}
          currentPageNum={currentPage}
          onShowSizeChange={handleShowSizeChange}
          totalItems={vehicleMasterContext.vehiclesList.total || 0}
          searchTerm={searchTerm}
        />
      </Block>
    );
  }

  function renderMainContent() {
    if (vehicleMasterContext.isLoadingVehicles) {
      return <Spinner />;
    }

    return renderVehicleList();
  }

  function renderCreateVehicleButton() {
    return (
      <BlockHeadContent>
        <div className="toggle-wrap nk-block-tools-toggle">
          <Button
            className={`btn-icon btn-trigger toggle-expand mr-n1 ${
              sm ? "active" : ""
            }`}
            onClick={() => updateSm(!sm)}
          >
            <Icon name="menu-alt-r"></Icon>
          </Button>
          <div
            className="toggle-expand-content"
            style={{ display: sm ? "block" : "none" }}
          >
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <NavLink to={HOTEL_ROUTE.vehicleMasterCreate}>
                  <Button color="success">
                    <span>Add Vehicle</span>
                    <Icon className="pl-0" name="plus-sm" />
                  </Button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </BlockHeadContent>
    );
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Vehicles List</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalVehicles} vehicles.</p>
            </BlockDes>
          </BlockHeadContent>
          <Guard permittedActions={[PERMISSIONS.CREATE_VEHICLE]}>
            {renderCreateVehicleButton()}
          </Guard>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="Vehicle Master | Core App" />
      <Content>
        {renderHeaderSection()}
        {renderMainContent()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(VehicleMasterProvider, VehicleMasterPage);
