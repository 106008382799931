import React from "react";

const SelectField = (props) => {
  const {
    labelText,
    inputName,
    inputType,
    register,
    errors,
    options,
    handleChange,
    defaultValue,
    value,
    handleClick,
    required
  } = props;
  return (
    <div className="form-control-wrap mb-3">
      <div className="form-control-select">
        <div className="input-group">
          {
            labelText && <div className="input-group-prepend">
              <span
                className="input-group-text bg-white"
                id="inputGroup-sizing-lg"
              >
                {labelText}
                {required ? <span className="text-danger">*</span> : ""}
              </span>
            </div>
          }
          <select
            className="form-control custom-select"
            type={inputType}
            name={inputName}
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            onClick={handleClick}
            {...(register && register(inputName))}
          >
            {options.map((option) => {
              return <option key={option.value} value={option.value}>{option.name}</option>;
            })}
          </select>

          {errors && errors[inputName] && (
            <span className="invalid">{errors[inputName].message}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectField;
