/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { NavLink } from "react-router-dom";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  RSelect,
} from "../../../../../components/Component";
import CustomTable from "../../../../../components/table/CustomTable";
import Guard from "../../../../../components/Guard";
import { PERMISSIONS } from "../../../../../config/AppPermission";
import { withProvider } from "../../../../../hoc/withProvider";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import UserManagementProvider, {
  UserManagementContext,
} from "../../../providers/UserManagementProvider";
import { CORE_APP_ROUTES } from "../../../routes/CoreAppRoute";

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

const UserManagementContainer = (props) => {
  const userManagementContext = React.useContext(UserManagementContext);

  const [sm, updateSm] = React.useState(false);

  const users = userManagementContext.usersList.users;

  /** @type {import("react-data-table-component").TableColumn[]} */
  const dataTableColumns = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      cell: renderUsername,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      cell: renderPhoneNumber,
    },
    {
      name: "Roles",
      cell: renderUserRole,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];

  React.useEffect(() => {
    userManagementContext.getUsers();
  }, []);

  const totalUsers = userManagementContext.usersList.total || 0;

  function handlePageChange(page) {
    console.log("page :>>> ", page);
  }

  function handleShowSizeChange(showSize) {
    console.log("showSize :>> ", showSize);
  }

  function handleSort(sort) {
    console.log("sort :>> ", sort);
  }

  function handleApplyAction(action, rows) {
    console.log("action :>> ", action);
    console.log("rows :>> ", rows);
  }

  function handleSearchChange(searchTerm) {
    console.log("searchTerm :>> ", searchTerm);
  }

  function renderFilters() {
    return (
      <Row className="gx-6 gy-3">
        <Col size="6">
          <div className="custom-control custom-control-sm custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input form-control"
              id="hasBalance"
            />
            <label className="custom-control-label" htmlFor="hasBalance">
              {" "}
              Have Balance
            </label>
          </div>
        </Col>
        <Col size="6">
          <div className="custom-control custom-control-sm custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input form-control"
              id="hasKYC"
            />
            <label className="custom-control-label" htmlFor="hasKYC">
              {" "}
              KYC Verified
            </label>
          </div>
        </Col>
        <Col size="6">
          <FormGroup>
            <label className="overline-title overline-title-alt">Role</label>
            <RSelect options={filterRole} placeholder="Any Role" />
          </FormGroup>
        </Col>
        <Col size="6">
          <FormGroup>
            <label className="overline-title overline-title-alt">Status</label>
            <RSelect options={filterStatus} placeholder="Any Status" />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  function renderUserRole(u) {
    const allRoles = u.roles.map((ur) => ur.role.name).flat();

    return allRoles.slice(0, 3).map((ar) => (
      <span
        key={`user-manage-role-${ar}`}
        className="badge badge-dim bg-gray-200 mr-1"
      >
        {ar}
      </span>
    ));
  }

  function renderPhoneNumber(u) {
    return (
      <span>
        {u.countryCode} {u.phoneNumber}
      </span>
    );
  }

  function renderUsername(u) {
    return (
      <div className="user-info">
        <span className="tb-lead">
          <span
            className={`dot dot-${
              u.status === "ACTIVE"
                ? "success"
                : u.status === "SUSPENDED"
                ? "warning"
                : "danger"
            } mr-1`}
          />
          {u.firstName} {u.lastName}
        </span>
        <span>{u.email}</span>
      </div>
    );
  }

  function renderUserList() {
    return (
      <Block>
        <CustomTable
          data={users}
          columns={dataTableColumns}
          expandableRows
          pagination
          selectableRows
          onSearchChange={handleSearchChange}
          bulkActions={filterRole}
          onSort={handleSort}
          onPageChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          totalItems={userManagementContext.usersList.total}
          onBulkActionApply={handleApplyAction}
          renderFilters={renderFilters}
          onSaveFilter={() => {}}
          onResetFilter={() => {}}
        />
      </Block>
    );
  }

  function renderMainContent() {
    if (userManagementContext.isLoadingUsers) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="m-2" size={"xl"} />
        </div>
      );
    }

    return renderUserList();
  }

  function renderCreateUserButton() {
    return (
      <BlockHeadContent>
        <div className="toggle-wrap nk-block-tools-toggle">
          <Button
            className={`btn-icon btn-trigger toggle-expand mr-n1 ${
              sm ? "active" : ""
            }`}
            onClick={() => updateSm(!sm)}
          >
            <Icon name="menu-alt-r"></Icon>
          </Button>
          <div
            className="toggle-expand-content"
            style={{ display: sm ? "block" : "none" }}
          >
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <NavLink to={CORE_APP_ROUTES.userManageCreate}>
                  <Button color="primary" className="btn-icon">
                    <Icon name="plus"></Icon>
                  </Button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </BlockHeadContent>
    );
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Users List</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalUsers} users.</p>
            </BlockDes>
          </BlockHeadContent>

          <Guard permittedActions={[PERMISSIONS.CREATE_USER]}>
            {renderCreateUserButton()}
          </Guard>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="User Management | Core App" />
      <Content>
        {renderHeaderSection()}
        {renderMainContent()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(UserManagementProvider, UserManagementContainer);
