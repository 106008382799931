import React, { useState, useEffect, useContext, useMemo } from "react";
import Content from "../../../../layout/content/Content";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  Row,
} from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import CustomTable from "../../../../components/table/CustomTable";
import ButtonGroup from "../../../../components/utils/TopButtons";
import EditableCell from "../../../../components/utils/EditableCell";
import {
  MasterRateCardContext,
} from "../../providers/MasterRateCardProvider";
import { renderUnits, unitOptions } from "../master_rate_card/Constants";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast } from "../../../../components/toastify/Toastify";
import { useHistory, useParams } from "react-router";
import HotelCustomerProvider, {
  HotelCustomerContext,
} from "../../providers/HotelCustomerProvider";
import { withProvider } from "../../../../hoc/withProvider";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import SelectField from "../../../../components/utils/SelectComponent";

const CustomerRateCard = () => {
  const { id: hotelId } = useParams();
  const history = useHistory();
  const [editingUnitFor, setEditingUnitFor] = useState(null);

  const masterRateCardContext = useContext(MasterRateCardContext);
  const hotelCustomerContext = useContext(HotelCustomerContext);

  const masterRateCardData = useMemo(() => {
    return hotelCustomerContext.masterRateCardData || []
  }, [hotelCustomerContext.masterRateCardData]);

  const categoryList = masterRateCardContext.categoryList;
  const [categorySelection, setCategorySelection] = useState("all");

  const [updateFields, setUpdateFields] = useState([]);
  const [deleteFields, setDeleteFields] = useState([]);

  useEffect(() => {
    hotelCustomerContext.getCustomerRateCard(hotelId, {
      category: categorySelection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId]);

  useEffect(() => {
    const customerRateCardData = hotelCustomerContext.customerRateCardData || [];
    masterRateCardData.forEach(function (mr) {
      customerRateCardData.forEach(function (cr) {
        if (cr.hotelProductsVariantId === mr.id) {
          mr.baseRate = cr.baseRate;
          mr.finalRate = cr.finalRate;
          mr.variantToProduct.unit = cr.unit || mr.variantToProduct?.unit;
          mr.unit = mr.variantToProduct.unit;
          mr.edit = true;
        }
      });
    });
  }, [masterRateCardData, hotelCustomerContext.customerRateCardData]);

  const handleBasePriceSave = (e, row) => {
    if (e.value > 0) {
      const index = updateFields.findIndex((field) => field.id === row.id);
      if (index !== -1) {
        updateFields[index].baseRate = e.value;
      } else {
        const obj = {
          id: row.id,
          baseRate: e.value,
          finalRate: row.finalRate,
          unit: row.unit,
        };
        const arrayOfFields = [...updateFields, obj];
        setUpdateFields(arrayOfFields);
      }
    }
  };

  const handleFinalPriceSave = (e, row) => {
    const deleteIndex = deleteFields.findIndex((field) => field === row.id);
    const updateIndex = updateFields.findIndex((field) => field.id === row.id);
    if (e.value > 0) {
      if (deleteIndex !== -1) {
        let arrayOfIds = deleteFields.slice();
        arrayOfIds.splice(deleteIndex, 1);
        setDeleteFields(arrayOfIds);
      }
      if (updateIndex !== -1) {
        updateFields[updateIndex].finalRate = e.value;
      } else {
        const obj = {
          id: row.id,
          baseRate: row.baseRate,
          finalRate: e.value,
          unit: row.unit,
        };
        const arrayOfFields = [...updateFields, obj];
        setUpdateFields(arrayOfFields);
      }
    }
  }

  const handleUnitChangeSave = (newUnit, row) => {
    console.log("unit change", newUnit, row)
    const index = updateFields.findIndex((field) => field.id === row.id);
    if (index !== -1) {
      updateFields[index].unit = newUnit;
    } else {
      const obj = {
        id: row.id,
        baseRate: row.baseRate,
        finalRate: row.finalRate,
        unit: newUnit,
      };
      const arrayOfFields = [...updateFields, obj];
      setUpdateFields(arrayOfFields);
      const mRateCard = masterRateCardData.find((mr) => mr.id === row.id);
      if (mRateCard) {
        mRateCard.variantToProduct.unit = newUnit;
        mRateCard.unit = newUnit;
      }
    }
  };

  const handleUpdate = () => {
    if (updateFields.length || deleteFields.length) {
      hotelCustomerContext
        .createCustomerRateCard(hotelId, { updateFields, deleteFields })
        .then((res) => {
          history.push(HOTEL_ROUTE.customerRateCard.replace(":id", hotelId));
          hotelCustomerContext.getCustomerRateCard(hotelId, {
            category: categorySelection,
          });
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  };

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "Ratecard added Successfully",
    });
  };

  const handleCategorySelection = (category_id) => {
    setCategorySelection(category_id);
    hotelCustomerContext.getCustomerRateCard(hotelId, {
      category: category_id === "all" ? "" : category_id,
    });
  };

  const handleRateCardToggleChange = (e, row) => {
    if (e.target.checked) {
      const fieldIndex = updateFields.findIndex(field => field === row.id);
      if (fieldIndex === -1) {
        const obj = {
          id: row.id,
          baseRate: row.baseRate,
          finalRate: row.finalRate,
        };
        const arrayOfFields = [...updateFields, obj];
        setUpdateFields(arrayOfFields);
      }
      const deleteIndex = deleteFields.findIndex(field => field === row.id);
      if (deleteIndex !== -1) {
        let deleteFieldsCopy = deleteFields.slice();
        deleteFieldsCopy.splice(deleteIndex, 1);
        setDeleteFields(deleteFieldsCopy);
      }
    }
    else {
      const updateIndex = updateFields.findIndex((field) => field === row.id);
      if (updateIndex !== -1) {
        let updateFieldsCopy = updateFields.slice();
        updateFieldsCopy.splice(updateIndex, 1);
        setDeleteFields(updateFieldsCopy);
      }
      const deleteIndex = deleteFields.findIndex((field) => field === row.id);
      if (deleteIndex === -1) {
        const arrayOfIds = [...deleteFields, row.id];
        setDeleteFields(arrayOfIds);
      }
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
      width: "5%",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "Item Name",
      selector: (row) => row?.name,
      width: "12%",
    },
    {
      name: "Category",
      selector: (row) => row.variantToProduct?.productCategory?.name,
    },
    {
      name: "Units",
      selector: (row) => row.variantToProduct?.unit,
      cell: (row) => {
        return <div className="d-flex flex-row" style={{ alignItems: "center" }}>
          {
            editingUnitFor === row.id ? (
              <SelectField
                inputName="unit"
                inputType="select"
                options={unitOptions}
                defaultValue={row?.variantToProduct?.unit}
                handleChange={(e) => { handleUnitChangeSave(e.target.value, row); setEditingUnitFor(null) }}
              />
            ) : (
              <span >{renderUnits(row?.variantToProduct?.unit)}</span>
            )
          }

          {!editingUnitFor && <Button onClick={() => setEditingUnitFor(row.id)}>
            <Icon name="edit" />
          </Button>}
        </div>
      },
      width: "12%",
    },
    {
      name: "Net Weight",
      selector: (row) => row.variantToProduct?.netWeight,
      width: "8%",
    },
    {
      name: "Max Order Qty",
      selector: (row) => row.variantToProduct?.maximumOrderQuantity,
      width: "8%",
    },
    {
      name: "Base Price",
      selector: (row) => row?.baseRate,
      cell: (row) => (
        <EditableCell
          name="baseRate"
          type="number"
          defaultValue={`${row?.baseRate}`}
          row={row}
          handleSave={(e) => handleBasePriceSave(e, row)}
          showEditButton
        />
      ),
    },
    {
      name: "Final Price",
      selector: (row) => row?.finalRate,
      cell: (row) => (
        <EditableCell
          name="baseRate"
          type="number"
          defaultValue={`${row?.finalRate}`}
          row={row}
          handleSave={(e) => handleFinalPriceSave(e, row)}
          showEditButton
        />
      ),
    },
    {
      name: "Tax Type",
      selector: (row) => row?.variantToProduct?.taxable,
      width: "10%",
    },
    {
      name: "CGST",
      selector: (row) => row?.variantToProduct?.taxSlab,
      width: "6%",
      cell: (row) => <span>{row?.variantToProduct?.taxSlab / 2}%</span>,
    },
    {
      name: "SGST",
      selector: (row) => row?.variantToProduct?.taxSlab,
      width: "6%",
      cell: (row) => <span>{row?.variantToProduct?.taxSlab / 2}%</span>,
    },
    {
      name: "Edit",
      selector: (row) => row.hotelProductId,
      cell: (row, index) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input form-control"
              id={`ratecardswitch-${index} `}
              defaultChecked={row?.edit}
              onChange={(e) => handleRateCardToggleChange(e, row)}
            />
            <label
              className="custom-control-label"
              htmlFor={`ratecardswitch-${index} `}
            ></label>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Head title="Customer Ratecard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Customer Ratecard
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="d-flex flex-wrap">
          <ButtonGroup
            data={[{ name: "All", id: "all" }].concat(
              categoryList.map((category) => ({
                name: category.name,
                id: category.id,
              }))
            )}
            activeId={categorySelection}
            onItemClick={(item) => handleCategorySelection(item)}
          />
        </div>
        <div className="d-flex flex-row-reverse">
          {/* <Button color="success" onClick={handleUpdate}>Update Card</Button> */}
          <Button color={"success"} onClick={handleUpdate}>
            <span>Update Rate Card</span>
          </Button>
        </div>
        <div>
          <CustomTable
            data={masterRateCardData}
            columns={columns}
            expandableRows
            pagination
          />
        </div>
        <Row
          justifyContent="end"
          style={{ justifyContent: "end", margin: "12px" }}
        ></Row>
      </Content>
    </React.Fragment>
  );
};

export default withProvider(HotelCustomerProvider, CustomerRateCard);
