import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/dashlite.scss";
import "./assets/scss/skins/theme-sneha.scss";
import "./assets/scss/theme.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CoreProvider, { CoreContext } from "./providers/CoreProvider";

const Error404Modern = lazy(() =>
  import("./apps/auth/pages/errors/404-modern")
);

const SnehaOneAppWrapper = (props) => {
  const coreContext = React.useContext(CoreContext);

  React.useEffect(() => {
    window.history.replaceState(
      {
        ...window.history.state,
        state: { is404: false },
      },
      document.title
    );
  }, []);

  function renderPage({ location }) {
    const is404 =
      coreContext.isAuthDetermined && location.state && location.state.is404;
    return is404 ? <Error404Modern /> : <App />;
  }

  return (
    <Suspense fallback={<div />}>
      <Router basename={`/`}>
        <Route render={renderPage} />
      </Router>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById("sneha-one-web-root"));
root.render(
  <CoreProvider>
    <SnehaOneAppWrapper />
  </CoreProvider>
);


reportWebVitals();
