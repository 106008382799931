import React, { createContext, useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import { emptyHandler } from "../../../utils/Utils";
import hotelCustomerRepository from "../repositories/HotelCustomerRepository";
import stockpointRepository from "../repositories/StockPointRepository";
import userRepository from "../repositories/UserRepository";

export const HotelCustomerContext = createContext({
  isLoadingCustomers: false,
  customersData: { customers: [], invoices: [], totalCount: 0 },
  getHotelCustomers: async () => emptyHandler,
  createHotelCustomer: async () => emptyHandler,
  usersData: [],
  userData: {},
  getUsers: async () => emptyHandler,
  getUser: async () => emptyHandler,
  stockpointOptions: [],
  getStockpointOptions: async () => emptyHandler,
  getCustomerData: async () => emptyHandler,
  customerData: {},
  getCustomerRateCard: async () => emptyHandler,
  customerRateCardData: [],
  masterRateCardData: [],
  createCustomerRateCard: async () => emptyHandler,
  updateHotelCustomer: async () => emptyHandler,
  roles: [],
  getRoles: async () => emptyHandler,
});

const HotelCustomerProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingCustomers, ilcState] = useBoolean(false);
  const [customersData, setCustomersData] = useState([]);

  const [usersData, setUsersData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [stockpointOptions, setStockpointOptions] = useState([]);

  const [customerData, setCustomerData] = useState({});
  const [customerRateCardData, setCustomerRateCardData] = useState([]);
  const [masterRateCardData, setMasterRateCardData] = useState([]);
  const [roles, setRoles] = useState([]);

  async function getHotelCustomers(queryParams) {
    try {
      if(!isLoadingCustomers){
        ilcState.on();
        const response = await hotelCustomerRepository.getHotelCustomers(
          queryParams
        );
        setCustomersData({
          customers: response?.hotels?.items,
          invoices: response?.invoices,
          totalCount: response?.hotels?.pagination?.totalCount,
        });
      }
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  }

  async function createHotelCustomer(data) {
    try {
      ilcState.on();
      const res = await hotelCustomerRepository.createHotelCustomer(data);
      return res;
    } catch (e) {
      throw e;
    } finally {
      ilcState.off();
    }
  }

  async function getUsers(params) {
    try {
      ilcState.on();
      const response = await userRepository.getUsers();
      setUsersData(response.users);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilcState.off();
    }
  }

  async function getUser(params) {
    try {
      ilcState.on();
      const response = await userRepository.getUser(params);
      setUserData(response.user);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilcState.off();
    }
  }

  async function getStockpointOptions() {
    try {
      ilcState.on();
      const response = await stockpointRepository.getStockpointOptions();
      setStockpointOptions(response.stockpointOptions);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilcState.off();
    }
  }

  async function getCustomerData(params) {
    try {
      ilcState.on();
      const response = await hotelCustomerRepository.getCustomerData(params);
      setCustomerData(response.customerData);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  }

  async function getCustomerRateCard(params, queryParams) {
    try {
      ilcState.on();
      const response = await hotelCustomerRepository.getCustomerRateCard(
        params,
        queryParams
      );
      setCustomerRateCardData(response.customerRateCard);
      setMasterRateCardData(response.masterRateCard);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  }

  async function createCustomerRateCard(params, data) {
    try {
      ilcState.on();
      const response = await hotelCustomerRepository.createCustomerRateCard(
        params,
        data
      );
      return response;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  }

  async function updateHotelCustomer(params, data) {
    try {
      ilcState.on();
      const res = await hotelCustomerRepository.updateHotelCustomer(
        params,
        data
      );
      return res;
    } catch (e) {
      throw e;
    } finally {
      ilcState.off();
    }
  }

  async function getRoles(){
    try {
      ilcState.on();
      const res = await hotelCustomerRepository.getRoles();
      setRoles(res.roles)
      return res;
    } catch (e) {
      throw e;
    } finally {
      ilcState.off();
    }
  }

  const mContext = {
    isLoadingCustomers,
    customersData,
    getHotelCustomers,
    createHotelCustomer,
    userData,
    usersData,
    getUsers,
    getUser,
    stockpointOptions,
    getStockpointOptions,
    getCustomerData,
    customerData,
    getCustomerRateCard,
    customerRateCardData,
    masterRateCardData,
    createCustomerRateCard,
    updateHotelCustomer,
    roles,
    getRoles,
  };

  return (
    <HotelCustomerContext.Provider value={mContext}>
      {props.children}
    </HotelCustomerContext.Provider>
  );
};

export default HotelCustomerProvider;
