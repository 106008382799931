import React from "react";
import Head from "../layout/head/Head";
import AuthLogin from "./auth/pages/login/AuthLogin";

const LandingPage = () => {
  return (
    <React.Fragment>
      <Head title="Landing | Sneha One" />
      <AuthLogin />
    </React.Fragment>
  );
};

export default LandingPage;
