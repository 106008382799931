// TODO: PERMITTED ACTIONS

import { PERMISSIONS } from "../../config/AppPermission";
import { CORE_APP_ROUTES } from "./routes/CoreAppRoute";

const CORE_APP_MENU = [
  {
    icon: "users-fill",
    text: "User Management",
    active: false,
    link: CORE_APP_ROUTES.userManagement,
    permittedActions: [PERMISSIONS.READ_USER],
  },
];

export default CORE_APP_MENU;
