import React from "react";
import { toast } from "react-toastify";
import { Icon } from "../components/Component";

const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

const useCustomToast = () => {
  /**
   *
   * @param {import('react-toastify').ToastContent} content
   * @param {import('react-toastify').ToastOptions} options
   */
  const successToast = (content, options = {}) => {
    toast.success(content, {
      position: options.position || "top-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
      ...options,
    });
  };

  /**
   *
   * @param {import('react-toastify').ToastContent} content
   * @param {import('react-toastify').ToastOptions} options
   */
  const warningToast = (content, options = {}) => {
    toast.warning(content, {
      position: options.position || "top-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
      ...options,
    });
  };

  /**
   *
   * @param {import('react-toastify').ToastContent} content
   * @param {import('react-toastify').ToastOptions} options
   */
  const infoToast = (content, options = {}) => {
    toast.info(content, {
      position: options.position || "top-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
      ...options,
    });
  };

  /**
   *
   * @param {import('react-toastify').ToastContent} content
   * @param {import('react-toastify').ToastOptions} options
   */
  const errorToast = (content, options = {}) => {
    toast.error(content, {
      position: options.position || "top-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
      ...options,
    });
  };

  return {
    successToast,
    warningToast,
    infoToast,
    errorToast,
  };
};

export default useCustomToast;
