import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthLogin from "../pages/login/AuthLogin";

export const AUTH_ROUTES = {
  login: "/auth/login",
};

const AuthRoute = (props) => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={AUTH_ROUTES.login} component={AuthLogin}></Route>
    </Switch>
  );
};
export default AuthRoute;
