/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import CustomTable from "../../../../components/table/CustomTable";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { format } from "date-fns";
import { withProvider } from "../../../../hoc/withProvider";
import LogHistoryProvider, {
  LogHistoryContext,
} from "../../providers/LogHistoryProvider";

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

const LogHistoryPage = (props) => {
  const logHistoriesContext = React.useContext(LogHistoryContext);
  const logHistories = logHistoriesContext.logHistories;
  const totalCount = logHistoriesContext.totalCount;
  const page = 1,
    size = 10;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 10);

  React.useEffect(() => {
    logHistoriesContext.getLogHistories({
      page: currentPage,
      size: currentPageSize,
    });
  }, []);

  const dataTableColumns = [
    {
      name: "Slno",
      selector: (row) => row.id,
      width: "5%",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      cell: (row) => (
        <span>{format(new Date(row.date || null), "dd/MMM/yyyy")}</span>
      ),
    },
    {
      name: "User Name",
      selector: (row) => row?.transactionUser?.firstName,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) =>
        row.action === "CREDIT" ? (
          <Badge color="success">Credit</Badge>
        ) : (
          <Badge color="danger">Debit</Badge>
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) =>
        row.status === "SUCCESS" ? (
          <Badge color="success">{row.status}</Badge>
        ) : (
          <Badge color="danger">{row.status}</Badge>
        ),
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Old Balance",
      selector: (row) => row.oldBalance,
      cell: (row) => row.ledgerBalance - row.amount,
    },
    {
      name: "New Balance",
      selector: (row) => row.ledgerBalance,
    },
  ];

  function handlePageChange(page) {
    setCurrentPage(page);
    logHistoriesContext.getLogHistories({
      page: currentPage,
      size: currentPageSize,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);
    logHistoriesContext.getLogHistories({
      page: currentPage,
      size: currentPageSize,
    });
  }

  function handleSort(sort) {}

  function handleSearchChange(searchTerm) {}

  function renderLogListTable() {
    return (
      <Block>
        <CustomTable
          data={logHistories}
          columns={dataTableColumns}
          expandableRows
          pagination
          selectableRows={false}
          onSearchChange={handleSearchChange}
          onSort={handleSort}
          onPageChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          onSaveFilter={() => {}}
          onResetFilter={() => {}}
          pageShowSize = {currentPageSize}
          currentPageNum={currentPage}
          totalItems={logHistoriesContext.totalCount}
        />
      </Block>
    );
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Log History</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalCount} Logs.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="Log History | Core App" />
      <Content>
        {renderHeaderSection()}
        {renderLogListTable()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(LogHistoryProvider, LogHistoryPage);
