/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { DataTablePagination, Icon, RSelect, DataTable as CTable, Button } from "../Component";
import useBoolean from "../../hooks/useBoolean";
import { isFunction } from "../../utils/Utils";
import debounce from "lodash.debounce";
import useMounted from "../../hooks/useMounted";

const SHOW_SIZE = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const SORT_TYPE = {
  desc: "desc",
  asc: "asc",
};

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

const ExpandableRowComponent = ({ data }) => {
  return (
    <ul className="dtr-details p-2 border-bottom ml-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span> <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span> <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span> <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span> <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input form-control"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const TABLE_SPACING = "12px";
/** @type {import("react-data-table-component").TableStyles}  */
const customStyles = {
  rows: {
    style: {
      padding: TABLE_SPACING,
      color: "#8094ae!important",
      transition: "background-color 0.3s, box-shadow 0.3s border-bottom-color 0.3s",
      "&:hover": {
        background: "#f8f9fc!important",
        boxShadow: "0 2px 15px -4px rgb(133 79 255 / 40%)",
        outlineStyle: "none!important",
        borderBottomColor: "rgb(133 79 255 / 25%)!important",
      },
    },
  },
  headRow: {
    style: {
      padding: TABLE_SPACING,
    },
  },
  headCells: {
    style: {
      fontWeight: "normal!important",
      color: "#8094ae!important",
    },
  },
};

const CustomTable = ({
  filterTitle = "Filters",
  renderFilters,
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  onResetFilter,
  onSaveFilter,
  onSort,
  onShowSizeChange,
  showSizeOptions = SHOW_SIZE,
  bulkActions = [],
  onBulkActionApply,
  onSearchChange,
  totalItems = 0,
  onPageChange,
  responsive,
  bulkActionsEnable = true,
  searchBar = true,
  pageShowSize,
  currentPageNum,
  searchTerm,
  customSort,
  ...otherProps
}) => {
  const isMounted = useMounted();

  const [sort, setSort] = useState(SORT_TYPE.desc);

  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState(searchTerm || "");
  const [showSize, setShowSize] = useState(pageShowSize);
  const [mobileView, setMobileView] = useState();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAction, setSelectedActions] = useState(null);

  const [currentPage, setCurrentPage] = useState(currentPageNum);

  const [tablesm, updateTableSm] = useState(false);
  const [showSearchInput, ssiState] = useBoolean(false);

  // overrides
  const isSortEnabled = isFunction(onSort);
  const isBulkActionsEnabled = isFunction(onBulkActionApply);
  const isResetFilterEnabled = isFunction(onResetFilter);
  const isSaveFilterEnabled = isFunction(onSaveFilter);

  useEffect(() => {
    if (isMounted && isFunction(onSearchChange)) {
      onSearchChange(searchText);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (!isFunction(onPageChange)) {
      return;
    }

    onPageChange(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (!isFunction(onSort)) {
      return;
    }

    onSort(sort);
  }, [sort]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (!isFunction(onShowSizeChange)) {
      return;
    }

    onShowSizeChange(showSize);
  }, [showSize]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchTextChange, 600);
  }, []);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function handleApplyAction() {
    if (!isBulkActionsEnabled) {
      return;
    }

    onBulkActionApply(selectedAction, selectedRows);
  }

  function handleSelectedRows({ selectedRows: _selectedRows }) {
    setSelectedRows(_selectedRows);
  }

  function handleResetFilter() {
    if (!isFunction(onResetFilter)) {
      return;
    }

    onResetFilter();
  }

  function handleSaveFilter() {
    if (!isFunction(onSaveFilter)) {
      return;
    }

    onSaveFilter();
  }

  function renderShowSettings() {
    if (!showSizeOptions) {
      return null;
    }

    return (
      <ul className="link-check">
        <li>
          <span>Show</span>
        </li>
        {showSizeOptions.map((so, i) => {
          return (
            <li key={`ct-show-size-${so.value}-${i}`} className={showSize === so.value ? "active" : ""}>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowSize(so.value);
                }}
              >
                {so.label}
              </DropdownItem>
            </li>
          );
        })}
      </ul>
    );
  }

  function renderOrderSettings() {
    if (!isSortEnabled) {
      return null;
    }

    return (
      <ul className="link-check">
        <li>
          <span>Order</span>
        </li>
        <li className={sort === SORT_TYPE.desc ? "active" : ""}>
          <DropdownItem
            tag="a"
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setSort(SORT_TYPE.desc);
            }}
          >
            DESC
          </DropdownItem>
        </li>
        <li className={sort === SORT_TYPE.asc ? "active" : ""}>
          <DropdownItem
            tag="a"
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setSort(SORT_TYPE.asc);
            }}
          >
            ASC
          </DropdownItem>
        </li>
      </ul>
    );
  }

  function renderDataSettings() {
    return (
      <li>
        <UncontrolledDropdown>
          <DropdownToggle color="transparent" className="btn btn-trigger btn-icon dropdown-toggle">
            <Icon name="setting"></Icon>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-xs">
            {renderShowSettings()}
            {renderOrderSettings()}
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    );
  }

  function renderDataFilter() {
    if (!renderFilters) {
      return null;
    }

    if (!isFunction(renderFilters)) {
      return null;
    }

    return (
      <li>
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
            <div className="dot dot-primary"></div>
            <Icon name="filter-alt"></Icon>
          </DropdownToggle>

          <DropdownMenu right className="filter-wg dropdown-menu-xl" style={{ overflow: "visible" }}>
            {filterTitle && (
              <div className="dropdown-head">
                <span className="sub-title dropdown-title">{filterTitle}</span>
              </div>
            )}

            <div className="dropdown-body dropdown-body-rg">{renderFilters()}</div>

            {isResetFilterEnabled || isSaveFilterEnabled ? (
              <div className="dropdown-foot between">
                {isResetFilterEnabled && (
                  <a
                    href="#reset"
                    className="clickable"
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleResetFilter();
                    }}
                  >
                    Reset Filter
                  </a>
                )}
                {isSaveFilterEnabled && (
                  <a
                    href="#save"
                    onClick={(ev) => {
                      ev.preventDefault();
                      handleSaveFilter();
                    }}
                  >
                    Save Filter
                  </a>
                )}
              </div>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    );
  }

  function renderOtherFilters() {
    return (
      <>
        {renderDataFilter()}
        {renderDataSettings()}
      </>
    );
  }

  function renderSearchBar() {
    return (
      <React.Fragment>
        <div className="card-tools mr-n1">
          <ul className="btn-toolbar gx-1">
            <li>
              <a
                href="#search"
                onClick={(ev) => {
                  ssiState.toggle();
                  ev.preventDefault();
                }}
                className="btn btn-icon search-toggle toggle-search"
              >
                <Icon name="search"></Icon>
              </a>
            </li>
            {/* <li className="btn-toolbar-sep"></li>
            <li>
              <div className="toggle-wrap">
                <Button
                  className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                  onClick={() => updateTableSm(true)}
                >
                  <Icon name="menu-right"></Icon>
                </Button>
                <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                  <ul className="btn-toolbar gx-1">
                    <li className="toggle-close">
                      <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                        <Icon name="arrow-left"></Icon>
                      </Button>
                    </li>

                    {renderOtherFilters()}
                  </ul>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
        <div className={`card-search search-wrap ${showSearchInput && "active"}`}>
          <div className="card-body">
            <div className="search-content">
              <Button
                className="search-back btn-icon toggle-search active"
                onClick={() => {
                  ssiState.toggle();
                }}
              >
                <Icon name="arrow-left"></Icon>
              </Button>
              <input
                type="text"
                className="border-transparent form-focus-none form-control"
                placeholder="Search by user or email"
                defaultValue={searchText}
                onChange={debouncedSearch}
              />
              <Button className="search-submit btn-icon">
                <Icon name="search"></Icon>
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function renderBulkActions() {
    if (!isBulkActionsEnabled) {
      return (
        <div className="card-tools">
          <span>{searchText ? `Search result for : ${searchText}` : 'Search by user or name'}</span>
        </div>
      );
    }

    return (
      <div className="card-tools">
        <div className="form-inline flex-nowrap gx-3">
          <div className="btn-wrap">
            <span className="d-md-none">
              <Button color="light" outline disabled={false} className="btn-dim btn-icon">
                <Icon name="arrow-right"></Icon>
              </Button>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <CTable className={"card-stretch"}>
      {/* <div className={`dataTables_wrapper dt-bootstrap4 no-footer nk-tb-list ${className ? className : ""}`}> due to overflow of the table */}
      <div className="card-inner position-relative card-tools-toggle">
        <div className="card-title-group">
          {bulkActionsEnable ? renderBulkActions() : <></>}
          {searchBar ? renderSearchBar() : <></>}
        </div>
      </div>
      <DataTable
        data={tableData}
        columns={columns}
        highlightOnHover
        pointerOnHover
        responsive
        customStyles={customStyles}
        onSelectedRowsChange={handleSelectedRows}
        selectableRows={selectableRows}
        selectableRowsComponent={CustomCheckbox}
        expandableRowsComponent={ExpandableRowComponent}
        expandableRows={mobileView}
        noDataComponent={<div className="p-2">There are no records found</div>}
        sortIcon={
          <div>
            <span>&darr;</span>
            <span>&uarr;</span>
          </div>
        }
        paginationServer
        pagination={pagination}
        sortFunction={customSort}
        paginationComponent={() => (
          <DataTablePagination
            customItemPerPage={showSize}
            itemPerPage={showSize}
            totalItems={totalItems}
            paginate={handlePageChange}
            currentPage={currentPage}
            onChangeRowsPerPage={setShowSize}
            setRowsPerPage={setShowSize}
          />
        )}
        {...otherProps}
      />
      {/* </div> */}
    </CTable>
  );
};

export default CustomTable;
