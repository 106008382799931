import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import stockpointRepository from "../repositories/StockPointRepository";
import { emptyHandler } from "../../../utils/Utils";

export const StockpointContext = React.createContext({
  isLoadingStockpoints: false,
  stockpointsList: { stockpoints: [], total: 0 },
  getVehicles: async () => emptyHandler,
  stockpoint: {},
  getStockpoint: async () => emptyHandler,
  updateStockpoint: async () => emptyHandler,
});

export const StockpointProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingStockpoints, ilspState] = useBoolean(false);
  const [stockpointsList, setStockpointsList] = useState([]);
  const [stockpoint, setStockpoint] = useState({});

  // TODO: PAGINATION
  async function getStockpoints(queryParams) {
    try {
      ilspState.on();
      const response = await stockpointRepository.getStockpointsList(
        queryParams
      );

      setStockpointsList({
        stockpoints: response.items,
        total: response.pagination.totalCount,
      });
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilspState.off();
    }
  }
  const createStockpoint = async (createParams) => {
    try {
      ilspState.on();
      const res = await stockpointRepository.createStockpointApi(createParams);
      return res;
    } catch (e) {
      throw e;
    } finally {
      ilspState.off();
    }
  };

  async function getStockpoint(params) {
    try {
      ilspState.on();
      const response = await stockpointRepository.getStockpoint(params);
      setStockpoint(response.stockpoint);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilspState.off();
    }
  }

  async function updateStockpoint(params, data) {
    try {
      ilspState.on();
      const response = await stockpointRepository.updateStockpoint(
        params,
        data
      );
      return response.stockpoint;
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilspState.off();
    }
  }

  const mContext = {
    isLoadingStockpoints,
    stockpointsList,
    getStockpoints,
    createStockpoint,
    getStockpoint,
    stockpoint,
    updateStockpoint,
  };

  return (
    <StockpointContext.Provider value={mContext}>
      {props.children}
    </StockpointContext.Provider>
  );
};

export default StockpointProvider;
