import React from "react";
import { PERMISSIONS } from "../../../../../config/AppPermission";
import useAuthorization from "../../../../../hooks/useAuthorization";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import MasterRateCardCreatePage from "../MasterRateCardForm";
import { withProvider } from "../../../../../hoc/withProvider";
import MasterRateCardProvider from "../../../providers/MasterRateCardProvider";

const MasterRateCardFormContainer = (props) => {
  return (
    <React.Fragment>
      <Head title="MasterRateCard form | Core App" />
      <Content>
        <MasterRateCardCreatePage />
      </Content>
    </React.Fragment>
  );
};
export default withProvider(
  MasterRateCardProvider,
  MasterRateCardFormContainer
);
