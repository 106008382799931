import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import logHistoryRepository from "../repositories/LogHistoryRepository";
import { emptyHandler } from "../../../utils/Utils";

export const LogHistoryContext = React.createContext({
  isLoadingHistory: false,
  logHistories: null,
  totalCount: 0,
  getLogHistories: async () => emptyHandler,
});

const LogHistoryProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingHistory, ilhState] = useBoolean(false);
  const [logHistories, setLogHistories] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  async function getLogHistories(params) {
    try {
      ilhState.on();
      const response = await logHistoryRepository.getLogHistories(params);

      setLogHistories(response?.logHistories?.items);
      setTotalCount(response?.logHistories?.pagination?.totalCount);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilhState.off();
    }
  }

  const mContext = {
    isLoadingHistory,
    logHistories,
    totalCount,
    getLogHistories,
  };

  return (
    <LogHistoryContext.Provider value={mContext}>
      {props.children}
    </LogHistoryContext.Provider>
  );
};

export default LogHistoryProvider;
