import {
  GET_CATEGORIES_OPTIONS,
  GET_SUB_CATEGORIES_OPTIONS,
  MASTER_RATE_CARD_LIST_API,
  MASTER_RATE_CARD_CREATE_API,
  GET_CATEGORIES,
  MASTER_RATE_CARD_EDIT_API,
} from "../../../constants/Endpoints";
import API from "../../../service/ApiService";

class MasterRateCardRepository {
  // TODO: PAGINATION
  async getMasterRateCardsList(queryParams) {
    const response = await API.get(MASTER_RATE_CARD_LIST_API, { queryParams });
    return response.data;
  }

  async createMasterRateCard(params) {
    const response = await API.post(MASTER_RATE_CARD_CREATE_API, {
      body: params,
    });
    return response;
  }

  async getCategories() {
    const response = await API.get(GET_CATEGORIES);
    return response.data;
  }

  async getCategoryOptions() {
    const response = await API.get(GET_CATEGORIES_OPTIONS);
    return response.data;
  }

  async getSubCategoryOptions(categoryId) {
    const response = await API.get(GET_SUB_CATEGORIES_OPTIONS, {
      pathParams: { id: categoryId },
    });
    return response.data;
  }

  async updateMasterRateCard(params) {
    const response = await API.put(MASTER_RATE_CARD_LIST_API, { body: params });
    return response.data;
  }

  async getProduct(params) {
    const response = await API.get(MASTER_RATE_CARD_EDIT_API, {
      pathParams: { id: params },
    });
    return response.data;
  }

  async updateProduct(params, data) {
    const response = await API.put(MASTER_RATE_CARD_EDIT_API, {
      pathParams: { id: params },
      body: data,
    });
    return response.data;
  }
}

const masterRateCardRepository = new MasterRateCardRepository();
export default masterRateCardRepository;
