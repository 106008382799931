import React from "react";
import { Card, Col } from "reactstrap";

const DashboardCard = (props) => {
  const { countData, icons, limit, tierwise, hotelwise } = props;
  const FlexCards = countData.map((data, index) => {
    return index < limit ? (
      <Col key={index}>
        <Card className="m-0" style={{ borderRadius: "10px" }}>
          <div className="team">
            <div className="user-card user-card-s2">
              {icons ? (
                <>
                  <span style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    {data?.name}
                  </span>
                  <img
                    src={icons[data?.name]}
                    style={{ width: "20%", height: "20%" }}
                    alt="logo"
                  />
                  <h4 className="mt-1" style={{ color: "red" }}>
                    {data?.count || 0} Kgs
                  </h4>
                </>
              ) : (
                <></>
              )}
              {tierwise ? (
                <>
                  <h6>{data.tier}</h6>
                  <h4 style={{ color: "blue" }}>{`${data.count} Orders`}</h4>
                </>
              ) : (
                <></>
              )}
              {hotelwise ? (
                <>
                  <span>{data.name}</span>
                  <p
                    style={{ color: "red", fontSize: "12px" }}
                  >{`${data.count} Orders`}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card>
      </Col>
    ) : (
      <React.Fragment key={index}></React.Fragment>
    );
  });

  return FlexCards;
};

export default DashboardCard;
