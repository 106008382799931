import API from "../../../service/ApiService";
import {
  GET_HOTEL_USERS_API,
  GET_HOTEL_USER_API,
} from "../../../constants/Endpoints";

class UserRepository {
  async getUsers() {
    const response = await API.get(GET_HOTEL_USERS_API);
    return response.data;
  }

  async getUser(params) {
    const response = await API.get(GET_HOTEL_USER_API, {
      pathParams: { id: params },
    });
    return response.data;
  }
}

const userRepository = new UserRepository();

export default userRepository;
