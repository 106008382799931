import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import userManageRepository from "../../../repositories/UserManagementRepository";
import { emptyHandler } from "../../../utils/Utils";

export const UserManagementContext = React.createContext({
  isLoadingUsers: false,
  usersList: { users: [], total: 0 },
  getUsers: async () => emptyHandler,
});

const UserManagementProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingUsers, iluState] = useBoolean(false);
  const [usersList, setUsersList] = useState([]);

  // TODO: PAGINATION
  async function getUsers() {
    try {
      iluState.on();
      const response = await userManageRepository.getUsers();

      setUsersList({
        users: response.items,
        total: response.pagination.totalCount,
      });
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iluState.off();
    }
  }

  const mContext = {
    isLoadingUsers,
    usersList,
    getUsers,
  };

  return (
    <UserManagementContext.Provider value={mContext}>
      {props.children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
