import React, { useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import InputField from "../../../../components/utils/InputComponent";

const StaffDetails = ({ index, register, errors, control, staffRemove }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <InputField
            labelText="Full Name"
            inputName={`stockpointUser[${index}].stockpointUserDetails.firstName`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="Role"
            inputName={`stockpointUser[${index}].role`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="Mobile No"
            inputName={`stockpointUser[${index}].stockpointUserDetails.phoneNumber`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="Shift Timings"
            inputName={`stockpointUser[${index}].shiftTimings`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>

      {index >= 0 ? (
        <Button
          color="danger"
          className="mb-3"
          onClick={() => staffRemove(index)}
        >
          {" "}
          Delete Staff
        </Button>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default StaffDetails;
