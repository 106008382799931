import {
  STOCKPOINT_LIST_API,
  STOCKPOINT_CREATE_API,
  STOCKPOINT_OPTIONS,
  STOCKPOINT_EDIT,
} from "../../../constants/Endpoints";
import API from "../../../service/ApiService";

class StockpointRepository {
  // TODO: PAGINATION
  async getStockpointsList(queryParams) {
    const response = await API.get(STOCKPOINT_LIST_API, {
      queryParams: queryParams,
    });
    return response.data;
  }

  async createStockpointApi(params) {
    const response = await API.post(STOCKPOINT_CREATE_API, {
      body: params,
    });
    return response;
  }

  async getStockpointOptions() {
    const response = await API.get(STOCKPOINT_OPTIONS);
    return response.data;
  }

  async getStockpoint(params) {
    const response = await API.get(STOCKPOINT_EDIT, {
      pathParams: { id: params },
    });
    return response.data;
  }

  async updateStockpoint(params, data) {
    const response = await API.put(STOCKPOINT_EDIT, {
      pathParams: { id: params },
      body: data,
    });
    return response.data;
  }
}

const stockpointRepository = new StockpointRepository();
export default stockpointRepository;
