import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import Icon from "../../../../components/icon/Icon";
import { APP_MODULE_PREFIX } from "../../../../config/Permission";
import { CoreContext } from "../../../../providers/CoreProvider";

const AccessibleAppItem = (props) => {
  const { icon, title, link } = props;

  return (
    <NavLink to={link} className="nk-menu-link p-3 border-top">
      <div className="d-flex align-items-center">
        <span className="nk-menu-icon">
          <Icon
            color={"secondary"}
            style={{ color: "secondary" }}
            name={icon}
          />
        </span>
        <div className="nk-notification-content">
          <div className="nk-notification-text">{title}</div>
        </div>
        {/* <span className=" text-color-secondary nk-menu-text">{title}</span> */}
      </div>
    </NavLink>
  );

  // return <a className="p-3" href="#link" onClick={(ev) => ev.preventDefault()}>
  //   <div className="d-flex text-center flex-column">
  // <Icon style={{ fontSize: 36 }} name={icon} />
  // <span className="title">{title}</span>
  //   </div>
  // </a>
};

const AccessibleApps = () => {
  const coreContext = useContext(CoreContext);
  const accessibleApps = coreContext.accessibleModules.map(
    (am) => APP_MODULE_PREFIX[am]
  );

  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <Icon name="menu-circled" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-body">
          <div className="dropdown-head">
            <span className="sub-title nk-dropdown-title">
              {"Accessible Modules"}
            </span>
          </div>
          <div className="d-flex flex-column">
            {accessibleApps.map((item) => {
              if (!item.link) return null;
              return (
                <AccessibleAppItem
                  key={`accessible-app-${item.title}`}
                  link={item.link}
                  icon={item.icon}
                  title={item.title}
                />
              );
            })}
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AccessibleApps;
