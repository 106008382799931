import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import { withProvider } from "../../hoc/withProvider";
import HotelLayout from "./pages/HotelLayout";
import HotelAppProvider from "./providers/HotelProvider";

const HotelApp = (props) => {
  return (
    <Suspense>
      <Switch>
        <Route exact path="" component={HotelLayout} />
      </Switch>
    </Suspense>
  );
};

export default withProvider(HotelAppProvider, HotelApp);
