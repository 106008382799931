import { VEHICLES_API, VEHICLE_EDIT_API } from "../../../constants/Endpoints";
import API from "../../../service/ApiService";
class VehicleMasterRepository {
  async getVehicles(queryParams) {
    const response = await API.get(VEHICLES_API, { queryParams: queryParams });
    return response.data;
  }

  async createVehicle(params) {
    const response = await API.post(VEHICLES_API, {
      body: params,
    });
    return response;
  }

  async getVehicle(params) {
    const response = await API.get(VEHICLE_EDIT_API, {
      pathParams: { id: params },
    });
    return response.data;
  }

  async updateVehicle(params, data) {
    const response = await API.put(VEHICLE_EDIT_API, {
      pathParams: { id: params },
      body: data,
    });
    return response.data;
  }
}

const vehicleMasterRepository = new VehicleMasterRepository();

export default vehicleMasterRepository;
