// TODO: PERMITTED ACTIONS
import { HOTEL_ROUTE } from "./routes/HotelRoute";

const HOTEL_MENU = [
  {
    icon: "dashboard",
    text: "Dashboard",
    active: false,
    link: HOTEL_ROUTE.dashboard,
    permittedActions: ["READ_HOTEL_DASHBOARD"],
  },
  { heading: "Indents" },
  {
    icon: "list-index",
    text: "List Indents",
    active: false,
    link: HOTEL_ROUTE.listIndent,
    permittedActions: ["CREATE_USER"],
  },
  { heading: "Customer" },
  {
    icon: "users",
    text: "List Customers",
    active: false,
    link: HOTEL_ROUTE.customersList,
    permittedActions: ["CREATE_USER"],
  },
  {
    icon: "user-add",
    text: "Add a New Customer",
    active: false,
    link: HOTEL_ROUTE.addNewCustomerPage,
    permittedActions: ["CREATE_USER"],
  },
  {
    icon: "history",
    text: "Log History",
    active: false,
    link: HOTEL_ROUTE.customersLogHistoryPage,
    permittedActions: ["CREATE_USER"],
  },
  // {
  //   icon: "sign-cc-alt",
  //   text: "Post Pay",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "next 7 days",
  //       link: "/",
  //       newTab: false,
  //     },
  //     {
  //       text: "next 15 days",
  //       link: "/",
  //       newTab: false,
  //     },
  //     {
  //       text: "next 30 days",
  //       link: "/",
  //       newTab: false,
  //     },
  //     {
  //       text: "next 45 days",
  //       link: "/",
  //       newTab: false,
  //     },
  //     {
  //       text: "next 60 days",
  //       link: "/",
  //       newTab: false,
  //     },
  //   ],
  // },
  // {
  //   icon: "sign-inr-alt",
  //   text: "Pre Pay",
  //   active: false,
  // },
  {
    icon: "visa-alt",
    text: "Master rate card",
    active: false,
    link: HOTEL_ROUTE.masterRateCard,
  },
  // {
  //   icon: "users-fill",
  //   text: "Users and access",
  //   active: false,
  // },
  {
    icon: "activity-round-fill",
    text: "Stock points",
    active: false,
    link: HOTEL_ROUTE.stockPoints,
  },
  {
    icon: "truck",
    text: "Vehicle Master",
    active: false,
    link: HOTEL_ROUTE.vehicleMaster,
  },
  // { heading: "Payments" },
  {
    icon: "histroy",
    text: "Payment history",
    link: HOTEL_ROUTE.paymentHistory,
    active: false,
  },
  // {
  //   icon: "calc",
  //   text: "Payment tally",
  //   active: false,
  // },
];

export default HOTEL_MENU;
