import { DASHBOARD_DETAILS_API, DASHBOARD_OVER_DUE_LIST } from "../../../constants/Endpoints";
import API from "../../../service/ApiService";
class DashboardRepository {
  async getDashboardDetails() {
    const response = await API.get(DASHBOARD_DETAILS_API);
    return response.data;
  }

  async getOverDueList(){
    const response = await API.get(DASHBOARD_OVER_DUE_LIST);
    return response.data;
  }
}

const dashboardRepository = new DashboardRepository();
export default dashboardRepository;
