import React from "react";
import classNames from "classnames";
import { Spinner } from "reactstrap";

const Button = ({
  isLoading,
  renderLoader,
  color,
  size,
  className,
  outline,
  disabled,
  ...props
}) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });

  function _renderLoader() {
    if (!isLoading) {
      return props.children;
    }

    if (renderLoader) return renderLoader();

    return <Spinner size="sm" color="light" />;
  }

  return (
    <button className={buttonClass} {...props}>
      {_renderLoader()}
    </button>
  );
};
export default Button;
