import React from "react";
import { CoreContext } from "../providers/CoreProvider";

const Guard = (props) => {
  const coreContext = React.useContext(CoreContext);
  const { permittedActions } = props;

  const canAccess = coreContext.canAccess(permittedActions);

  if (!canAccess) {
    return null;
  }

  return props.children;
};

export default Guard;
