import { CUSTOMER_RATE_CARD_CREATE_API } from "../../../constants/Endpoints";
import API from "../../../service/ApiService";

class HotelRepository {
  // async createCustomerRateCard(params, hotelId) {
  //   const response = await API.post(CUSTOMER_RATE_CARD_CREATE_API, {
  //     queryParams: { hotelId },
  //     body: { params },
  //   });
  //   return response;
  // }
}

const hotelRepository = new HotelRepository();
export default hotelRepository;
