import React, { useContext, useEffect } from "react";
import {
  Button,
  Col,
  Label,
  Row,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  BlockHeadContent,
  BlockTitle,
  OverlineTitle,
} from "../../../../components/Component";
import classnames from "classnames";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import * as yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import useYupValidationResolver from "../../../../hooks/useYupResolver";
import { useForm, useFieldArray } from "react-hook-form";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { StockpointContext } from "../../providers/StockpointProvider";
import { errorToast } from "../../../../components/toastify/Toastify";
import StaffDetails from "./StaffCreateForm";
import InputField from "../../../../components/utils/InputComponent";
const CREATE_TITLE_HEAD = "Create Stock |  Hotel";
const EDIT_TITLE_HEAD = "Edit Stock |  Hotel";

const createValidationSchema = yup
  .object({
    name: yup.string().trim().required("Stockpoint Name is required"),
    addressLine_1: yup.string().trim().required("AddressLine1 is required"),
    addressLine_2: yup.string().trim().required("AddressLine2 is required"),
    postalCode: yup.string().typeError("Zipcode required"),
    city: yup.string().trim().required("City is required"),
    latitude: yup.string().trim(),
    longitude: yup.string().trim(),
    mapUrl: yup.string().trim(),
    landlineNumber: yup.string().trim(),
    id: yup.number().notRequired(),
    stockpointUser: yup.array().of(
      yup.object().shape({
        role: yup.string().trim().required("Role is required"),
        shiftTimings: yup.string().trim().required("Shift Timings is required"),
        id: yup.number().notRequired(),
        stockpointId: yup.number().notRequired(),
        userId: yup.number().notRequired(),
        stockpointUserDetails: yup.object().shape({
          firstName: yup.string().trim().required("Full Name is required"),
          phoneNumber: yup.string().trim().required("Mobile No is required"),
        }),
      })
    ),
  })
  .required();

const StockCreatePage = (props) => {
  const { id: stockpointId } = useParams();
  const history = useHistory();
  const isEditMode = props?.match?.params?.id || false;

  const { createStockpoint, stockpoint, updateStockpoint, getStockpoint } =
    useContext(StockpointContext);

  useEffect(() => {
    if (stockpointId) getStockpoint(stockpointId);
  }, []);

  useEffect(() => {
    reset({
      ...stockpoint,
    });
  }, [stockpoint]);
  const resolver = useYupValidationResolver(createValidationSchema);
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      stockpointUser: [
        {
          role: "",
          shiftTimings: "",
          stockpointUserDetails: {
            firstName: "",
            phoneNumber: "",
          },
        },
      ],
    },
  });
  const {
    fields: staffFields,
    remove: staffRemove,
    append,
  } = useFieldArray({
    control,
    name: `stockpointUser`,
  });

  const headTitle = isEditMode ? EDIT_TITLE_HEAD : CREATE_TITLE_HEAD;

  async function handleSaveItem(data) {
    if (stockpointId) {
      await updateStockpoint(stockpointId, data)
        .then((res) => {
          history.push(HOTEL_ROUTE.stockPoints);
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      await createStockpoint(data)
        .then((res) => {
          history.push(HOTEL_ROUTE.stockPoints);
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  }

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "Stockpoint added Successfully",
    });
  };
  return (
    <React.Fragment>
      <Head title={headTitle} />
      <Content>
        <BlockHeadContent>
          <BlockTitle page>Stock Point Creation form</BlockTitle>
        </BlockHeadContent>
        <div className="mt-4 p-4 bg-white rounded-sm">
          <Form
            className={"form-validate is-alter"}
            onSubmit={handleSubmit(handleSaveItem)}
          >
            <OverlineTitle tag="span" className="preview-title-lg">
              Stockpoint details
            </OverlineTitle>

            <Row md="12" lg="12">
              <Col md="6" lg="6">
                <InputField
                  labelText="Stockpoint Name"
                  inputName={`name`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6"></Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Addressline 1"
                  inputName={`addressLine_1`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Addressline 2"
                  inputName={`addressLine_2`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Zipcode"
                  inputName={`postalCode`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="City"
                  inputName="city"
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Address Lat"
                  inputName={`latitude`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Address Long"
                  inputName={`longitude`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Address Map url"
                  inputName={`mapUrl`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Landline Number"
                  inputName={`landlineNumber`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>

            <div className="my-4" />
            <div className="form-control-wrap mb-3">
              <Label
                htmlFor="default-5"
                className="form-label "
                style={{ fontSize: "20px" }}
              >
                Staff
              </Label>
              <Button
                outline
                size="sm"
                color={"primary"}
                className="ml-3"
                onClick={() =>
                  append({
                    role: "",
                    shiftTimings: "",
                    stockpointUserDetails: {
                      firstName: "",
                      phoneNumber: "",
                    },
                  })
                }
              >
                {" "}
                Add
              </Button>
              {staffFields.map((item, index) => {
                return (
                    <StaffDetails
                      key={index}
                      item={item}
                      index={index}
                      register={register}
                      errors={errors}
                      control={control}
                      staffRemove={staffRemove}
                    />
                );
              })}
            </div>

            <Row>
              <Col />
              <Button onClick={handleSubmit(handleSaveItem)} color="success">
                {stockpointId ? "Update Stockpoint" : "Save Stockpoint"}
              </Button>
            </Row>
          </Form>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default StockCreatePage;
