import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Label, Row, Form } from "reactstrap";
import {
  BlockHeadContent,
  BlockTitle,
  Icon,
  OverlineTitle,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import * as yup from "yup";
import useYupValidationResolver from "../../../../hooks/useYupResolver";
import { useForm, useFieldArray } from "react-hook-form";
import InputField from "../../../../components/utils/InputComponent";
import SelectField from "../../../../components/utils/SelectComponent";
import VariantDetails from "./VariantForm";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { errorToast } from "../../../../components/toastify/Toastify";
import { useHistory, useParams } from "react-router-dom";
import MasterRateCardProvider, {
  MasterRateCardContext,
} from "../../providers/MasterRateCardProvider";
import { unitOptions, taxableOptions, taxCodes } from "./Constants";
import { withProvider } from "../../../../hoc/withProvider";

const CREATE_TITLE_HEAD = "Create Master Rate Card |  Hotel";
const EDIT_TITLE_HEAD = "Edit Master Rate Card |  Hotel";

const createValidationSchema = yup
  .object({
    name: yup.string().trim().required("Item name is required"),
    categoryId: yup.string().trim().required("Category is required"),
    subCategoryId: yup.string().required("Sub category is required"),
    unit: yup.string().required("Unit is required"),
    netWeight: yup
      .number()
      .typeError("Invalid field value")
      .required("Net weight is required"),
    maximumOrderQuantity: yup
      .number()
      .typeError("Invalid field value")
      .required("Max order qty is required"),
    taxSlab: yup.string().required(),
    taxable: yup.string().required(),
    id: yup.number().notRequired(),
    productVariants: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Variant Name required"),
        baseRate: yup
          .number()
          .typeError("Invalid field value")
          .required("Base Price is required"),
        finalRate: yup
          .number()
          .typeError("Invalid field value")
          .required("Final Price is required"),
        id: yup.number().notRequired(),
        hotelProductId: yup.number().notRequired(),
      })
    ),
  })
  .required();

const MasterRateCardCreatePage = (props) => {
  const history = useHistory();
  const { id: productId } = useParams();

  const masterRateCardContext = useContext(MasterRateCardContext);
  const { categoryOptions, subCategoryOptions } = masterRateCardContext;
  const productData = masterRateCardContext.productData;

  const categorySelectOptions = categoryOptions.map((category) => ({
    value: category.id,
    name: `${category.name}`,
  }));

  // const [categoryId, setCategoryId] = useState(categorySelectOptions[0]?.value);
  const isEditMode = props?.match?.params?.id || false;

  useEffect(() => {
    masterRateCardContext.getCategoryOptions();
    masterRateCardContext.getSubCategoryOptions(0);
    if (productId) {
      masterRateCardContext.getProduct(productId);
    }
  }, [productId]);

  const resolver = useYupValidationResolver(createValidationSchema);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver,
    defaultValues: {
      categoryId: "1",
      subCategoryId: "1",
      productVariants: [
        {
          name: "",
          baseRate: "",
          finalRate: "",
        },
      ],
    },
  });

  useEffect(() => {
    if (productId && productData.categoryId) {
      reset({
        ...productData,
      });
    }
  }, [productData]);

  const {
    fields: variantFields,
    remove: variantRemove,
    append,
  } = useFieldArray({
    control,
    name: `productVariants`,
  });

  const headTitle = isEditMode ? EDIT_TITLE_HEAD : CREATE_TITLE_HEAD;

  async function handleSaveItem(data) {
    if (productId) {
      masterRateCardContext
        .updateProduct(productId, data)
        .then((res) => {
          history.push(HOTEL_ROUTE.masterRateCard);
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      masterRateCardContext
        .createMasterRatecard(data)
        .then((res) => {
          history.push(HOTEL_ROUTE.masterRateCard);
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  }

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "MasterRateCard added Successfully",
    });
  };

  const categoryId = watch("categoryId");

  useEffect(() => {
    if (categoryId) {
      const subcategory = subCategoryOptions.find(
        (subCategory) => subCategory.parentId === Number(categoryId)
      );
      if (subcategory) {
        setValue("subCategoryId", String(subcategory.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  return (
    <React.Fragment>
      <Head title={headTitle} />
      <Content>
        <BlockHeadContent>
          <BlockTitle page>Master Rate Card form</BlockTitle>
        </BlockHeadContent>
        <div className="mt-4 p-4 bg-white rounded-sm">
          <Form
            className={"form-validate is-alter"}
            onSubmit={handleSubmit(handleSaveItem)}
          >
            <OverlineTitle tag="span" className="preview-title-lg">
              Item details
            </OverlineTitle>
            <Row>
              <Col md="6" lg="6">
                <InputField
                  labelText="Item name"
                  inputName="name"
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectField
                  labelText="Category"
                  inputName="categoryId"
                  inputType="select"
                  register={register}
                  errors={errors}
                  options={categorySelectOptions}
                />
              </Col>

              <Col>
                <SelectField
                  labelText="Sub Category"
                  inputName="subCategoryId"
                  inputType="select"
                  register={register}
                  errors={errors}
                  options={subCategoryOptions.map((subCategory) => ({
                    value: subCategory.id,
                    name: `${subCategory.name}`,
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col md="3" lg="3">
                <SelectField
                  labelText="Units"
                  inputName="unit"
                  inputType="select"
                  register={register}
                  errors={errors}
                  options={unitOptions}
                />
              </Col>

              <Col md="3" lg="3">
                <InputField
                  labelText="Net Wt."
                  inputName="netWeight"
                  inputType="text"
                  placeholder="0000"
                  register={register}
                  errors={errors}
                />
              </Col>

              <Col md="6" lg="6">
                <InputField
                  labelText="Max order qty"
                  inputName="maximumOrderQuantity"
                  inputType="text"
                  placeholder="0000"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>

            <div className="my-4" />
            <OverlineTitle tag="span" className="preview-title-lg">
              Price details
            </OverlineTitle>
            <Row>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <Label htmlFor="default-5" className="form-label">
                    Taxes
                  </Label>
                  <SelectField
                    labelText="Tax Type"
                    inputName="taxable"
                    inputType="select"
                    register={register}
                    errors={errors}
                    options={taxableOptions}
                  />
                </div>
              </Col>
              <Col md="4" lg="4">
                <div className="form-control-wrap mb-3">
                  <Label htmlFor="default-5" className="form-label">
                    Tax Slab
                  </Label>
                  <SelectField
                    labelText="Tax Rate"
                    inputName="taxSlab"
                    inputType="select"
                    register={register}
                    errors={errors}
                    options={taxCodes}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-control-wrap mb-3">
              <Label htmlFor="default-5" className="form-label">
                Variants <span>(if any)</span>
              </Label>

              <Button
                outline
                size="sm"
                fontSize="5px"
                color={"success"}
                className="ml-3"
                onClick={() =>
                  append({
                    name: "",
                    baseRate: 0,
                    finalRate: 0,
                  })
                }
              >
                {" "}
                + Add variant
              </Button>
              {variantFields.map((item, index) => {
                return (
                  <VariantDetails
                    key={item.id}
                    item={item}
                    index={index}
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    variantRemove={variantRemove}
                  />
                );
              })}
            </div>

            <Row></Row>
            <Row>
              <Col />
              <Button onClick={handleSubmit(handleSaveItem)} color="primary">
                Save Item
              </Button>
            </Row>
          </Form>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withProvider(MasterRateCardProvider, MasterRateCardCreatePage);
