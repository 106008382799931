import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ListGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  RSelect,
} from "../../../../components/Component";
import CustomTable from "../../../../components/table/CustomTable";
import Guard from "../../../../components/Guard";
import { PERMISSIONS } from "../../../../config/AppPermission";
import { withProvider } from "../../../../hoc/withProvider";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import StockpointProvider, {
  StockpointContext,
} from "../../providers/StockpointProvider";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { Link } from "react-router-dom";

const StockpointPage = (props) => {
  const stockpointContext = React.useContext(StockpointContext);
  const page = 1,
    size = 10;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 10);
  const [searchTerm, setSearchTerm] = useState("");

  const [sm, updateSm] = React.useState(false);

  const stockpoints = stockpointContext.stockpointsList.stockpoints || [];

  const renderActionButtons = (row) => {
    return (
      <div style={{ display: "block", width: "100%" }}>
        <ListGroup>
          <a href={`/hotels/stock-points/${row.id}/edit`}>
            <Button color="warning">Edit</Button>
          </a>
        </ListGroup>
      </div>
    );
  };
  /** @type {import("react-data-table-component").TableColumn[]} */
  const dataTableColumns = [
    {
      name: "Slno",
      selector: (row) => row.id,
      width: "50px",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "StockPoint name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (
        <div>
          {row.addressLine_1}, {row.addressLine_2}
        </div>
      ),
    },
    {
      name: "Landlineno",
      selector: (row) => row.landlineNumber,
    },
    {
      name: "Incharge Name",
      selector: (row) => row.inchargeName,
      cell: (row) => <div>{row.inchargeName || "N/A"}</div>,
    },
    {
      name: "Maplink",
      selector: (row) => row.maplink,
      cell: (row) => (
        <Link
          to={row.maplink}
          style={{ color: "royalblue", textDecoration: "underline" }}
        >
          {row.mapUrl}
        </Link>
      ),
    },
    {
      name: "Details",
      selector: (row) => row.maplink,
      cell: (row) => (
        <Link
          to="#"
          style={{ color: "lightgreen", textDecoration: "underline" }}
        >
          view details
        </Link>
      ),
    },
    {
      name: "Action",
      selector: (row) => row,
      cell: (row) => renderActionButtons(row),
    },
  ];

  React.useEffect(() => {
    stockpointContext.getStockpoints({
      page: currentPage,
      size: currentPageSize,
    });
  }, []);

  const totalStockpoints = stockpointContext.stockpointsList.total || 0;

  function handlePageChange(page) {
    setCurrentPage(page);
    stockpointContext.getStockpoints({
      page: page,
      size: currentPageSize,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);
    stockpointContext.getStockpoints({
      page: currentPage,
      size: showSize,
    });
  }

  function handleSort(sort) {
    console.log("sort :>> ", sort);
  }

  function handleSearchChange(searchText) {
    setSearchTerm(searchText)
    stockpointContext.getStockpoints({
      search: searchText,
      page: currentPage,
      size: currentPageSize,
    });
  }

  function renderStockpointList() {
    return (
      <Block>
        <CustomTable
          data={stockpoints}
          columns={dataTableColumns}
          expandableRows
          pagination
          onSort={handleSort}
          onSearchChange={handleSearchChange}
          onPageChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          pageShowSize = {currentPageSize}
          currentPageNum={currentPage}
          totalItems={stockpointContext.stockpointsList.total || 3}
          searchTerm={searchTerm}
        />
      </Block>
    );
  }

  function renderMainContent() {
    if (stockpointContext.isLoadingStockpoints) {
      return <Spinner />;
    }

    return renderStockpointList();
  }

  function renderCreateStockpointButton() {
    return (
      <BlockHeadContent>
        <div className="toggle-wrap nk-block-tools-toggle">
          <Button
            className={`btn-icon btn-trigger toggle-expand mr-n1 ${
              sm ? "active" : ""
            }`}
            onClick={() => updateSm(!sm)}
          >
            <Icon name="menu-alt-r"></Icon>
          </Button>
          <div
            className="toggle-expand-content"
            style={{ display: sm ? "block" : "none" }}
          >
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <NavLink to={HOTEL_ROUTE.stockPointCreate}>
                  <Button color="success">
                    <span>Add Stock Point</span>
                    <Icon className="pl-0" name="plus-sm" />
                  </Button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </BlockHeadContent>
    );
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Stockpoints List</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalStockpoints} stockpoints.</p>
            </BlockDes>
          </BlockHeadContent>

          <Guard permittedActions={[PERMISSIONS.CREATE_STOCK_POINT]}>
            {renderCreateStockpointButton()}
          </Guard>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="Stockpoint | Core App" />
      <Content>
        {renderHeaderSection()}
        {renderMainContent()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(StockpointProvider, StockpointPage);
