export const renderUnits = (unit) => {
  switch (unit) {
    case "KILOGRAM":
      return "kg";
    case "LITER":
      return "ltrs";
    case "GRAMS":
      return "grms";
    case "DOZEN":
      return "dozen";
    case "PACKET":
      return "pkt";
    case "MILILITER":
      return "ml";
    default:
      return "n/a";
  }
};
export const unitOptions = [
  { name: "kg", value: "KILOGRAM" },
  { name: "ltrs", value: "LITER" },
  // { name: "grms", value: "GRAMS" },
  { name: "dozen", value: "DOZEN" },
  { name: "pkt", value: "PACKET" },
  // { name: "ml", value: "MILILITER" },
];

export const taxableOptions = [
  { name: "Inclusive", value: "INCLUSIVE" },
  { name: "Exclusive", value: "EXCLUSIVE" },
  { name: "Exempted", value: "EXEMPTED" },
];

export const taxCodes = [
  { name: "0%", value: "0" },
  { name: "5%", value: "5" },
  { name: "12%", value: "12" },
  { name: "18%", value: "18" },
  { name: "28%", value: "28" },
];
