import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button } from "../../../../components/Component";
import { Badge, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import ButtonGroup from "../../../../components/utils/TopButtons";
import CustomTable from "../../../../components/table/CustomTable";
import { format } from "date-fns";
import { IndentContext } from "../../providers/IndentProvider";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { CoreContext } from "../../../../providers/CoreProvider";
import { SUPER_ADMIN, ADMIN } from "../../../../constants/common";
import { DownloadIndentsButton } from "./DownloadIndentsButton";

const IndentListing = (props) => {
  const indentContext = React.useContext(IndentContext);
  const indents = indentContext.indentsList.indents;
  const totalIndents = indentContext.indentsList.total || 0;
  const isLoadingIndents = indentContext.isLoadingIndents;
  const { currentPage, setCurrentPage, currentPageSize, setCurrentPageSize, queryParams, setQueryParams, resetIndentQueryState } = indentContext;

  const [searchTerm, setSearchTerm] = useState("");
  const coreContext = React.useContext(CoreContext);
  const currentUser = coreContext.currentUser;

  useEffect(() => {
    // reset indent query state on unmount
    return resetIndentQueryState;
  }, [resetIndentQueryState]);

  const dataTableColumns = [
    {
      name: "SL.No",
      selector: (row) => row.id,
      cell: (row, index) => <span>{(currentPage - 1) * currentPageSize + index + 1}</span>,
      width: "50px",
    },
    {
      name: "Indent Id",
      selector: (row) => row.orderNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "C.Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      width: "300px",
      selector: (row) => row?.orderedBranch?.name,
    },
    {
      name: "City",
      selector: (row) => row?.orderedBranch?.city,
      cell: (row) => <Badge color="danger">{row?.orderedBranch?.city}</Badge>,
    },
    {
      name: "Items",
      width: "250px",
      selector: (row) => row,
      cell: (row) => (
        <ListGroup className="w-100">
          {row.orderLineItems.map((item) => (
            <div className="d-flex flex-row" key={item.id}>
              <ListGroupItem className="flex-fill">{item?.productVariantOrdered?.name}</ListGroupItem>
            </div>
          ))}
        </ListGroup>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row,
      cell: (row) => (
        <ListGroup className="w-100">
          {row.orderLineItems.map((item) => (
            <div className="d-flex flex-row w-100" key={item.id}>
              <ListGroupItem className="flex-fill">{item?.currentQuantity}</ListGroupItem>
            </div>
          ))}
        </ListGroup>
      ),
    },
    {
      name: "Rate",
      width: "150px",
      selector: (row) => row,
      cell: (row) => (
        <ListGroup className="w-100">
          {row.orderLineItems.map((item) => (
            <div className="d-flex flex-row" key={item.id}>
              <ListGroupItem className="flex-fill">
                <del>&#2352;</del> {item?.rate}
              </ListGroupItem>
            </div>
          ))}
        </ListGroup>
      ),
    },
    {
      name: "Total Amount",
      width: "150px",
      selector: (row) => (
        <>
          <del>&#2352;</del> {row.totalAmount}
        </>
      ),
    },
    {
      name: "Request Date",
      width: "150px",
      selector: (row) => row.requestDate,
      sortable: true,
      cell: (row) => <span>{format(new Date(row.requestDate || null), "dd-MMM-yyyy")}</span>,
    },
    {
      name: "Delivery Date",
      width: "150px",
      selector: (row) => row.deliveryDate,
      sortable: true,
      cell: (row) => <span>{format(new Date(row.deliveryDate || null), "dd-MMM-yyyy")}</span>,
    },
    {
      name: "Status",
      width: "180px",
      selector: (row) => row.status,
      cell: (row) => <Badge color="danger">{row.status}</Badge>,
    },
    {
      name: "Action",
      width: "300px",
      selector: (row) => row.status,
      cell: (row) => renderActionButton(row.status, row.id),
    },
  ];

  const deliveryDateFilterOptions = [
    {
      id: "TODAY",
      name: "Today",
    },
    { id: "TOMORROW", name: "Tomorrow" },
    {
      id: "THIS_WEEK",
      name: "This Week",
    },
  ];

  const indentStatusFilter = [
    // {
    //   id: "DRAFT",
    //   name: "Draft",
    // },
    {
      id: "OPEN",
      name: "Open",
    },
    { id: "ACCEPTED", name: "Accepted" },
    // {
    //   id: "PROCESSING",
    //   name: "Processing",
    // },
    // {
    //   id: "STOCKPOINT_RECEIVED",
    //   name: "Stockpoint Received",
    // },
    // {
    //   id: "DISPATCHED",
    //   name: "Dispatched",
    // },
    {
      id: "DELIVERED",
      name: "Delivered",
    },
    // {
    //   id: "REJECTED",
    //   name: "Rejected",
    // },
    // {
    //   id: "HOLD",
    //   name: "On Hold",
    // },
    // {
    //   id: "CANCELLED",
    //   name: "Cancelled",
    // },
  ];

  const [deliveryDateFilter, setDeliveryDateFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");

  const handleDeliverDateFilterSelection = (deliveryDate) => {
    setDeliveryDateFilter(deliveryDate);
    setQueryParams({
      ...queryParams,
      delivery_date: deliveryDate === "all" ? "" : deliveryDate,
    });
    indentContext.getIndents({
      ...queryParams,
      delivery_date: deliveryDate === "all" ? "" : deliveryDate,
    });
  };

  const handleIndentStatusFilter = (indentStatus) => {
    setStatusFilter(indentStatus);
    setQueryParams({
      ...queryParams,
      status: indentStatus === "all" ? "" : indentStatus,
    });
    indentContext.getIndents({
      ...queryParams,
      status: indentStatus === "all" ? "" : indentStatus,
    });
  };

  function handlePageChange(page) {
    setCurrentPage(page);
    indentContext.getIndents({
      ...queryParams,
      page,
      size: currentPageSize,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);
    indentContext.getIndents({
      ...queryParams,
      page: currentPage,
      size: showSize,
    });
  }

  function handleSort(sort) { }

  function handleSearchChange(searchText) {
    setSearchTerm(searchTerm);
    indentContext.getIndents({
      ...queryParams,
      delivery_date: deliveryDateFilter === "all" ? "" : deliveryDateFilter,
      search: searchText,
    });
    setQueryParams({ ...queryParams, search: searchText });
  }

  const handleConfirmation = async (status, orderId) => {
    await indentContext.changeStatus({ status, orderId });
    handleRefresh();
  };
  const handleRefresh = () => {
    indentContext.getIndents({
      ...queryParams,
      page: currentPage,
      size: currentPageSize,
    });
  };

  function handleChangeStatus(status, orderId) {
    return confirmationSweetAlert({
      confirmButtonText: "Ok",
      text: "Do you want to change order status?",
      handleConfirmation: () => handleConfirmation(status, orderId),
    });
  }

  const handleAcceptAllIndents = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    indentContext.acceptAllIndents(formattedDate);
  };

  const renderActionButton = (status, orderId) => {
    switch (status) {
      case "DRAFT":
        return (
          <div className="d-flex">
            <Button className="mx-1 justify-content-center btn btn-sm" color="success" onClick={() => handleChangeStatus((status = "OPEN"), orderId)}>
              Open
            </Button>
            <Button
              className="mx-1 justify-content-center btn btn-sm"
              color="warning"
              onClick={() => handleChangeStatus((status = "REJECTED"), orderId)}
            >
              Reject
            </Button>

            <Button
              className="mx-1 justify-content-center btn btn-sm"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button>
          </div>
        );
      case "OPEN":
        return (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "ACCEPTED"), orderId)}
            >
              Accept
            </Button>
            {/* <Button className="btn btn-sm mx-1 justify-content-center" color="info" onClick={() => handleChangeStatus((status = "HOLD"), orderId)}>
              Hold
            </Button> */}
            {/* <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button> */}
          </div>
        );
      case "ACCEPTED":
        return (
          <div className="d-flex">
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "DELIVERED"), orderId)}
            >
              Delivered
            </Button>
            {/* <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "PROCESSING"), orderId)}
            >
              Processing
            </Button>
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button> */}
          </div>
        );
      case "PROCESSING":
        return (
          <div className="d-flex">
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "STOCKPOINT_RECEIVED"), orderId)}
            >
              Stockpoint Received
            </Button>
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button>
          </div>
        );
      case "STOCKPOINT_RECEIVED":
        return (
          <div className="d-flex">
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "DISPATCHED"), orderId)}
            >
              Dispatched
            </Button>
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button>
          </div>
        );
      case "DISPATCHED":
        return (
          <div className="d-flex">
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="info"
              onClick={() => handleChangeStatus((status = "DELIVERED"), orderId)}
            >
              Delivered
            </Button>
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button>
          </div>
        );
      case "HOLD":
        return (
          <div className="d-flex">
            <Button className="btn btn-sm mx-1 justify-content-center" color="info" onClick={() => handleChangeStatus((status = "OPEN"), orderId)}>
              Open
            </Button>
            <Button
              className="btn btn-sm mx-1 justify-content-center"
              color="danger"
              onClick={() => handleChangeStatus((status = "CANCELLED"), orderId)}
            >
              Cancel
            </Button>
          </div>
        );
      default:
        <></>;
    }
  };

  const hasPermission = currentUser?.userRoles?.includes(SUPER_ADMIN) || currentPage?.userRoles.includes(ADMIN);
  const isTodayFilterApplied = deliveryDateFilter === "TODAY";
  const isOpenFilterApplied = statusFilter === "OPEN";
  const buttonLabel = indentContext.isUpdateLoadingIndents ? <Spinner /> : "Accept All Indents";
  const enableButton = hasPermission && isTodayFilterApplied && isOpenFilterApplied && indents?.length;

  return (
    <React.Fragment>
      <Head title="Indent List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                <div className="d-flex align-items-center gap-2">
                  Indent List
                  {isLoadingIndents && <Spinner className="ml-2" size={"sm"} />}
                </div>
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have totaly {totalIndents} Indents.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="d-flex flex-wrap">
          <label className="lead mr-3 font-weight-bold">Delivery Date:</label>
          <ButtonGroup
            data={[{ name: "All", id: "all" }].concat(
              deliveryDateFilterOptions.map((deliveryDate) => ({
                name: deliveryDate.name,
                id: deliveryDate.id,
              }))
            )}
            activeId={deliveryDateFilter}
            onItemClick={handleDeliverDateFilterSelection}
          />
        </div>
        <div className="d-flex flex-wrap">
          <label className="lead mr-3 font-weight-bold">Status:</label>
          <ButtonGroup
            data={[{ name: "All", id: "all" }].concat(
              indentStatusFilter.map((status) => ({
                name: status.name,
                id: status.id,
              }))
            )}
            activeId={statusFilter}
            onItemClick={handleIndentStatusFilter}
          />
        </div>

        {enableButton && (
          <button className="btn btn-primary" onClick={handleAcceptAllIndents}>
            {buttonLabel}
          </button>
        )}
        {
          <div className="d-flex justify-content-end">
            <DownloadIndentsButton />
          </div>
        }
        {isLoadingIndents && totalIndents === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner className="m-2" size={"xl"} />
          </div>
        ) : (
          <CustomTable
            data={indents}
            columns={dataTableColumns}
            expandableRows
            pagination
            selectableRows={false}
            onSearchChange={handleSearchChange}
            onSort={handleSort}
            onPageChange={handlePageChange}
            onShowSizeChange={handleShowSizeChange}
            pageShowSize={currentPageSize}
            currentPageNum={currentPage}
            totalItems={totalIndents}
            searchTerm={searchTerm}
          />
        )}
      </Content>
    </React.Fragment>
  );
};


export default IndentListing;
