import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { OverlineTitle } from "../../../../components/Component";
import InputField from "../../../../components/utils/InputComponent";
import SelectField from "../../../../components/utils/SelectComponent";

const BillingTerms = ({
  errors,
  register,
  mode,
  branchIndex,
  branchAccount,
  setValue,
}) => {
  useEffect(() => {
    if (branchAccount) {
      branchAccount.forEach((element, index) => {
        setValue(
          `branchDetails[${branchIndex}].creditPeriod`,
          element?.creditPeriod
        );
        setValue(
          `branchDetails[${branchIndex}].gracePeriod`,
          element?.gracePeriod
        );
        setValue(
          `branchDetails[${branchIndex}].creditLimit`,
          element?.creditLimit
        );
        setValue(`branchDetails[${branchIndex}].pocName`, element?.pocName);
        setValue(
          `branchDetails[${branchIndex}].pocPhoneNumber`,
          element?.pocPhoneNumber
        );
        setValue(`branchDetails[${branchIndex}].pocEmail`, element?.pocEmail);
      });
    }
  });

  return (
    <React.Fragment>
      <div className="my-4" />
      <OverlineTitle tag="span" className="preview-title-lg">
        Billing Terms
      </OverlineTitle>
      <Row>
        <Col>
          <div className="form-control-wrap mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text bg-white"
                  id="inputGroup-sizing-lg"
                >
                  Billing Method
                </span>
              </div>
              <div className="custom-radio custom-control p-1">
                <input
                  name="mode"
                  value={mode === "POSTPAY" ? "POSTPAY" : "PREPAY"}
                  {...register(`branchDetails[${branchIndex}].mode`)}
                  type="radio"
                  checked={true}
                  className=" custom-control custom-radio custom-control-sm"
                />
                <label className="m-auto">
                  {mode === "POSTPAY" ? "Postpay" : "Prepay"}
                </label>
              </div>
            </div>

            {errors.mode && (
              <span className="invalid">{errors.mode?.message}</span>
            )}
          </div>
        </Col>
        {mode === "POSTPAY" ? (
          <Col>
            <SelectField
              labelText="Invoice Terms"
              inputType="select"
              inputName={`branchDetails[${branchIndex}].creditPeriod`}
              register={register}
              errors={errors}
              options={[
                { value: 7, name: "Nett 7 Days" },
                { value: 15, name: "Nett 15 Days" },
                { value: 30, name: "Nett 30 Days" },
                { value: 45, name: "Nett 45 Days" },
                { value: 60, name: "Nett 60 Days" },
              ]}
            />
          </Col>
        ) : (
          <Col></Col>
        )}
      </Row>
      {mode === "POSTPAY" ? (
        <Row>
          <Col>
            <SelectField
              labelText="Grace Period"
              inputType="select"
              inputName={`branchDetails[${branchIndex}].gracePeriod`}
              register={register}
              errors={errors}
              options={[
                { value: 3, name: "3 Days" },
                { value: 7, name: "7 Days" },
                { value: 15, name: "15 Days" },
                { value: 30, name: "30 Days" },
                { value: 45, name: "45 Days" },
                { value: 60, name: "60 Days" },
              ]}
              required={true}
            />
          </Col>
          <Col>
            <InputField
              labelText="Postpay Credit Limit"
              inputName={`branchDetails[${branchIndex}].creditLimit`}
              inputType="number"
              register={register}
              errors={errors}
              required={true}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col>
          <InputField
            labelText="Billing POC Name"
            inputName={`branchDetails[${branchIndex}].pocName`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="Billing POC Number"
            inputName={`branchDetails[${branchIndex}].pocPhoneNumber`}
            inputType="text"
            register={register}
            errors={errors}
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="Email (For Invoicing)"
            inputName={`branchDetails[${branchIndex}].pocEmail`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
};

export default BillingTerms;
