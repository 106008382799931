import React, { useState, useEffect, useRef, useCallback } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import indentRepository from "../repositories/IndentRepository";
import { emptyHandler } from "../../../utils/Utils";
import { Howl } from "howler";
import notifySound from "../../../assets/sounds/loud_notification.mp3";
import { useInterval } from "../../../hooks/useInterval";
import { useStableCallback } from "../../../hooks/useStableCallback";

const POLL_PERIOD_MS = 1000 * 30;
const DEFAULT_PAGE_SIZE = 10;


export const IndentContext = React.createContext({
  isLoadingIndents: false,
  indentsList: { indents: [], total: 0 },
  getIndents: async () => emptyHandler,
  changeStatus: async () => emptyHandler,
  acceptAllIndents: async () => emptyHandler,
  getIndentsToDownload: async () => emptyHandler,
  isUpdateLoadingIndents: false,
  indentCount: 0,
});

const IndentProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingIndents, iliState] = useBoolean(false);
  const [isUpdateLoadingIndents, iuliState] = useBoolean(false);
  const [indentsList, setIndentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [queryParams, setQueryParams] = useState({});
  const howlRef = useRef(null);

  async function getIndents(params) {
    try {
      if (!isLoadingIndents) {
        iliState.on();
        const response = await indentRepository.getIndents(params);
        // testPollingLogic(setIndentsList, response.indents);
        setIndentsList({
          indents: response.indents.items,
          total: response.indents.pagination.totalCount,
        });
      }
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iliState.off();
    }
  }

  async function getIndentsToDownload() {
    try {
      const response = await indentRepository.getIndentsToDownload();
      return response.indents;
    } catch (e) {
      console.error("failed to download", e);
      toast.errorToast(e.toString());
    } finally {
    }
    
  }

  async function changeStatus(params) {
    try {
      iliState.on();
      await indentRepository.changeStatus(params);
    } catch (e) {
      toast.errorToast(`Failed to poll - ${e.toString()}`);
    } finally {
      iliState.off();
    }
  }

  async function acceptAllIndents(params) {
    try {
      iuliState.on();
      await indentRepository.acceptAllIndents(params);
      setIndentsList([]);
      toast.successToast("Updated succesfully");
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iuliState.off();
    }
  }

  function playNotificationSound() {
    try {
      if (howlRef.current) {
        console.log("sound is already playing");
        return;
      }
      console.log("playing notification sound");
      howlRef.current = new Howl({
        src: [notifySound],
        loop: true,
      });
      howlRef.current.play();
    } catch (error) {
      console.error("error in playing notification sound", error);
    }
  }

  const resetIndentQueryState = useCallback(() => {
    setCurrentPage(1);
    setCurrentPageSize(DEFAULT_PAGE_SIZE);
    setQueryParams({});
  }, []);

  useInterval(
    useStableCallback(() => {
      getIndents({
        ...queryParams,
        page: currentPage,
        size: currentPageSize,
      });
    }),
    POLL_PERIOD_MS
  );

  useEffect(() => {
    if (!indentsList?.indents) return;
    const openIndents = indentsList.indents.filter((i) => ["DRAFT", "OPEN"].includes(i.status));

    if (openIndents.length > 0) {
      playNotificationSound();
    } else if (howlRef.current) {
      try {
        howlRef.current.stop();
      } catch (error) {
        console.error("error in stopping notification sound", error);
      }
      howlRef.current = null;
    }
  }, [indentsList.indents]);

  const mContext = {
    isLoadingIndents,
    indentsList,
    getIndents,
    getIndentsToDownload,
    changeStatus,
    acceptAllIndents,
    isUpdateLoadingIndents,
    currentPage,
    setCurrentPage,
    currentPageSize,
    setCurrentPageSize,
    queryParams,
    setQueryParams,
    resetIndentQueryState,
  };

  return <IndentContext.Provider value={mContext}>{props.children}</IndentContext.Provider>;
};

/**
 * don't call this in actual app. This makes the top item open, and
 * then makes it accepted after a few seconds. Simulating order coming
 * and acceptance for testing notification sound.
 */
function testPollingLogic(setIndentsList, indents) {
  if (process.env.NODE_ENV === "production") return;
  indents.items[0].status = "OPEN";
  setTimeout(() => {
    indents.items[0].status = "ACCEPTED";
    setIndentsList({
      indents: [...indents.items],
      total: indents.pagination.totalCount,
    });
  }, 5000);
}

export default IndentProvider;
