import React, { useContext, useEffect } from "react";
import { withProvider } from "../../../../hoc/withProvider";
import HotelCustomerProvider, {
  HotelCustomerContext,
} from "../../providers/HotelCustomerProvider";
import { useParams } from "react-router-dom";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import AddNewPostpayCustomerPage from "./AddNewPostpayCustomerPage";
import AddNewPrepayCustomerPage from "./AddNewPrepayCustomerPage";
import { Spinner } from "reactstrap";

const EditHotelCustomer = (props) => {
  const { id } = useParams();

  const hotelCustomerContext = useContext(HotelCustomerContext);
  const customerData = hotelCustomerContext.customerData || {};

  useEffect(() => {
    hotelCustomerContext.getCustomerData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head title="Edit Customer | Hotel" />
      <Content>
          {customerData.category === "POSTPAY" ? (
            <AddNewPostpayCustomerPage
              customerData={customerData}
              hotelId={id}
            />
          ) : customerData.category === "PREPAY" ? (
            <AddNewPrepayCustomerPage
              customerData={customerData}
              hotelId={id}
            />
          ) : (
            <Spinner />
          )}
      </Content>
    </React.Fragment>
  );
};
export default withProvider(HotelCustomerProvider, EditHotelCustomer);
