import React, { useState, useContext, useEffect } from "react";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
} from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import CustomTable from "../../../../components/table/CustomTable";
import ButtonGroup from "../../../../components/utils/TopButtons";
import EditableCell from "../../../../components/utils/EditableCell";
import { MasterRateCardContext } from "../../providers/MasterRateCardProvider";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { NavLink, useHistory } from "react-router-dom";
import { renderUnits } from "./Constants";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast } from "../../../../components/toastify/Toastify";

const MasterRateCard = () => {
  const history = useHistory();

  const masterRateCardContext = useContext(MasterRateCardContext);
  const { masterRateCardsList, categoryList } = masterRateCardContext;
  const [categorySelection, setCategorySelection] = useState("all");
  const page = 1,
    size = 10;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 10);
  const [searchTerm, setSearchTerm] = useState("");

  const [sm, updateSm] = React.useState(false);

  const masterRateCards = masterRateCardsList.masterRateCardsList || [];

  const totalMaterRateCard = masterRateCardsList.total;

  const [updateFields, setUpdateFields] = useState([]);

  useEffect(() => {
    masterRateCardContext.getMasterRateCards({ category: categorySelection });
  }, []);

  const handleBasePriceSave = (e, row) => {
    if (e.previousValue !== e.value && e.value >= 0) {
      const index = updateFields.findIndex((field) => field.id === row.id);
      if (index !== -1) {
        updateFields[index].baseRate = e.value;
      } else {
        const obj = {
          id: row.id,
          baseRate: e.value,
        };
        const arrayOfFields = [...updateFields, obj];
        setUpdateFields(arrayOfFields);
      }
    }
  };

  const handleCategorySelection = (category_id) => {
    setCategorySelection(category_id);
    masterRateCardContext.getMasterRateCards({ category: category_id });
  };

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "MasterRateCard updated Successfully",
    });
  };

  const handleUpdate = () => {
    if (updateFields.length) {
      masterRateCardContext
        .updateMasterRateCard(updateFields)
        .then((res) => {
          history.push(HOTEL_ROUTE.masterRateCard);
          masterRateCardContext.getMasterRateCards({
            category: categorySelection,
          });
          handleSuccessConfirmation();
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
      width: "5%",
      cell: (row, index) => <span>{currentPageSize * (currentPage - 1) + index + 1}</span>,
    },
    {
      name: "Item Name",
      selector: (row) => row.name,
      width: "15%",
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row.variantToProduct.productCategory.name,
    },
    {
      name: "Units",
      selector: (row) => row.variantToProduct.unit,
      cell: (row) => <span>{renderUnits(row.variantToProduct.unit)}</span>,
      width: "6%",
    },
    {
      name: "Net Weight",
      selector: (row) => row.variantToProduct.netWeight,
      width: "8%",
    },
    {
      name: "Max Order Qty",
      selector: (row) => row.variantToProduct.maximumOrderQuantity,
      width: "8%",
    },
    {
      name: "Base Price",
      selector: (row) => row.baseRate,
      cell: (row) => (
        <EditableCell
          name="baseRate"
          type="number"
          defaultValue={`${row?.baseRate}`}
          row={row}
          handleSave={handleBasePriceSave}
          showEditButton
        />
      ),
    },
    {
      name: "Tax Type",
      selector: (row) => row.variantToProduct.taxable,
      width: "10%",
    },
    {
      name: "CGST",
      selector: (row) => row?.variantToProduct?.taxSlab,
      width: "6%",
      cell: (row) => <span>{row?.variantToProduct?.taxSlab / 2}</span>,
    },
    {
      name: "SGST",
      selector: (row) => row?.variantToProduct?.taxSlab,
      width: "6%",
      cell: (row) => <span>{row?.variantToProduct?.taxSlab / 2}</span>,
    },
    {
      name: "Final Price",
      selector: (row) => row.finalRate,
      width: "8%",
    },
    {
      name: "Edit",
      selector: (row) => row.hotelProductId,
      cell: (row) => {
        return (
          <a href={`/hotels/master-rate-card/${row?.variantToProduct?.id}/edit`}>
            <span style={{ color: "deepskyblue" }}>Edit</span>
          </a>
        );
      },
    },
  ];
  function renderCreateMasterCardButton() {
    return (
      <BlockHeadContent>
        <div className="toggle-wrap nk-block-tools-toggle">
          <Button
            className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
            onClick={() => updateSm(!sm)}
          >
            <Icon name="menu-alt-r"></Icon>
          </Button>
          <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <NavLink to={HOTEL_ROUTE.masterRateCardCreate}>
                  <Button color="danger">
                    <span>Add Master Rate Card</span>
                    <Icon className="pl-0" name="plus-sm" />
                  </Button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </BlockHeadContent>
    );
  }

  function handleSearchChange(searchText) {
    setSearchTerm(searchText)
    masterRateCardContext.getMasterRateCards({
      category: categorySelection,
      search: searchText,
    });
  }

  function handlePageChange(page) {
    setCurrentPage(page);

    masterRateCardContext.getMasterRateCards({
      page: page,
      size: currentPageSize,
      category: categorySelection,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);

    masterRateCardContext.getMasterRateCards({
      page: currentPage,
      size: showSize,
      category: categorySelection,
    });
  }

  return (
    <React.Fragment>
      <Head title="Master Ratecard"></Head>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Master Ratecard
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalMaterRateCard} masterRateCards.</p>
            </BlockDes>
          </BlockHeadContent>
          {/* <Guard permittedActions={[PERMISSIONS.CREATE_MASTER_RATE_CARD]}>  */}
          {renderCreateMasterCardButton()}
          {/* </Guard> */}
        </BlockBetween>
      </BlockHead>
      <div className="d-flex flex-wrap">
        <ButtonGroup
          data={[{ name: "All", id: "all" }].concat(
            categoryList.map((category) => ({
              name: category.name,
              id: category.id,
            }))
          )}
          activeId={categorySelection}
          onItemClick={(item) => handleCategorySelection(item)}
        />
      </div>
      <div className="d-flex flex-row-reverse mb-4">
        <Button color="info" onClick={handleUpdate}>
          Update Master Rate Card
        </Button>
      </div>
      <div>
        <CustomTable
          data={masterRateCards}
          columns={columns}
          expandableRows
          pagination
          onPageChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
          onSearchChange={handleSearchChange}
          pageShowSize={currentPageSize}
          currentPageNum={currentPage}
          totalItems={masterRateCardContext.masterRateCardsList.total}
          searchTerm={searchTerm}
        />
      </div>
    </React.Fragment>
  );
};
export default MasterRateCard;
