import React, { useContext } from "react";
import { PERMISSIONS } from "../../../../../config/AppPermission";
import useAuthorization from "../../../../../hooks/useAuthorization";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import MasterRateCard from "../MasterRateCardPage";
import { withProvider } from "../../../../../hoc/withProvider";
import MasterRateCardProvider, {
  MasterRateCardContext,
} from "../../../providers/MasterRateCardProvider";

const MasterRateCardListContainer = (props) => {
  const masterRateCardContext = useContext(MasterRateCardContext);
  const { getMasterRateCards, getCategories } = masterRateCardContext;

  React.useEffect(() => {
    getMasterRateCards();
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <Head title="Create Stockpoint | Core App" />
      <Content>
        <MasterRateCard />
      </Content>
    </React.Fragment>
  );
};
export default withProvider(
  MasterRateCardProvider,
  MasterRateCardListContainer
);
