import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import CoreAppLayout from "./pages/CoreAppLayout";

const CoreApp = (props) => {
  return (
    <Suspense>
      <Switch>
        <Route exact path="" component={CoreAppLayout} />
      </Switch>
    </Suspense>
  );
};

export default CoreApp;
