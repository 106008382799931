/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useBoolean from "./useBoolean";

const useMounted = () => {
  const [mounted, mState] = useBoolean(false);

  useEffect(() => {
    mState.on();
  }, []);

  return mounted;
};

export default useMounted;
