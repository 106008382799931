import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { OverlineTitle } from "../../../../components/Component";
import InputField from "../../../../components/utils/InputComponent";
import SelectField from "../../../../components/utils/SelectComponent";

const CustomerKYCForm = (props) => {
  const { register, errors, customerData, hotelId, setValue } = props;
  useEffect(() => {
    if (hotelId) {
      setValue("name", customerData?.name);
      setValue("legalEntityName", customerData?.legalEntityName);
      setValue("addressLine_1", customerData?.addressLine_1);
      setValue("addressLine_2", customerData?.addressLine_2);
      setValue("postalCode", customerData?.postalCode);
      setValue("city", customerData?.city);
      setValue("addressLat", customerData?.addressLat);
      setValue("addressLong", customerData?.addressLong);
      setValue("addressMapUrl", customerData?.addressMapUrl);
      setValue("gstin", customerData?.gstin);
      setValue("pan", customerData?.pan);
      setValue("gin", customerData?.gin);
      setValue("cin", customerData?.cin);
      setValue("tier", customerData?.tier);
    }
  }, []);
  return (
    <React.Fragment>
      <OverlineTitle tag="span" className="preview-title-lg">
        KYC details
      </OverlineTitle>
      <Row>
        <Col>
          <InputField
            labelText="Hotel Name"
            inputName="name"
            inputType="text"
            register={register}
            errors={errors}
            required={true}
          />
        </Col>
        <Col>
          <InputField
            labelText="Legal Entity Name"
            inputName="legalEntityName"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="Address Line 1"
            inputName="addressLine_1"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="Address Line 2"
            inputName="addressLine_2"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="Zipcode"
            inputName="postalCode"
            inputType="number"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="City"
            inputName="city"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="GST Number"
            inputName="gstin"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="PAN Number"
            inputName="pan"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="GIN Number"
            inputName="gin"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="CIN Number"
            inputName="cin"
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectField
            labelText="Customer Type"
            inputName="tier"
            inputType="select"
            register={register}
            errors={errors}
            options={[
              { name: "A", value: "a" },
              { name: "B", value: "b" },
              { name: "C", value: "c" },
            ]}
          />
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
};

export default CustomerKYCForm;
