/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import CustomTable from "../../../../components/table/CustomTable";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { format } from "date-fns";
import { withProvider } from "../../../../hoc/withProvider";
import HotelDashboardProvider, { HotelDashboardContext } from "../../providers/DashboardProvider";
import { Link } from "react-router-dom";

const IndentOverDuePage = (props) => {
  const hotelDashboardContext = React.useContext(HotelDashboardContext);
  const overDueList = hotelDashboardContext.overDueList || [];
  const totalCount = hotelDashboardContext.totalCount;
  const page = 1,
    size = 25;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 25);

  React.useEffect(() => {
    hotelDashboardContext.getOverDueList();
  }, []);

  const dataTableColumns = [
    {
      name: "SL.No",
      selector: (row) => row.id,
      width: "5%",
      cell: (row, index) => <span>{(currentPage - 1) * currentPageSize + index + 1}</span>,
    },
    {
      name: "Hotel Name",
      selector: (row) => row.hotelName,
      sortable: true,
    },
    {
      name: "Amount Over Due",
      selector: (row) => row.amountOverDue,
      cell: (row) => <span className="font-weight-bold">{`${+(Math.round(row.amountOverDue + "e+2")  + "e-2")}`}</span>,
      sortable: true,
    },
    {
      name: "Days",
      selector: (row) => row.days,
      cell: (row) => <span className="font-weight-bold">{row.days}</span>,
    },
    {
      name: "Type Of Customer",
      selector: (row) => row.type,
    },
    {
      name: "Edit",
      selector: (row) => row,
      cell:(row) => <Link to="#">Notify</Link>
    },
  ];

  function handlePageChange(page) {
    setCurrentPage(page);
    hotelDashboardContext.getLogHistories({
      page: currentPage,
      size: currentPageSize,
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);
    hotelDashboardContext.getLogHistories({
      page: currentPage,
      size: currentPageSize,
    });
  }

  function handleSort(sort) {}

  function handleSearchChange(searchTerm) {}

  function renderOverDueListTable() {
    return (
      <Block>
        <CustomTable
          data={overDueList}
          columns={dataTableColumns}
          expandableRows
          // pagination
          // selectableRows={false}
          // onSearchChange={handleSearchChange}
          onSort={handleSort}
          searchBar={false}
          bulkActionsEnable={false}
          // onPageChange={handlePageChange}
          // onShowSizeChange={handleShowSizeChange}
          // onSaveFilter={() => {}}
          // onResetFilter={() => {}}
        />
      </Block>
    );
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Invoice Over Due List</BlockTitle>
            <BlockDes className="text-soft">
              <p>You have total {totalCount} Over Due Customers.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="Invoice Over Due List | Core App" />
      <Content>
        {renderHeaderSection()}
        {renderOverDueListTable()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(HotelDashboardProvider, IndentOverDuePage);
