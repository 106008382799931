import React from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

const EggTrackingDashboard = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Egg Tracking | Dashboard Page" />
      <Content>
        <p>Egg Tracking Dashboard</p>
      </Content>
    </React.Fragment>
  );
};

export default EggTrackingDashboard;
