import React, { createContext, useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import HotelRepository from "../repositories/HotelRepository";
import useCustomToast from "../../../hooks/useCustomToast";
import { emptyHandler } from "../../../utils/Utils";
import { useParams } from "react-router";

export const HotelAppContext = createContext({});

export const CustomerRateCardContext = React.createContext({
  isLoadingCustomerRates: false,
  createCustomerRatecard: async () => emptyHandler,
  getCustomerRateCard: async () => emptyHandler,
  customerRateCard: [],
});

const HotelAppProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingCustomerRates, ilcState] = useBoolean(false);
  const [customerRateCard, setCustomerRateCard] = useState([]);

  const createCustomerRatecard = async (createParams, hotelId) => {
    try {
      ilcState.on();
      const res = await HotelRepository.createCustomerRateCard(
        createParams,
        hotelId
      );
      return res;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  };

  async function getCustomerRateCard(params) {
    try {
      ilcState.on();
      const response = await HotelRepository.getCustomerRateCard(params);
      setCustomerRateCard(response.customerRateCard);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilcState.off();
    }
  }

  const mContext = {
    isLoadingCustomerRates,
    createCustomerRatecard,
    getCustomerRateCard,
    customerRateCard: [],
  };

  return (
    <HotelAppContext.Provider value={mContext}>
      {props.children}
    </HotelAppContext.Provider>
  );
};

export default HotelAppProvider;
