import CoreApp from "../apps/core/CoreApp";
import { CORE_APP_ROUTES } from "../apps/core/routes/CoreAppRoute";
import EggTrackingApp from "../apps/egg_tracking/EggTrackingApp";
import { EGG_TRACKING_ROUTE } from "../apps/egg_tracking/routes/EggTrackingRoute";
import HotelApp from "../apps/hotel_management/HotelApp";
import { HOTEL_ROUTE } from "../apps/hotel_management/routes/HotelRoute";

export const APP_MODULES = {
  HOTEL_APP: "HOTEL_APP",
  EGG_TRACKING_APP: "EGG_TRACKING_APP",
  CORE_APP: "CORE_APP",
};

export const APP_MODULE_PREFIX = {
  [APP_MODULES.HOTEL_APP]: {
    urlPrefix: "/hotels",
    component: HotelApp,
    description:
      "Hotel managers are responsible for managing employees and for planning, marketing , coordinating and administering hotel services such as catering and accommodation facilities.",
    icon: "coffee",
    title: "Hotel Management",
    link: HOTEL_ROUTE.dashboard,
  },
  [APP_MODULES.EGG_TRACKING_APP]: {
    urlPrefix: "/egg-trackings",
    component: EggTrackingApp,
    description:
      "Running an egg business is more than just feeding hens, collecting and selling eggs",
    icon: "map-pin",
    title: "Egg Tracking",
    link: EGG_TRACKING_ROUTE.dashboard,
  },
  [APP_MODULES.CORE_APP]: {
    urlPrefix: "/core",
    component: CoreApp,
    description: "Core applications",
    icon: "laptop",
    title: "Core App",
    link: CORE_APP_ROUTES.userManagement,
  },
};
