import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * https://thoughtspile.github.io/2021/04/07/better-usecallback/
 * gives a stable reference to the callback function. This prevents unnecessary
 * useEffects running, and also prevents stale closures in event handlers. You also
 * don't need to pass any dependencies.
 *
 * example-
 * const cb = useStableCallback(() => console.log('hello'));
 *
 * If you face an issue where it seems the older callback is getting called, you might be
 * calling the function during the render phase. it should either be called in an effect or
 * on a manual trigger.
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
export function useStableCallback(callback) {
    const callbackRef = useRef(callback);
  
    useLayoutEffect(() => {
      callbackRef.current = callback;
    });
    const stable = useCallback((...args) => callbackRef.current(...args), []);
  
    return stable;
  }
  