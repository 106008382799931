/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Slider from "react-slick";
import { Card, Row, Col, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../../components/Component";
import {
  SlickArrowLeft,
  SlickArrowRight,
} from "../../../../components/partials/slick/SlickComponents";
import { withProvider } from "../../../../hoc/withProvider";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import HotelPaymentProvider, {
  HotelPaymentContext,
} from "../../providers/HotelPaymentProvider";

const settings2 = {
  className: "slider-init plan-list",
  slidesToShow: 3,
  centerMode: false,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    { breakpoint: 1539, settings: { slidesToShow: 3 } },
    { breakpoint: 992, settings: { slidesToShow: 2 } },
    { breakpoint: 768, settings: { slidesToShow: 1 } },
  ],
  slide: "li",
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
};

const PaymentHistoryPage = ({ ...props }) => {
  const paymentProvider = useContext(HotelPaymentContext);

  useEffect(() => {
    paymentProvider.fetchPaymentHistory();
  }, []);

  function renderPaymentHistory() {
    if (paymentProvider.isLoadingData) {
      return <Spinner />;
    }

    return (
      <Block size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">Pricing Table Carousel</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <PreviewCard>
          <Slider {...settings2}>
            {paymentProvider.paymentHistory.map((item) => (
              <Col key={item.id}>
                <Card className="card-bordered pricing">
                  <div className="pricing-head">
                    <div className="pricing-title">
                      <h4 className="card-title title">{item.title}</h4>
                      <p className="sub-text">{item.caption}</p>
                    </div>
                    <div className="card-text">
                      <Row>
                        <Col className="col-6">
                          <span className="lead-text">{item.interest}%</span>
                          <span className="sub-text">Daily Interest</span>
                        </Col>
                        <Col className="col-6">
                          <span className="lead-text">{item.term}</span>
                          <span className="sub-text">Term Days</span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="pricing-body">
                    <ul className="pricing-features">
                      <li>
                        <span className="w-50">Min Deposit</span> -{" "}
                        <span className="ml-auto">${item.minDeposit.usd}</span>
                      </li>
                      <li>
                        <span className="w-50">Max Deposit</span> -{" "}
                        <span className="ml-auto">${item.maxDeposit.usd}</span>
                      </li>
                      <li>
                        <span className="w-50">Deposit Return</span> -{" "}
                        <span className="ml-auto">Yes</span>
                      </li>
                      <li>
                        <span className="w-50">Total Return</span> -{" "}
                        <span className="ml-auto">{item.totalReturn}%</span>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
            ))}
          </Slider>
        </PreviewCard>
      </Block>
    );
  }

  return (
    <React.Fragment>
      <Head title="Hotel | Payment History Page" />
      <Content>{renderPaymentHistory()}</Content>
    </React.Fragment>
  );
};

export default withProvider(HotelPaymentProvider, PaymentHistoryPage);
