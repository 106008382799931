import React from "react";

const InputField = (props) => {
  const {
    labelText,
    key,
    inputName,
    inputType,
    register,
    errors,
    placeholder = "",
    handleChange,
    value,
    required
  } = props;
  return (
    <div className="form-control-wrap mb-3">
      <div className="input-group">
        <div className="input-group-prepend">
          {labelText ? (
            <span
              className="input-group-text bg-white"
              id="inputGroup-sizing-lg"
            >
              {labelText}
              {required ? <span className="text-danger">*</span> : ""}
            </span>
          ) : (
            <></>
          )}
        </div>
        <input
          key={key}
          name={inputName}
          type={inputType}
          value={value}
          placeholder={placeholder}
          className="form-control"
          {...register(inputName)}
          onChange={handleChange}
        />
      </div>
      {errors[inputName] && (
        <span className="invalid">{errors[inputName].message}</span>
      )}
    </div>
  );
};

export default InputField;
