import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SuccessToast = ({ description }) => (
  <div className="toastr-text">
    <h5>Success</h5>
    <p>{description}</p>
  </div>
);

const ErrorToast = ({ description }) => (
  <div className="toastr-text">
    <h5>Error</h5>
    <p>{description}</p>
  </div>
);

export const successToast = (props) => {
  const { description, position } = props;

  toast.success(<SuccessToast description={description} />, {
    position: position || "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
  });
};

export const warningToast = (props) => {
  const { description, position } = props;

  toast.warn(description, {
    position: position || "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <></>,
  });
};

export const infoToast = (props) => {
  const { description, position } = props;

  toast.info(description, {
    position: position || "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <></>,
  });
};

export const errorToast = (props) => {
  const { description, position } = props;

  toast.error(<ErrorToast description={description} />, {
    position: position || "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <></>,
  });
};
