import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import { RedirectAs404 } from "../../../utils/Utils";
import HotelDashboardPage from "../pages/dashboard/DashboardPage";
import MasterRateCardFormContainer from "../pages/master_rate_card/containers/MasterRateCardFormContainer";
import PaymentHistoryPage from "../pages/payments/PaymentHistoryPage";
import StockpointCreateContainer from "../pages/stock_points/StockPointCreateContainer";
import StockpointPage from "../pages/stock_points/StockPointsPage";
import IndentOverdueList from "../pages/dashboard/IndentOverdueList";
import VehicleMasterCreatePage from "../pages/vehicle_master/VehicleMasterCreatePage";
import VehicleMasterPage from "../pages/vehicle_master/VehicleMasterPage";
import AddNewCustomerPage from "../pages/customer/AddNewCustomerPage";
import AddNewPostpayCustomerPage from "../pages/customer/AddNewPostpayCustomerPage";
import AddNewPrepayCustomerPage from "../pages/customer/AddNewPrepayCustomerPage";
import ListCustomers from "../pages/customer/ListCustomers";
import LogHistoryPage from "../pages/customer/LogHistoryPage";
import IndentListing from "../pages/indent/IndentListing";
import MasterRateCardListContainer from "../pages/master_rate_card/containers/MasterRateCardListContainer";
import CustomerRateCardListContainer from "../pages/customer/container/CustomerRateCardPageContainer";
import EditHotelCustomer from "../pages/customer/EditHotelCustomer";
import InvoiceOverDuePage from "../pages/dashboard/InvoiceOverDuePage";
import IndentProvider from "../providers/IndentProvider";
import { withProvider } from "../../../hoc/withProvider";

export const HOTEL_ROUTE = {
  dashboard: "/hotels/dashboard",
  dashboardInvoiceOverDueList: "/hotels/dashboard/invoice-over-due-list",

  // master rate card
  masterRateCard: "/hotels/master-rate-card",
  masterRateCardCreate: "/hotels/master-rate-card/create",
  masterRateCardEdit: "/hotels/master-rate-card/:id/edit",

  // payments
  paymentHistory: "/hotels/payments/history",

  //Indents
  indentOverdue: "/hotels/indent-overdue",

  //vehicle master
  editVehicles: "/hotels/vehicle-masters/:id/edit",
  vehicle: "/hotels/vehicle-masters/:id/",
  vehicleMasterCreate: "/hotels/vehicle-masters/create",
  vehicleMaster: "/hotels/vehicle-masters",

  //stocks
  stockPointEdit: "/hotels/stock-points/:id/edit",
  stockPoints: "/hotels/stock-points",
  stockPointCreate: "/hotels/stock-points/create",

  //customer
  editHotelCustomer: "/hotels/customers/:id/edit",
  addNewCustomerPage: "/hotels/customers/create",
  addNewPostpayCustomer: "/hotels/customers/create-postpay-customer",
  addNewPrepayCustomer: "/hotels/customers/create-prepay-customer",
  customersList: "/hotels/customers/",
  customerRateCard: "/hotels/customers/:id/rate-card",

  // log history
  customersLogHistoryPage: "/hotels/customers/logs",

  // indents
  listIndent: "/hotels/indents",
};

const HotelRoute = (props) => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={HOTEL_ROUTE.dashboard}
          component={HotelDashboardPage}
        ></Route>
        <Route
          exact
          path={HOTEL_ROUTE.paymentHistory}
          component={PaymentHistoryPage}
        />

        {/* Master rate card */}
        <Route
          exact
          path={HOTEL_ROUTE.masterRateCard}
          component={MasterRateCardListContainer}
        />
        <Route
          exact
          path={HOTEL_ROUTE.masterRateCardCreate}
          component={MasterRateCardFormContainer}
        />
        <Route
          exact
          path={HOTEL_ROUTE.masterRateCardEdit}
          component={MasterRateCardFormContainer}
        />
        {/* Vehicle master */}
        <Route
          exact
          path={HOTEL_ROUTE.editVehicles}
          component={VehicleMasterCreatePage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.vehicleMaster}
          component={VehicleMasterPage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.vehicleMasterCreate}
          component={VehicleMasterCreatePage}
        />
        {/* Stock card */}
        <Route
          exact
          path={HOTEL_ROUTE.stockPointEdit}
          component={StockpointCreateContainer}
        />
        <Route
          exact
          path={HOTEL_ROUTE.stockPoints}
          component={StockpointPage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.stockPointCreate}
          component={StockpointCreateContainer}
        />

        {/* Customer */}
        <Route
          exact
          path={HOTEL_ROUTE.editHotelCustomer}
          component={EditHotelCustomer}
        />

        <Route
          exact
          path={HOTEL_ROUTE.addNewCustomerPage}
          component={AddNewCustomerPage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.addNewPostpayCustomer}
          component={AddNewPostpayCustomerPage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.addNewPrepayCustomer}
          component={AddNewPrepayCustomerPage}
        />
        <Route
          exact
          path={HOTEL_ROUTE.customersList}
          component={ListCustomers}
        />
        <Route
          exact
          path={HOTEL_ROUTE.customerRateCard}
          component={CustomerRateCardListContainer}
        />
        <Route
          exact
          path={HOTEL_ROUTE.customersLogHistoryPage}
          component={LogHistoryPage}
        />

        {/* Indent */}
        <Route exact path={HOTEL_ROUTE.listIndent} component={IndentListing} />

        {/* Indent */}
        <Route
          exact
          path={HOTEL_ROUTE.indentOverdue}
          component={IndentOverdueList}
        />

        <Route
          exact
          path={HOTEL_ROUTE.dashboardInvoiceOverDueList}
          component={InvoiceOverDuePage}
        />\
        
        <Route component={RedirectAs404} />
      </Switch>
    </Suspense>
  );
};

export default withProvider(IndentProvider, HotelRoute);