import React from "react";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";

const EditableCell = (props) => {
  const {
    name,
    type,
    defaultValue,
    row,
    handleSave,
    width = "100px",
    showEditButton,
  } = props;
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <EditText
        name={name}
        type={type}
        style={{ width: width }}
        defaultValue={defaultValue}
        onSave={(e) => handleSave(e, row)}
        editButtonProps={{ style: { marginLeft: "5px" } }}
        showEditButton={showEditButton}
        inline
      />
    </div>
  );
};

export default EditableCell;
