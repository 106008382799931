import React from "react";

export const withProvider = (Provider, Container) => {
  return (props) => (
    <Provider>
      <Container {...props} />
    </Provider>
  );
};

export const withMultiProviders = (providers = [], Container) => {
  const childProvider = providers[providers.length - 1];
  let loopedProvider = withProvider(childProvider, Container);

  for (let i = providers.length - 2; i >= 0; i--) {
    loopedProvider = withProvider(providers[i], loopedProvider);
  }

  return loopedProvider;
};
