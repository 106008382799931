import { EGG_TRACKING_ROUTE } from "./routes/EggTrackingRoute";

const EGG_TRACKING_MENU = [
  {
    icon: "dashboard",
    text: "Dashboard",
    active: false,
    link: EGG_TRACKING_ROUTE.dashboard,
  },
];

export default EGG_TRACKING_MENU;
