import React, { useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { OverlineTitle } from "../../../../components/Component";
import { Link } from "react-router-dom";
import { useFieldArray } from "react-hook-form";
import InputField from "../../../../components/utils/InputComponent";
import SelectField from "../../../../components/utils/SelectComponent";

const PaymentReminder = (props) => {
  const {
    control,
    field,
    errors,
    branchIndex,
    register,
    paymentReminders,
    paymentReminderData,
    setValue,
  } = props;

  const { fields, remove, append } = useFieldArray({
    control,
    name: paymentReminders,
    defaultValues: {
      days: "",
      sequence: "",
    },
  });

  useEffect(() => {
    if (paymentReminderData) {
      paymentReminderData.forEach((element, index) => {
        setValue(
          `branchDetails[${branchIndex}].paymentReminders[${index}].id`,
          element?.id
        );
        setValue(
          `branchDetails[${branchIndex}].paymentReminders[${index}].days`,
          element.days
        );
        setValue(
          `branchDetails[${branchIndex}].paymentReminders[${index}].sequence`,
          element.sequence
        );
        fields.push({ ...element });
      });
    }
  }, [paymentReminderData]);

  return (
    <React.Fragment>
      <div className="my-4" />
      <OverlineTitle tag="span" className="preview-title-lg">
        Payment Reminder [Rules]
        <Button
          color="info"
          onClick={() =>
            append({
              days: "",
              sequence: "",
            })
          }
        >
          {" "}
          +Add
        </Button>
      </OverlineTitle>
      {fields.map((item, index) => {
        return (
          <React.Fragment>
            <div className="my-4" />
            <Row>
              <Col>
                <div className="d-flex">
                  <InputField
                    labelText="Remind"
                    inputName={`branchDetails[${branchIndex}].paymentReminders[${index}].days`}
                    inputType="text"
                    register={register}
                    errors={errors}
                  />
                  <SelectField
                    labelText="Days"
                    inputName={`branchDetails[${branchIndex}].paymentReminders[${index}].sequence`}
                    inputType="select"
                    register={register}
                    errors={errors}
                    options={[
                      { name: "After", value: 2 },
                      { name: "Before", value: -1 },
                    ]}
                  />
                  <div className="form-control-wrap mb-3">
                    <span
                      className="input-group-text bg-white"
                      id="inputGroup-sizing-lg"
                    >
                      Due date
                    </span>
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row></Row>
            {index > 0 ? (
              <Button color="danger" onClick={() => remove(index)}>
                {" "}
                Delete Reminder
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default PaymentReminder;
