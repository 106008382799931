import { mkConfig, generateCsv, download } from "export-to-csv";

import { IndentContext } from "../../providers/IndentProvider";
import React from "react";
import { Button } from "../../../../components/Component";


export function DownloadIndentsButton() {
    const indentContext = React.useContext(IndentContext);
    const getIndentsToDownload = indentContext.getIndentsToDownload;

    const handleDownload = async () => {
        const todayDate = new Date().toISOString().split("T")[0];
        const filename = `indents-${todayDate}`;
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });
        const indents = await getIndentsToDownload();

        let data = [];

        for (let i = 0; i < indents.length; i++) {
            const indent = indents[i];
            indent.orderLineItems.forEach((item) => {
                data.push({
                    "Indent ID": indent.id,
                    "Item": item.productVariantOrdered.name,
                    "Quantity": item.currentQuantity,
                    "Amount": item.rate,
                    "Branch name": indent.orderedBranch.name,
                    "Status": indent.status,
                    "Created At": indent.created_at,
                });
            });
        }
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv, "indents.csv");
        console.log("indents", indents);
    };

    return (
        <Button color="primary" onClick={handleDownload}>
            Download Today
        </Button>
    );
}
