import { Button } from "reactstrap";
import React from "react";

function TopButtons(props) {
  const { data, activeId, onItemClick } = props;

  if (!data) return null;
  return data.map((datum) => (
    <Button
      outline
      color={activeId === datum.id ? "danger" : "light"}
      size="rg"
      key={"top-button-" + datum.name + datum.id}
      height="24px"
      fontWeight="normal"
      className="mr-gs mb-gs btn-dim"
      padding="0 10px 0 10px"
      onClick={() => {
        onItemClick(datum.id);
      }}
      margin="0 0 10px 10px"
      _hover={{ className: "btn-dim", color: " danger" }}
      _active={{ className: "btn-dim", color: "danger" }}
    >
      {datum.name}
    </Button>
  ));
}
export default TopButtons;
