/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";

import AuthRoute from "./apps/auth/routes/AuthRoute";
import LandingPage from "./apps/LandingPage";
import Error404Classic from "./apps/auth/pages/errors/404-classic";
import Error504Modern from "./apps/auth/pages/errors/504-modern";
import Error504Classic from "./apps/auth/pages/errors/504-classic";
import Error404Modern from "./apps/auth/pages/errors/404-modern";
import { CoreContext } from "./providers/CoreProvider";
import { APP_MODULE_PREFIX } from "./config/Permission";
import { Spinner } from "reactstrap";
import CoreApp from "./apps/core/CoreApp";
import ErrorBoundary from "./components/ErrorBoundary";

const App = (props) => {
  const coreContext = useContext(CoreContext);

  useEffect(() => {
    coreContext.onUserLookup();
  }, []);

  function renderAccessibleRoutes() {
    return coreContext.accessibleModules.map((am) => {
      const routeItem = APP_MODULE_PREFIX[am];
      if (!routeItem) return null;

      return (
        <Route
          key={`route-prefix-${routeItem.urlPrefix}`}
          path={routeItem.urlPrefix}
          component={routeItem.component}
        />
      );
    });
  }

  function renderRoutes() {
    if (!coreContext.isAuthDetermined) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner className="m-2" size={"xl"} />
        </div>
      );
    }

    return (
      <ErrorBoundary>
        <Switch>
          {renderAccessibleRoutes()}
          <Route path={"/core"} component={CoreApp} />
          {/* <Route path={'/egg-trackings'} component={EggTrackingApp} /> */}

          {/*Error Pages*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/404-classic`}
            component={Error404Classic}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/504-modern`}
            component={Error504Modern}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/404-modern`}
            component={Error404Modern}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/errors/504-classic`}
            component={Error504Classic}
          ></Route>

          {/*Main Routes*/}
          {/* <PrivateRoute exact path="" component={Layout}></PrivateRoute> */}
          {/* Auth Pages */}
          <Route path={"/auth"} component={AuthRoute} />
          <Route exact path={"/"} component={LandingPage} />
          <Route component={RedirectAs404} />
        </Switch>
      </ErrorBoundary>
    );
  }

  return renderRoutes();
};
export default withRouter(App);
