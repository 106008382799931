import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import ButtonGroup from "../../../../components/utils/TopButtons";
import CustomTable from "../../../../components/table/CustomTable";
import { withProvider } from "../../../../hoc/withProvider";
import HotelCustomerProvider, {
  HotelCustomerContext,
} from "../../providers/HotelCustomerProvider";
import { Spinner } from "reactstrap";

const categories = [
  {
    id: "POSTPAY",
    name: "Postpay",
  },
  {
    id: "PREPAY",
    name: "Prepay",
  },
];

const ListCustomers = (props) => {
  const [categorySelection, setCategorySelection] = useState("all");
  const [queryParams, setQueryParams] = useState({});
  const defaultPage = 1;

  const renderInvoiceStatus = (row) => {
    const count = invoicePendingCount(row);
    const textToRender =
      count === 0 ? (
        <span className="text-info">ALL PAID</span>
      ) : (
        <span className="text-danger">PENDING</span>
      );
    return textToRender;
  };

  const renderAction = (row) => {
    return (
      <div style={{ display: "block", width: "100%" }}>
        <span>
          <a href={`/hotels/customers/${row.id}/edit`}>
            <Button color="warning" className="mb-1"> Edit Customer </Button>
          </a>
        </span>
        <span>
          <a href={`/hotels/customers/${row.id}/rate-card`}>
            <Button color="info"> Add Rate Card </Button>
          </a>
        </span>
      </div>
    );
  };

  const hotelCustomerContext = React.useContext(HotelCustomerContext);
  const customers = hotelCustomerContext.customersData.customers || [];
  const isLoadingCustomers = hotelCustomerContext.isLoadingCustomers;
  const invoices = hotelCustomerContext.customersData.invoices || [];
  const totalCount = hotelCustomerContext.customersData.totalCount || 0;

  const page = 1,
    size = 10;
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 0);
  const [currentPageSize, setCurrentPageSize] = useState(Number(size) || 10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");

  React.useEffect(() => {
    hotelCustomerContext.getHotelCustomers({
        ...queryParams,
      page: currentPage,
      size: currentPageSize,
      sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const customSort = (rows, selector, direction) => {

    if (direction === 'desc') {
        setSortBy('DESC')
    } else {
        setSortBy('ASC')
    }

    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const aField = selector(rowA)
     const bField = selector(rowB)
   
     let comparison = 0;
   
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
   
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  const dataTableColumns = [
    {
      name: "SL.No",
      selector: (row) => row.id,
      cell: (row, index) => <span>{(currentPage - 1) * currentPageSize + index + 1}</span>,
      // allowOverflow: true,
      width: "50px",
    },
    // {
    //   name: "C Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width: "50px",
    // },
    {
      name: "Hotel Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Contact",
      selector: (row) =>
        row?.hotelBranches[0]?.hotelBranchAccount[0]?.pocPhoneNumber,
      width: "130px",
      wrap: true
    },
    {
      name: "Category",
      selector: (row) => row.tier,
      width:"80px"
    },
    {
    //   name: "Billing Method",
    selector: (row) => row.category,
      name: (
        <div style={{ width: "100px" }} >
          Billing <br /> Method
        </div>
      ),
    },
      
    {
    //   name: "Invoice Terms",
      name: (
        <div style={{ width: "100px" }}>
          Invoice <br /> Terms
        </div>
      ),
      selector: (row) => row.creditPeriod,
      cell: (row) => invoiceTerms(row),
      width:"80px"
    },
    {
    //   name: "Invoice Pending",
      name: (
        <div style={{ width: "100px" }}>
          Invoice <br /> Pending
        </div>
      ),
      selector: (row) => row.invoicePending,
      cell: (row) => invoicePendingCount(row),
      width:"100px"
    },
    {
    //   name: "Invoice Status",
      name: (
        <div style={{ width: "100px" }}>
          Invoice <br /> Status
        </div>
      ),
      selector: (row) => row.invoiceStatus,
      cell: (row) => renderInvoiceStatus(row),
      width:"100px"
    },
    {
      name: "Action",
      selector: (row) => row,
      cell: (row) => renderAction(row),
      width: "170px",
    },
  ];

  const invoiceTerms = (data) => {
    if(data.hotelBranches.length && data.hotelBranches[0].hotelBranchAccount.length){
      return data.hotelBranches[0]?.hotelBranchAccount[0]?.creditPeriod ? `${data.hotelBranches[0]?.hotelBranchAccount[0]?.creditPeriod} Days` : "NA"
    }
    else return "NA"
  }

  const invoicePendingCount = (data) => {
    const invoiceData = invoices.find(
      (invoice) => invoice?.hotelId === data.id
    );
    return invoiceData ? (
      <span className="text-danger">{invoiceData.count}</span>
    ) : (
      0
    );
  };

  const handleCategorySelection = (category_id) => {
    setCategorySelection(category_id);
    const newParams = {
      ...queryParams,
      category: category_id === "all" ? "" : category_id,
      page: defaultPage,
      size: currentPageSize,
      sortBy
    };
    setQueryParams(newParams);
    hotelCustomerContext.getHotelCustomers(newParams);
    setCurrentPage(1);
  };

  function handlePageChange(page) {
    setCurrentPage(page);
    hotelCustomerContext.getHotelCustomers({
      ...queryParams,
      page: page,
      size: currentPageSize,
      sortBy
    });
  }

  function handleShowSizeChange(showSize) {
    setCurrentPageSize(showSize);
    hotelCustomerContext.getHotelCustomers({
      ...queryParams,
      page: currentPage,
      size: showSize,
      sortBy
    });
  }

  function handleSort(sort) {}

  function handleSearchChange(searchText) {
    setSearchTerm(searchText)
    hotelCustomerContext.getHotelCustomers({
      ...queryParams,
      search: searchText,
      sortBy
    });
    setQueryParams({...queryParams, search: searchText});
  }

  return (
    <React.Fragment>
      <Head title="Customer List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Customer Details
              </BlockTitle>
              {customers && (
                <BlockDes className="text-soft">
                  <p>You have totally {totalCount} customers.</p>
                </BlockDes>
              )}
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <NavLink tag={"Button"} to={HOTEL_ROUTE.addNewCustomerPage}>
                  <Button color="success">
                    <span>Create A Customer</span>
                    <Icon className="pl-0" name="plus-sm" />
                  </Button>
                </NavLink>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="d-flex flex-wrap">
          <ButtonGroup
            data={[{ name: "All", id: "all" }].concat(
              categories.map((category) => ({
                name: category.name,
                id: category.id,
              }))
            )}
            activeId={categorySelection}
            onItemClick={handleCategorySelection}
          />
        </div>

        {!isLoadingCustomers ? (
            <CustomTable
              data={customers}
              columns={dataTableColumns}
              expandableRows
              pagination
              selectableRows={false}
              onSearchChange={handleSearchChange}
              onSort={handleSort}
              onPageChange={handlePageChange}
              onShowSizeChange={handleShowSizeChange}
              pageShowSize = {currentPageSize}
              currentPageNum={currentPage}
              totalItems={totalCount}
              isSortEnabled={true}
              searchTerm={searchTerm}
              customSort={customSort}
            />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          
            <Spinner className="m-2" size={"xl"} />
          </div>
          
        )}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(HotelCustomerProvider, ListCustomers);
