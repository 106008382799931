import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";

const isAudioLocked = () => {
    try {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        return context.state === 'suspended';
      } catch (e) {
        console.log("failed to check audio lock status", e)
        return true
      }
  };


  export function EnsureAutoplayPossible() {
    const [showModal, setShowModal]= useState(false);

    useEffect(() => {
        const autoplayDisabled = isAudioLocked();
        console.log("autoplayDisabled", autoplayDisabled)
        if (autoplayDisabled) {
            setShowModal(true);
        }
    }, []);

    const toggle = () => setShowModal(!showModal);

    return (
        <Modal isOpen={showModal} toggle={toggle} onClick={toggle}>
            <ModalHeader toggle={toggle} close={<></>}>Allow Sound</ModalHeader>
            <ModalBody>
                Please click anywhere to allow sound for getting notified of new indents.
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Allow Sound</Button>
            </ModalFooter>
        </Modal>
    );
}
