import React, { useContext, useState } from "react";
import { Form, FormGroup, Alert, Collapse } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Head from "../../../../layout/head/Head";
import PageContainer from "../../../../layout/page-container/PageContainer";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../../../components/Component";
import Logo from "../../../../layout/logo/Logo";
import AuthFooter from "../../components/AuthFooter";

import * as yup from "yup";
import useYupValidationResolver from "../../../../hooks/useYupResolver";
import useBoolean from "../../../../hooks/useBoolean";
import authRepository from "../../../../repositories/AuthRepository";
import { CoreContext } from "../../../../providers/CoreProvider";

const loginSchema = yup
  .object({
    phoneNumber: yup.string().required("Phone number is required"),
    countryCode: yup.string().required("Country code is required"),
  })
  .required();

const verifyOtpSchema = yup
  .object({
    phoneNumber: yup.string().required("Phone number is required"),
    countryCode: yup.string().required("Country code is required"),
    otp: yup.string().required("OTP is required"),
  })
  .required();

const AuthLogin = () => {
  const coreContext = useContext(CoreContext);

  const loginResolver = useYupValidationResolver(loginSchema);
  const otpResolver = useYupValidationResolver(verifyOtpSchema);

  const loginForm = useForm({
    resolver: loginResolver,
    defaultValues: {
      countryCode: "+91",
    },
  });

  const otpForm = useForm({
    resolver: otpResolver,
    defaultValues: {
      countryCode: "+91",
      phoneNumber: loginForm.getValues()["phoneNumber"],
    },
  });

  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const [openOtp, ooState] = useBoolean(false);

  const activeForm = openOtp ? otpForm : loginForm;

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = activeForm;

  const onOtpFormSubmit = async (formData) => {
    try {
      setError(null);
      setLoading(true);

      const payload = {
        ...formData,
        deviceParams: {
          deviceId: "1234",
          platform: "web",
          ip: "127.0.0.1",
        },
      };

      const response = await authRepository.verifyLoginOtp(payload);
      coreContext.onAuthSuccess(response);
      setLoading(false);
    } catch (e) {
      setError(e?.message || "Cannot authorize credentials");
      setLoading(false);
    }
  };

  const onFormSubmit = async (formData) => {
    try {
      setError(null);
      setLoading(true);
      await authRepository.requestLogin(formData);
      setLoading(false);
      ooState.on();
    } catch (e) {
      setError(e?.message || "Cannot login with credentials");
      setLoading(false);
    }

    // if (formData.email === loginName && formData.passCode === pass) {
    //   localStorage.setItem("accessToken", "token");
    //   setTimeout(() => {
    //     window.history.pushState(
    //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
    //       AUTH_ROUTES.login,
    //       CORE_APP_ROUTES.accessibleApps,
    //     );
    //     window.location.reload();
    //   }, 2000);
    // } else {
    //   setTimeout(() => {
    // setError("Cannot login with credentials");
    // setLoading(false);
    //   }, 2000);
    // }
  };

  const handleSubmitButton = handleSubmit(
    openOtp ? onOtpFormSubmit : onFormSubmit
  );

  function handlePhoneNumberChange() {
    if (openOtp) {
      ooState.off();
    }
  }

  return (
    <React.Fragment>
      <Head title="Login | Sneha One" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <Logo />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>Access Sneha One using your phone number.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" />
                  {errorVal}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmitButton}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Phone number
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    name="phoneNumber"
                    // onChange={handlePhoneNumberChange}
                    placeholder="Enter your phone number"
                    className="form-control-lg form-control"
                    {...register("phoneNumber", {
                      onChange: handlePhoneNumberChange,
                    })}
                  />
                  {errors.phoneNumber && (
                    <span className="invalid">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </div>
              </FormGroup>

              <Collapse
                className="accordion-body"
                isOpen={openOtp}
                // isOpen={isOpen === "1" ? true : false}
              >
                <div className="accordion-inner p-0 mb-4">
                  <FormGroup>
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        One Time Password
                      </label>
                      <Button outline className={"btn-sm"}>
                        Resend OTP
                      </Button>
                      {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                        Resend OTP
                      </Link> */}
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>

                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        name="otp"
                        placeholder="Enter your OTP"
                        className={`form-control-lg form-control ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                        {...register("otp")}
                      />
                      {errors.otp && (
                        <span className="invalid">{errors.otp.message}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </Collapse>

              <FormGroup>
                <Button
                  isLoading={loading}
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                >
                  Sign in
                </Button>
              </FormGroup>
            </Form>
            {/* <div className="form-note-s2 text-center pt-4">
              {" "}
              New on our platform?{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-register`}>
                Create an account
              </Link>
            </div>
            <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-4">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Google
                </a>
              </li>
            </ul> */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default AuthLogin;
