import React from "react";
import Layout from "../../../layout/Index";
import CoreAppRoute from "../routes/CoreAppRoute";
import CORE_APP_MENU from "../MenuData.js";

const CoreAppLayout = (props) => {
  const renderRoutes = <CoreAppRoute />;

  return <Layout menuData={CORE_APP_MENU} routes={renderRoutes} {...props} />;
};

export default CoreAppLayout;
