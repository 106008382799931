export const BASE_URL =
    process.env.REACT_APP_BASE_URL ||
    "https://internal-api.snehafresh.com";

export const AUTH_LOGIN_API = `${BASE_URL}/api/v1/auth/sessions/otp/request`;
export const AUTH_LOGIN_VERIFY_API = `${BASE_URL}/api/v1/auth/sessions/otp/verify`;

export const USER_LOOKUP = `${BASE_URL}/api/v1/auth/users/lookup`;

//MASTER RATE CARD MANAGEMENT
export const MASTER_RATE_CARD_LIST_API = `${BASE_URL}/api/v1/hotels/master-rate-cards`;
export const MASTER_RATE_CARD_CREATE_API = `${BASE_URL}/api/v1/hotels/master-rate-card/create`;
export const MASTER_RATE_CARD_EDIT_API = `${BASE_URL}/api/v1/hotels/master-rate-card/:id/edit`;
export const GET_CATEGORIES = `${BASE_URL}/api/v1/hotels/products/categories`;
export const GET_CATEGORIES_OPTIONS = `${BASE_URL}/api/v1/hotels/master-rate-card/options/categories`;
export const GET_SUB_CATEGORIES_OPTIONS = `${BASE_URL}/api/v1/hotels/master-rate-card/options/categories/:id/sub-categories`;

//HOTEL MANAGEMENT
export const CUSTOMER_RATE_CARD = `${BASE_URL}/api/v1/hotels/:id/rate-card`;
// USER MANAGEMENT
export const USER_MANAGE_GET_API = `${BASE_URL}/api/v1/core/users`;
export const USER_MANAGE_UPDATE_API = `${BASE_URL}/api/v1/core/users/:userId/edit`;

//INDENT
export const INDENT_OVERDUE_LIST_API = `${BASE_URL}/api/v1/hotelManagement/indentOverdues`;
export const INDENT_LIST_API = `${BASE_URL}/api/v1/hotels/branches/indents`;
export const INDENT_LIST_API_TODAY = `${BASE_URL}/api/v1/hotels/branches/indents/today`;
export const INDENT_CHANGE_STATUS_API = `${BASE_URL}/api/v1/hotels/branches/indents/change-status`;
//VEHICLE MANAGEMENT
export const VEHICLES_API = `${BASE_URL}/api/v1/vehicles`;
export const VEHICLE_EDIT_API = `${BASE_URL}/api/v1/vehicles/:id/edit`;

// STOCKPOINT MANAGEMENT
export const STOCKPOINT_LIST_API = `${BASE_URL}/api/v1/hotels/stock-points`;
export const STOCKPOINT_CREATE_API = `${BASE_URL}/api/v1/hotels/stock-point/create`;
export const STOCKPOINT_OPTIONS = `${BASE_URL}/api/v1/stockpoint-options`;
export const STOCKPOINT_EDIT = `${BASE_URL}/api/v1/stockpoints/:id/edit`;
// DASHBOARD
export const DASHBOARD_DETAILS_API = `${BASE_URL}/api/v1/hotels/dashboard-cms`;
export const DASHBOARD_OVER_DUE_LIST = `${BASE_URL}/api/v1/hotels/dashboard/over-due-list`;

// HOTEL USERS
export const HOTEL_CUSTOMER_API = `${BASE_URL}/api/v1/hotels/customers`;

// USERS
export const GET_HOTEL_USERS_API = `${BASE_URL}/api/v1/users`;
export const GET_HOTEL_USER_API = `${BASE_URL}/api/v1/users/:id`;

//LOG HISTORY
export const LOG_HISTORIES_API = `${BASE_URL}/api/v1/log-histories`;

export const HOTEL_CUSTOMER_EDIT_API = `${BASE_URL}/api/v1/hotels/customers/:id/edit`;

//ROLES
export const GET_ROLES_API = `${BASE_URL}/api/v1/roles`;

// Accept Indents
export const ACCEPT_ALL_INDENTS = `${BASE_URL}/api/v1/hotels/orders/accept-indents`;