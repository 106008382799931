import React from "react";
import Layout from "../../../layout/Index";
import EGG_TRACKING_MENU from "../MenuData";
import EggTrackingRoute from "../routes/EggTrackingRoute";

const EggTrackingLayout = (props) => {
  const renderRoutes = <EggTrackingRoute />;

  return (
    <Layout menuData={EGG_TRACKING_MENU} routes={renderRoutes} {...props} />
  );
};

export default EggTrackingLayout;
