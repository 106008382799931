import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import masterRateCardRepository from "../repositories/MasterRateCardRepository";
import { emptyHandler } from "../../../utils/Utils";

export const MasterRateCardContext = React.createContext({
  isLoadingMasterRate: false,
  isLoadingCategory: false,
  masterRateCardsList: { masterRateCards: [], total: 0 },
  categoryList: [],
  getMasterRateCards: async () => emptyHandler,
  createMasterRatecard: async () => emptyHandler,
  getCategoryOptions: async () => emptyHandler,
  categoryOptions: [],
  getSubCategoryOptions: async () => emptyHandler,
  subCategoryOptions: {},
  getCategories: async () => emptyHandler,
  updateMasterRateCard: async () => emptyHandler,
  updateProduct: async () => emptyHandler,
  getProduct: async () => emptyHandler,
  productData: {},
});

const DEFAULT_PAGE_SIZE = 10;

const MasterRateCardProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingMasterRates, ilmState] = useBoolean(false);
  const [masterRateCardsList, setMasterRateCardsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [isLoadingCategory, iscState] = useBoolean(false);
  const [productData, setProductData] = useState({});

  // TODO: PAGINATION
  async function getMasterRateCards(queryParams) {
    try {
      ilmState.on();
      if (!queryParams) {
        queryParams = {};
      }
      if (!queryParams.size) {
        queryParams.size = DEFAULT_PAGE_SIZE;
      }
      if (!queryParams.page) {
        queryParams.page = 1;
      }
      const response = await masterRateCardRepository.getMasterRateCardsList(
        queryParams
      );

      setMasterRateCardsList({
        masterRateCardsList: response.items,
        total: response.pagination.totalCount,
      });
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilmState.off();
    }
  }

  const createMasterRatecard = async (createParams) => {
    try {
      ilmState.on();
      const res = await masterRateCardRepository.createMasterRateCard(
        createParams
      );
      return res;
    } catch (e) {
      throw e;
    } finally {
      ilmState.off();
    }
  };

  async function getCategories() {
    try {
      iscState.on();
      const response = await masterRateCardRepository.getCategories();
      setCategoryList(response.categories);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  async function getCategoryOptions() {
    try {
      iscState.on();
      const response = await masterRateCardRepository.getCategoryOptions();
      setCategoryOptions(response.categories);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  async function getSubCategoryOptions(categoryId) {
    try {
      iscState.on();
      const response = await masterRateCardRepository.getSubCategoryOptions(
        categoryId
      );
      setSubCategoryOptions(response.subCategories);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  async function updateMasterRateCard(params) {
    try {
      iscState.on();
      const response = await masterRateCardRepository.updateMasterRateCard(
        params
      );
      return response.products;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  async function updateProduct(params, data) {
    try {
      iscState.on();
      const response = await masterRateCardRepository.updateProduct(
        params,
        data
      );
      return response.product;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  async function getProduct(params) {
    try {
      iscState.on();
      const response = await masterRateCardRepository.getProduct(params);
      setProductData(response.product);
      return response.product;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      iscState.off();
    }
  }

  const mContext = {
    isLoadingMasterRates,
    isLoadingCategory,

    masterRateCardsList,
    categoryList,
    categoryOptions,
    subCategoryOptions,
    productData,

    getMasterRateCards,
    createMasterRatecard,
    getCategoryOptions,
    getSubCategoryOptions,
    getCategories,
    updateMasterRateCard,
    updateProduct,
    getProduct,
  };

  return (
    <MasterRateCardContext.Provider value={mContext}>
      {props.children}
    </MasterRateCardContext.Provider>
  );
};

export default MasterRateCardProvider;
