import {
  CUSTOMER_RATE_CARD,
  HOTEL_CUSTOMER_API,
  HOTEL_CUSTOMER_EDIT_API,
  GET_ROLES_API,
} from "../../../constants/Endpoints";
import API from "../../../service/ApiService";

class HotelCustomerRepository {
  async getHotelCustomers(queryParams) {
    const response = await API.get(HOTEL_CUSTOMER_API, {
      queryParams: queryParams,
    });
    return response.data;
  }

  async createHotelCustomer(data) {
    const response = await API.post(HOTEL_CUSTOMER_API, { body: data });
    return response.data;
  }

  async getCustomerData(params) {
    const response = await API.get(HOTEL_CUSTOMER_EDIT_API, {
      pathParams: { id: params },
    });
    return response.data;
  }

  async getCustomerRateCard(params, queryParams) {
    const response = await API.get(CUSTOMER_RATE_CARD, {
      pathParams: { id: params },
      queryParams: queryParams,
    });
    return response.data;
  }

  async createCustomerRateCard(params, data) {
    const response = await API.post(CUSTOMER_RATE_CARD, {
      pathParams: { id: params },
      body: data,
    });
    return response;
  }

  async updateHotelCustomer(params, data) {
    const response = await API.put(HOTEL_CUSTOMER_EDIT_API, {
      pathParams: { id: params },
      body: data,
    });
    return response.data;
  }

  async getRoles(){
    const response = await API.get(GET_ROLES_API);
    return response.data
  }
}

const hotelCustomerRepository = new HotelCustomerRepository();

export default hotelCustomerRepository;
