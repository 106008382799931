/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Button, Col, Row, Form } from "reactstrap";
import {
  BlockHeadContent,
  BlockTitle,
  OverlineTitle,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import * as yup from "yup";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import useYupValidationResolver from "../../../../hooks/useYupResolver";
import { useForm, Controller } from "react-hook-form";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import ReactDatePicker from "react-datepicker";
import VehicleMasterProvider, {
  VehicleMasterContext,
} from "../../providers/VehicleMasterProvider";
import { errorToast } from "../../../../components/toastify/Toastify";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { withProvider } from "../../../../hoc/withProvider";
import SelectField from "../../../../components/utils/SelectComponent";
import InputField from "../../../../components/utils/InputComponent";

const CREATE_TITLE_HEAD = "Vehicle Master |  Hotel";
const EDIT_TITLE_HEAD = "Edit Vehicle Master |  Hotel";

const createValidationSchema = yup
  .object({
    name: yup.string().trim().required("VehicleName Name is required"),
    vehicleType: yup.string().trim().optional("Vehicle type is required"),
    vehicleNumber: yup.string().trim().required("Vehicle number is required"),
    chassisNumber: yup.string().trim().required("Chassis number is required"),
    stockpointId: yup.string().required("StockPoint required"),
    insuranceExpiryDate: yup
      .date()
      .required("Insurance expiry date is required"),
    roadTaxExpiryDate: yup.date().required("Road tax expiry date is required"),
    lastServiceDate: yup.date().required("Last service date is required"),
    pucExpiryDate: yup.date().required("PUC Expiry is required"),
    gpsInstalled: yup.boolean(),
    weightLimit: yup.number().required("Weight limit is required"),
    weightBuffer: yup.number().required("Weight buffer is required"),
  })
  .required();

const VehicleMasterCreatePage = (props) => {
  const { id: vehicleId } = useParams();
  const history = useHistory();
  const isEditMode = props?.match?.params?.id || false;
  const {
    createVehicle,
    stockpointOptions,
    getStockpointOptions,
    updateVehicle,
    getVehicle,
    vehicle,
  } = useContext(VehicleMasterContext);

  const resolver = useYupValidationResolver(createValidationSchema);
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver,
  });

  const headTitle = isEditMode ? EDIT_TITLE_HEAD : CREATE_TITLE_HEAD;

  async function handleSaveItem(data) {
    if (vehicleId) {
      await updateVehicle(vehicleId, data)
        .then((res) => {
          if (res) {
            history.push(HOTEL_ROUTE.vehicleMaster);
            handleSuccessConfirmation();
          }
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      await createVehicle(data)
        .then((res) => {
          if (res) {
            history.push(HOTEL_ROUTE.vehicleMaster);
            handleSuccessConfirmation();
          }
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  }

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "Vehicle(s) added Succefully",
    });
  };

  React.useEffect(() => {
    getStockpointOptions();
    if (vehicleId) {
      getVehicle(vehicleId);
    }
  }, [vehicleId]);

  useEffect(() => {
    if (vehicleId && vehicle.id) {
      reset({
        ...vehicle,
        gpsInstalled: vehicle.gpsInstalled?.toString(),
        fcDate: vehicle.fcDate ? new Date(vehicle.fcDate) : "",
        insuranceExpiryDate: vehicle.insuranceExpiryDate
          ? new Date(vehicle.insuranceExpiryDate)
          : "",
        roadTaxExpiryDate: vehicle.roadTaxExpiryDate
          ? new Date(vehicle.roadTaxExpiryDate)
          : "",
        lastServiceDate: vehicle.lastServiceDate
          ? new Date(vehicle.lastServiceDate)
          : "",
        pucExpiryDate: vehicle.pucExpiryDate
          ? new Date(vehicle.pucExpiryDate)
          : "",
      });
    }
  }, [vehicle]);

  return (
    <React.Fragment>
      <Head title={headTitle} />
      <Content>
        <BlockHeadContent>
          <BlockTitle page>Vehicle master form</BlockTitle>
        </BlockHeadContent>
        <div className="mt-4 p-4 bg-white rounded-sm">
          <Form
            className={"form-validate is-alter"}
            onSubmit={handleSubmit(handleSaveItem)}
          >
            <OverlineTitle tag="span" className="preview-title-lg">
              Vehicle{" "}
            </OverlineTitle>

            <Row md="12" lg="12">
              <Col md="6" lg="6">
                <InputField
                  labelText="Vehicle Name"
                  inputName={`name`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Vehicle Type"
                  inputName={`vehicleType`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        Vehicle No
                      </span>
                    </div>

                    <input
                      name="vehicleNumber"
                      type="text"
                      className="form-control"
                      {...register("vehicleNumber")}
                    />
                  </div>
                  {errors.vehicleNumber && (
                    <span className="invalid">
                      {errors.vehicleNumber?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Chassis Number"
                  inputName={`chassisNumber`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <SelectField
                  labelText="Stockpoint"
                  inputType="stockpointId"
                  inputName={`stockpointId`}
                  register={register}
                  errors={errors}
                  options={stockpointOptions.map((stockpoint) => ({
                    value: stockpoint.id,
                    name: `${stockpoint.name} (${stockpoint.city})`,
                  }))}
                />
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        Insurance Expiry Date
                      </span>
                    </div>
                    <div className="form-control-wrap" style={{ width: "60%" }}>
                      <Controller
                        control={control}
                        name="insuranceExpiryDate"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value}
                          />
                        )}
                        {...register("insuranceExpiryDate")}
                      />
                    </div>
                  </div>
                  {errors.insuranceExpiryDate && (
                    <span className="invalid">
                      {errors.insuranceExpiryDate?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        Road Tax Expiry Date
                      </span>
                    </div>
                    <div className="form-control-wrap" style={{ width: "60%" }}>
                      <Controller
                        control={control}
                        name="roadTaxExpiryDate"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value}
                          />
                        )}
                        {...register("roadTaxExpiryDate")}
                      />
                    </div>
                  </div>
                  {errors.roadTaxExpiryDate && (
                    <span className="invalid">
                      {errors.roadTaxExpiryDate?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        Last Service Date
                      </span>
                    </div>
                    <div className="form-control-wrap" style={{ width: "60%" }}>
                      <Controller
                        control={control}
                        name="lastServiceDate"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value}
                          />
                        )}
                        {...register("lastServiceDate")}
                      />
                    </div>
                  </div>
                  {errors.lastServiceDate && (
                    <span className="invalid">
                      {errors.lastServiceDate?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        PUC Service Date
                      </span>
                    </div>
                    <div className="form-control-wrap" style={{ width: "60%" }}>
                      <Controller
                        control={control}
                        name="pucExpiryDate"
                        render={({ field }) => (
                          <ReactDatePicker
                            placeholderText="Select date"
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value}
                          />
                        )}
                        {...register("pucExpiryDate")}
                      />
                    </div>
                  </div>
                  {errors.pucExpiryDate && (
                    <span className="invalid">
                      {errors.pucExpiryDate?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="form-control-wrap mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white"
                        id="inputGroup-sizing-lg"
                      >
                        GPS installed
                      </span>
                    </div>

                    <div className="custom-control form-control custom-control-sm custom-radio ">
                      <label htmlFor="gpsInstalled">
                        <input
                          type="radio"
                          value={true}
                          id="gpsInstalled"
                          {...register("gpsInstalled")}
                        />
                        Yes
                      </label>
                    </div>
                    <div className="custom-control form-control custom-control-sm custom-radio">
                      <label htmlFor="gpsInstalled">
                        <input
                          className="custom-control-input form-control ml-2"
                          type="radio"
                          value={false}
                          id="gpsInstalled"
                          {...register("gpsInstalled")}
                        />
                        No
                      </label>
                    </div>
                  </div>
                  {errors.gpsInstalled && (
                    <span className="invalid">
                      {errors.gpsInstalled?.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Max Weight Limit"
                  inputName={`weightLimit`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md="6" lg="6">
                <InputField
                  labelText="Buffer Limit"
                  inputName={`weightBuffer`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>

            <div className="my-4" />

            <Row>
              <Col />
              <Button onClick={handleSubmit(handleSaveItem)} color="success">
                {vehicleId ? "Update Vehicle" : "Save Vehicle"}
              </Button>
            </Row>
          </Form>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withProvider(VehicleMasterProvider, VehicleMasterCreatePage);
