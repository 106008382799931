import React from "react";
import Layout from "../../../layout/Index";
import HOTEL_MENU from "../MenuData";
import HotelRoute from "../routes/HotelRoute";
import { EnsureAutoplayPossible } from "../../../components/utils/EnsureAutoplayPossible";

const HotelLayout = (props) => {
  const renderRoutes = <HotelRoute />;

  return <>
    <EnsureAutoplayPossible/>
    <Layout menuData={HOTEL_MENU} routes={renderRoutes} {...props} />;
  </>
};

export default HotelLayout;
