import { LOG_HISTORIES_API } from "../../../constants/Endpoints";
import API from "../../../service/ApiService";
class LogHistoryRepository {
  async getLogHistories(queryParams) {
    const response = await API.get(LOG_HISTORIES_API, {
      queryParams: queryParams,
    });
    return response.data;
  }
}

const logHistoryRepository = new LogHistoryRepository();

export default logHistoryRepository;
