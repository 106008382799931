/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { OverlineTitle } from "../../../../components/Component";
import { Link } from "react-router-dom";
import { Controller, useFieldArray } from "react-hook-form";
import BillingTerms from "./BillingTermsForm";
import PaymentReminder from "./PaymentReminderForm";
import InputField from "../../../../components/utils/InputComponent";
import SelectField from "../../../../components/utils/SelectComponent";
import { HotelCustomerContext } from "../../providers/HotelCustomerProvider";
import ReactDatePicker from "react-datepicker";
import { formattedTimestamp, parseTimestamp } from "../../../../utils/Utils";

const POCDetails = (props) => {
  const { register, control, branchPOC, errors, branchIndex, branchPocData, setValue } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: branchPOC,
  });
  const [addButtonEnable, setAddButtonEnable] = useState(true);

  const hotelCustomerContext = React.useContext(HotelCustomerContext);
  const usersData = hotelCustomerContext.usersData || [];
  const userData = hotelCustomerContext.userData || {};
  const roles = hotelCustomerContext.roles || [];

  React.useEffect(()=>{
    setValue(`branchDetails[${branchIndex}].users[0].id`, userData?.id);
    setValue(`branchDetails[${branchIndex}].users[0].firstName`, userData?.firstName);
    setValue(`branchDetails[${branchIndex}].users[0].email`, userData?.email);
    setValue(`branchDetails[${branchIndex}].users[0].phoneNumber`, userData?.phoneNumber);
  }, [userData]);

  React.useEffect(()=>{
    roles.length && 
        setValue(`branchDetails[${branchIndex}].users[0].roleAccess`, roles[0].id);
  }, [roles]);

  React.useEffect(() => {
    hotelCustomerContext.getRoles();
    hotelCustomerContext.getUsers();
    hotelCustomerContext.getStockpointOptions();
    if (branchPocData) {
      branchPocData.forEach((element, index) => {
        setValue(`branchDetails[${branchIndex}].users[${index}].id`, element.hotelBranchUser?.id);
        setValue(`branchDetails[${branchIndex}].users[${index}].firstName`, element.hotelBranchUser?.firstName);
        setValue(`branchDetails[${branchIndex}].users[${index}].email`, element.hotelBranchUser?.email);
        setValue(`branchDetails[${branchIndex}].users[${index}].phoneNumber`, element.hotelBranchUser?.phoneNumber);
        setValue(`branchDetails[${branchIndex}].users[${index}].designation`, element.designation);
        setValue(`branchDetails[${branchIndex}].users[${index}].roleAccess`, element.hotelBranchUser?.roles[0]?.roleId);
        fields.push({ ...element });
      });
    }
  }, []);
  
  const handleSelectFieldChange = (e) => {
    e.target.value === "add_new" ? setAddButtonEnable(false) : setAddButtonEnable(true);
  };

  const handleSelectUserFieldChange = async (e) => {
    const params = e.target.value;
    await hotelCustomerContext.getUser(params);
  };

  return (
    <>
      <OverlineTitle tag="span" className="preview-title-lg">
        POC's
        <Row>
          <Col>
            <SelectField
              labelText="Select"
              inputName="POCAction"
              inputType="select"
              defaultValue="add_new"
              register={register}
              errors={errors}
              options={[
                { value: "add_existing", name: "Add Existing POC" },
                { value: "add_new", name: "Add New POC" },
              ]}
              handleChange={handleSelectFieldChange}
            />
          </Col>
          <Col>
            {addButtonEnable ? (
              <SelectField
                labelText="Choose User"
                inputName="existingPOCSelect"
                inputType="select"
                register={register}
                errors={errors}
                options={usersData.map((user) => ({
                  value: user.id,
                  name: `${user.phoneNumber} (${user.firstName})`,
                }))}
                handleChange={handleSelectUserFieldChange}
              />
            ) : (
              <Button
                color="info"
                disabled={addButtonEnable}
                onClick={() => {
                  append({
                    firstName: "",
                    email: "",
                    phoneNumber: "",
                    designation: "",
                    roleAccess: "",
                  });
                }}
              >
                {" "}
                + Add POC
              </Button>
            )}
          </Col>
        </Row>
      </OverlineTitle>
      {fields.map((item, pocIndex) => {
        return (
          <React.Fragment key={item.id}>
            <div className="my-4" />
            {/* <hr className="preview-hr my-2"></hr> */}
            <Row>
              <Col>
                <InputField
                  labelText="Full Name"
                  inputName={`branchDetails[${branchIndex}].users[${pocIndex}].firstName`}
                  inputType="text"
                  register={register}
                  errors={errors}
                  required={true}
                />
              </Col>
              <Col>
                <InputField
                  labelText="Email"
                  inputName={`branchDetails[${branchIndex}].users[${pocIndex}].email`}
                  inputType="text"
                  register={register}
                  errors={errors}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputField
                  labelText="Contact Number"
                  inputName={`branchDetails[${branchIndex}].users[${pocIndex}].phoneNumber`}
                  inputType="text"
                  register={register}
                  errors={errors}
                  required={true}
                />
              </Col>
              <Col>
                <InputField
                  labelText="Designation"
                  inputName={`branchDetails[${branchIndex}].users[${pocIndex}].designation`}
                  inputType="text"
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectField
                  labelText="Role/Access"
                  inputName={`branchDetails[${branchIndex}].users[${pocIndex}].roleAccess`}
                  inputType="select"
                  register={register}
                  errors={errors}
                  options={
                    (roles.length &&
                      roles.map((role) => ({
                        value: role.id,
                        name: `${role.name}`,
                      }))) ||
                    []
                  }
                  required={true}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Link to="#">Reset Password</Link>
                  </Col>
                  <Col>
                    <Link to="#">Archive</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row></Row>
            {pocIndex > 0 ? (
              <Button
                color="danger"
                onClick={() => {
                  remove(pocIndex);
                }}
              >
                {" "}
                Delete POC
              </Button>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const BranchDetails = (props) => {
  const { item, index, register, errors, control, branchRemove, billingMethod, watch, setValue, customerData } = props;
  const name = watch("name");
  const city = watch("city");
  const addressLine_1 = watch("addressLine_1");
  const addressLine_2 = watch("addressLine_2");
  const postalCode = watch("postalCode");
  const [isChecked, setIsChecked] = useState(false);

  const hotelCustomerContext = React.useContext(HotelCustomerContext);
  const stockpointOptions = hotelCustomerContext.stockpointOptions || [];

  React.useEffect(() => {
    hotelCustomerContext.getStockpointOptions();
    if (customerData?.hotelBranches.length) {
      customerData?.hotelBranches.forEach((element, index) => {
        setValue(`branchDetails[${index}].id`, element.id);
        setValue(`branchDetails[${index}].name`, element.name);
        setValue(`branchDetails[${index}].city`, element.city);
        setValue(`branchDetails[${index}].addressLine_1`, element.addressLine_1);
        setValue(`branchDetails[${index}].addressLine_2`, element.addressLine_2);
        setValue(`branchDetails[${index}].postalCode`, element.postalCode);
        setValue(`branchDetails[${index}].mapUrl`, element.mapUrl);
        setValue(`branchDetails[${index}].latitude`, element.latitude);
        setValue(`branchDetails[${index}].longitude`, element.longitude);
        setValue(`branchDetails[${index}].stockpointId`, element.stockpointId);
        setValue(`branchDetails[${index}].route`, element.route);
        // setValue(
        //   `branchDetails[${index}].deliveryWindow`,
        //   element.deliveryWindow
        // );
        setValue(`branchDetails[${index}].indentClosingTime`, element.indentClosingTime);
      });
    } 
  }, []);

  const onClickStockpointField = (e) => {
    e.preventDefault();
    hotelCustomerContext.getStockpointOptions();
  };

  const onCheckBoxChange = (e) => {
    if (e.target.checked) {
      setValue(`branchDetails[0].name`, name);
      setValue(`branchDetails[0].city`, city);
      setValue(`branchDetails[0].addressLine_1`, addressLine_1);
      setValue(`branchDetails[0].addressLine_2`, addressLine_2);
      setValue(`branchDetails[0].postalCode`, postalCode);
    } else {
      setValue(`branchDetails[0].name`, "");
      setValue(`branchDetails[0].city`, "");
      setValue(`branchDetails[0].addressLine_1`, "");
      setValue(`branchDetails[0].addressLine_2`, "");
      setValue(`branchDetails[0].postalCode`, "");
    }
    setIsChecked(e.target.checked);
  };

  const stockPointOptions = stockpointOptions.map((stockpoint) => ({
    value: stockpoint.id,
    name: `${stockpoint.name} (${stockpoint.city})`,
  }));

  return (
    <React.Fragment>
      <Row>
        <Col>
          {index < 1 ? (
            <>
              <div className="form-control-wrap mb-3 custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  value="sameasabove"
                  id="sameasabove"
                  className="custom-control-input form-control custom-control-sm"
                  name="sameasabove"
                  onChange={onCheckBoxChange}
                  checked={isChecked}
                />
                <label className="custom-control-label" htmlFor="sameasabove">
                  {" "}
                  Same as above{" "}
                </label>
              </div>
              <>{errors.branchCity && <span className="invalid">{errors.branchCity?.message}</span>}</>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField labelText="Branch Name" inputName={`branchDetails[${index}].name`} inputType="text" register={register} errors={errors} required={true}/>
        </Col>
        <Col>
          <InputField labelText="City" inputName={`branchDetails[${index}].city`} inputType="text" register={register} errors={errors} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField
            labelText="Address Line1"
            inputName={`branchDetails[${index}].addressLine_1`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
        <Col>
          <InputField
            labelText="Address Line2"
            inputName={`branchDetails[${index}].addressLine_2`}
            inputType="text"
            register={register}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField labelText="Zipcode" inputName={`branchDetails[${index}].postalCode`} inputType="text" register={register} errors={errors} />
        </Col>
        <Col>
          <SelectField
            labelText="Stockpoint"
            inputType="stockpointId"
            inputName={`branchDetails[${index}].stockpointId`}
            register={register}
            errors={errors}
            options={stockPointOptions}
            handleClick={onClickStockpointField}
            required={true}
            defaultValue={customerData?.hotelBranches[`${index}`]?.stockpointId}
            /**
             * when the stockpoint options is fetched form server, if we don't remount, the select shows
             * the first item as selected
             */
            key={stockPointOptions.length}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <InputField labelText="Latitude" inputName={`branchDetails[${index}].latitude`} inputType="text" register={register} errors={errors} />
            </Col>
            <Col>
              <InputField
                labelText="Longitude"
                inputName={`branchDetails[${index}].longitude`}
                inputType="text"
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <InputField labelText="Address Map URL" inputName={`branchDetails[${index}].mapUrl`} inputType="text" register={register} errors={errors} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputField labelText="Route" inputName={`branchDetails[${index}].route`} inputType="text" register={register} errors={errors} />
        </Col>
        <Col>
          <div className="form-control-wrap mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text bg-white" id="inputGroup-sizing-lg">
                  {`Indent Closing Time`}
                  <span className="text-danger">*</span>
                </span>
                <Controller
                  control={control}
                  name={`branchDetails[${index}].indentClosingTime`}
                  render={({ field }) => {
                    return (
                      <ReactDatePicker
                        placeholderText="Select time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="HH:mm:ss"
                        onChange={(date) => {
                          field.onChange(formattedTimestamp({ timestamp: date.toISOString(), timeformat: "HH:mm:ss" }));
                        }}
                        selected={parseTimestamp(field.value, "HH:mm:ss")}
                      />
                    );
                  }}
                />
              </div>
            </div>
            {errors[`branchDetails[${index}].indentClosingTime`] && (
              <span className="invalid">{errors[`branchDetails[${index}].indentClosingTime`].message}</span>
            )}
          </div>
        </Col>
      </Row>
      <POCDetails
        register={register}
        control={control}
        field={item}
        branchPOC={`branchDetails[${index}].users`}
        errors={errors}
        branchIndex={index}
        branchPocData={customerData?.hotelBranches[`${index}`]?.branchPoc}
        setValue={setValue}
      />
      <BillingTerms
        mode={billingMethod}
        errors={errors}
        register={register}
        branchIndex={index}
        branchAccount={customerData?.hotelBranches[`${index}`]?.hotelBranchAccount}
        setValue={setValue}
      />
      <PaymentReminder
        errors={errors}
        register={register}
        branchIndex={index}
        control={control}
        field={item}
        paymentReminders={`branchDetails[${index}].paymentReminders`}
        paymentReminderData={customerData?.hotelBranches[`${index}`]?.accountReminders}
        setValue={setValue}
      />
      {index > 0 ? (
        <Button color="danger" onClick={() => branchRemove(index)}>
          {" "}
          Delete Branch
        </Button>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default BranchDetails;
