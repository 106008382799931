import { USER_MANAGE_GET_API } from "../constants/Endpoints";
import API from "../service/ApiService";

class UserManagementRepository {
  // TODO: PAGINATION
  async getUsers() {
    const response = await API.get(USER_MANAGE_GET_API);
    return response.data;
  }
}

const userManagementRepository = new UserManagementRepository();
export default userManagementRepository;
