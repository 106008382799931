import { useEffect } from "react";

export const useInterval = (callback, delay) => {
  useEffect(() => {
    let timeoutId = null;
    const run = () => {
      callback();
      timeoutId = setTimeout(run, delay);
    };
    run();

    return () => {
      clearTimeout(timeoutId);
    }
  }, [delay, callback]);
};
