import React, { useContext } from "react";
import { Button, Col, Row, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { BlockHeadContent, BlockTitle, Icon, OverlineTitle } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import * as yup from "yup";
import useYupValidationResolver from "../../../../hooks/useYupResolver";
import { useForm, useFieldArray, FormProvider, Controller } from "react-hook-form";
import classnames from "classnames";
import CustomerKYCForm from "./CustomerKYCForm";
import BranchDetails from "./BranchDetailsForm";
import { handleSuccess } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast } from "../../../../components/toastify/Toastify";
import { useHistory } from "react-router";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";
import { withProvider } from "../../../../hoc/withProvider";
import HotelCustomerProvider, { HotelCustomerContext } from "../../providers/HotelCustomerProvider";

const CREATE_TITLE_HEAD = "Create Prepay Customer |  Hotel";
const EDIT_TITLE_HEAD = "Edit Prepay Customer |  Hotel";
const BILLING_TERMS = "PREPAY";

const createValidationSchema = yup
  .object({
    name: yup.string().trim().required("Hotel name is required"),
    legalEntityName: yup.string().trim(),
    // .required("Legal entity name is required"),
    addressLine_1: yup.string().trim(), //.required("Address Line 1 is required"),
    addressLine_2: yup.string().trim(),
    postalCode: yup.string(), //.required("Zipcode is required"),
    city: yup.string().trim(), //.required("City is required"),
    gstin: yup.string().trim().optional("GST is required"),
    pan: yup.string().trim().optional("PAN is required"),
    gin: yup.string().trim().optional("GIN is required"),
    cin: yup.string().trim().optional("CIN is required"),
    category: yup.string().trim().required("Category is required"),
    tier: yup.string().required(),
    branchDetails: yup.array().of(
      yup.object().shape({
        name: yup.string().trim().required("Branch Name is required"),
        city: yup.string().trim(), //.required("Branch City is required"),
        addressLine_1: yup.string().trim(),
        //.required("Branch Address Line1 is required"),
        addressLine_2: yup.string().trim(),
        // .required("Branch Address Line2 is required"),
        postalCode: yup.string().trim(), //.required("Branch Zip is required"),
        latitude: yup.string().trim(),
        //.required("Branch Address Lat is required"),
        longitude: yup.string().trim(),
        //.required("Branch Address Long is required"),
        mapUrl: yup.string().trim(), //.required("Branch Address URL is required"),
        route: yup.string().trim(),
        pocName: yup.string().trim(), //.required("Billing POC Name is required"),
        pocPhoneNumber: yup.string().trim().min(10).max(10).required("Billing POC Number is required"),
        pocEmail: yup.string().trim(), //.required("Invoice Email is required"),
        mode: yup.string(),
        stockpointId: yup.number(),
        // deliveryWindow: yup.string().notRequired(),
        users: yup.array().of(
          yup.object().shape({
            firstName: yup.string().trim(), //.required("POC Fullname is required"),
            email: yup.string().trim().required("POC Email is required"),
            phoneNumber: yup.string().trim().required("POC Contact is required"),
            designation: yup.string().trim(),
            // .required("POC Designation is required"),
            roleAccess: yup.number().required("Select role"),
          })
        ),
        paymentReminders: yup.array().of(
          yup.object().shape({
            days: yup.string().required("Days required"),
            sequence: yup.string().required("Reminder event required"),
          })
        ),
      })
    ),
  })
  .required();

const AddNewPrepayCustomerPage = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { customerData, hotelId } = props;

  const history = useHistory();
  const { createHotelCustomer, updateHotelCustomer } = useContext(HotelCustomerContext);

  const handleSuccessConfirmation = async () => {
    handleSuccess({
      title: "Hotel Customer added Succefully",
    });
  };

  async function handleSaveItem(data) {
    if (hotelId) {
      await updateHotelCustomer(hotelId, data)
        .then((res) => {
          if (res) {
            history.push(HOTEL_ROUTE.customersList);
            handleSuccessConfirmation();
          }
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    } else {
      await createHotelCustomer(data)
        .then((res) => {
          if (res) {
            history.push(HOTEL_ROUTE.customersList);
            handleSuccessConfirmation();
          }
        })
        .catch((e) => {
          errorToast({ description: `${e}` });
        });
    }
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const initalizeBranchValues = () => {
    let initializeData = [];
    if (hotelId) {
      initializeData = customerData?.hotelBranches.map((hotelBranch) => ({
        name: "",
        city: "",
        addressLine_1: "",
        addressLine_2: "",
        postalCode: "",
        mapUrl: "",
        latitude: "",
        longitude: "",
        route: "",
        stockpointId: 1,
        users: [
          {
            firstName: "",
            email: "",
            phoneNumber: "",
            designation: "",
            roleAccess: "",
          },
        ],
        pocName: "",
        pocPhoneNumber: "",
        pocEmail: "",
      }));
    } else
      return [
        {
          name: "",
          city: "",
          addressLine_1: "",
          addressLine_2: "",
          postalCode: "",
          mapUrl: "",
          latitude: "",
          longitude: "",
          route: "",
          stockpointId: 1,
          users: [
            {
              firstName: "",
              email: "",
              phoneNumber: "",
              designation: "",
              roleAccess: 1,
            },
          ],
          pocName: "",
          pocPhoneNumber: "",
          pocEmail: "",
        },
      ];
    return initializeData;
  };

  const isEditMode = props?.match?.params?.id || false;
  const resolver = useYupValidationResolver(createValidationSchema);
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver,
    defaultValues: {
      name: "",
      legalEntityName: "",
      category: "PREPAY",
      tier: "b",
      branch: initalizeBranchValues(),
    },
  });

  const {
    fields: branchFields,
    remove: branchRemove,
    append: branchAppend,
  } = useFieldArray({
    control,
    name: `branch`,
  });

  const headTitle = isEditMode ? EDIT_TITLE_HEAD : CREATE_TITLE_HEAD;

  return (
    <React.Fragment>
      <FormProvider>
        <Head title={headTitle} />
        <Content>
          <BlockHeadContent>
            <BlockTitle page>Prepay Customer Form</BlockTitle>
          </BlockHeadContent>
          <div className="mt-4 p-4 bg-white rounded-sm">
            <Form className={"form-validate is-alter"} onSubmit={handleSubmit(handleSaveItem)}>
              <CustomerKYCForm register={register} errors={errors} customerData={customerData} hotelId={hotelId} setValue={setValue} />
              <div className="my-4" />
              <OverlineTitle tag="span" className="preview-title-lg">
                Branch Details
                <Button
                  color="info"
                  onClick={() =>
                    branchAppend({
                      branchName: "",
                      branchCity: "",
                      branchAddressLine1: "",
                      branchAddressLine2: "",
                      branchZipcode: "",
                      latitude: "",
                      longitude: "",
                      mapUrl: "",
                      poc: [
                        {
                          pocFullName: "",
                          pocEmail: "",
                          pocContactNumber: "",
                          pocDesignation: "",
                          pocRoleAccess: "",
                        },
                      ],
                      billingPOCName: "",
                      billingPOCNumber: "",
                      invoiceEmail: "",
                    })
                  }
                >
                  {" "}
                  + Add Branch
                </Button>
              </OverlineTitle>
              {branchFields.map((item, index) => {
                return (
                  <>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={classnames({
                            active: activeTab === index,
                          })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle(index);
                          }}
                        >
                          {`Branch ${index + 1}`}
                          <Icon name="chevron-down" className="chevron-down-icon" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={index}>
                        <div style={{ display: "block" }}>
                          <BranchDetails
                            item={item}
                            index={index}
                            register={register}
                            errors={errors}
                            control={control}
                            branchRemove={branchRemove}
                            billingMethod={BILLING_TERMS}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                            customerData={customerData}
                            Controller={Controller}
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </>
                );
              })}
              <Row>
                <Col />
                <Button onClick={handleSubmit(handleSaveItem)} color="primary">
                  {customerData ? "Update Item" : "Save Item"}
                </Button>
              </Row>
            </Form>
          </div>
        </Content>
      </FormProvider>
    </React.Fragment>
  );
};

export default withProvider(HotelCustomerProvider, AddNewPrepayCustomerPage);
