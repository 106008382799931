import React from "react";
import { Card, CardBody, CardLink, CardText, CardTitle } from "reactstrap";
import { BlockBetween } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { HOTEL_ROUTE } from "../../routes/HotelRoute";

const AddNewCustomerPage = (props) => {
  return (
    <React.Fragment>
      <Head title="Add New Customer |  Hotel" />
      <Content>
        <BlockBetween>
          <p>Add New Customer Page</p>
        </BlockBetween>
        <Card className="card-bordered">
          <CardLink href={HOTEL_ROUTE.addNewPostpayCustomer}>
            <CardBody className="card-inner">
              <CardTitle tag="h5">Postpay</CardTitle>
              <CardText>
                Click to create a new Postpay Customer
              </CardText>
            </CardBody>
          </CardLink>
        </Card>
        <Card className="card-bordered">
          <CardLink href={HOTEL_ROUTE.addNewPrepayCustomer}>
            <CardBody className="card-inner">
              <CardTitle tag="h5">Prepay</CardTitle>
              <CardText>
                Click to create a new Prepay Customer
              </CardText>
            </CardBody>
          </CardLink>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default AddNewCustomerPage;
