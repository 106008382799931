import React from "react";
import {
  BlockHeadContent,
  BlockTitle,
} from "../../../../../components/Component";
import { PERMISSIONS } from "../../../../../config/AppPermission";
import useAuthorization from "../../../../../hooks/useAuthorization";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";

const UserManagementCreateContainer = (props) => {
  useAuthorization([PERMISSIONS.CREATE_USER]);

  return (
    <React.Fragment>
      <Head title="Create User | Core App" />
      <Content>
        <BlockHeadContent>
          <BlockTitle page>User management create</BlockTitle>
        </BlockHeadContent>
      </Content>
    </React.Fragment>
  );
};

export default UserManagementCreateContainer;
