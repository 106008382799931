import {
  AUTH_LOGIN_API,
  AUTH_LOGIN_VERIFY_API,
  USER_LOOKUP,
} from "../constants/Endpoints";
import API from "../service/ApiService";

class AuthRepository {
  async requestLogin(payload) {
    const response = await API.post(AUTH_LOGIN_API, {
      body: payload,
    });

    return response.data;
  }

  async verifyLoginOtp(payload) {
    const response = await API.post(AUTH_LOGIN_VERIFY_API, {
      body: payload,
    });

    return response.data;
  }

  async userLookup() {
    const response = await API.get(USER_LOOKUP);
    return response.data;
  }
}

const authRepository = new AuthRepository();
export default authRepository;
