import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Icon } from "../Component";

export const handleSuccess = (props) => {
  const { title, text, icon } = props;
  Swal.fire({
    icon: "success",
    title: title || "data added successfully",
    text: text || "",
    //   focusConfirm: false,
  });
};

export const confirmationSweetAlert = (props) => {
  const { title, text, icon, confirmButtonText, handleConfirmation } = props;
  Swal.fire({
    title: title || "Are you sure?",
    text: text || "You won't be able to revert this!",
    icon: icon || "warning",
    showCancelButton: true,
    confirmButtonText: confirmButtonText || "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      handleConfirmation();
    }
  });
};
