import React from "react";
import { withProvider } from "../../../../hoc/withProvider";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import HotelDashboardProvider, {
  HotelDashboardContext,
} from "../../providers/DashboardProvider";
import chicken from "../../../../assets/images/chicken.png";
import fish from "../../../../assets/images/seafood.png";
import oil from "../../../../assets/images/oil.png";
import mutton from "../../../../assets/images/mutton.png";
import egg from "../../../../assets/images/egg.png";
import DashboardItemCountList from "./DashboardItemCountList";

import {
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import { Card, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import DashboardCard from "../../../../components/utils/DashboardCard";

const HotelDashboardPage = ({ ...props }) => {
  const dashboardContext = React.useContext(HotelDashboardContext);
  const categoryCount = dashboardContext?.categoryCount;
  const hotelwiseCount = dashboardContext?.hotelwiseCount;
  const tierwiseCount = dashboardContext?.tierwiseCount;
  const itemCount = dashboardContext?.itemCount;
  const overDueCount = dashboardContext?.overDueCount;

  const ICONS = {
    Chicken: chicken,
    Egg: egg,
    "Sea Food": fish,
    Mutton: mutton,
    Oil: oil,
  };

  React.useEffect(() => {
    dashboardContext.getDashboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <Head title="Hotel | Dashboard Page" />
      <BlockHeadContent>
        <BlockTitle
          tag="h2"
          style={{ fontSize: 20, color: "deepskyblue" }}
          className=" d-flex justify-content-center"
        >
          Dashboard
        </BlockTitle>
      </BlockHeadContent>
      <BlockHeadContent className="mb-3">
        <BlockTitle tag="h3" className="mb-3" style={{ color: "deepskyblue" }}>
          Order wise stats{" "}
        </BlockTitle>
      </BlockHeadContent>
      <BlockHeadContent>
        <BlockTitle small className="mt-3 mb-4" style={{ color: "deepskyblue" }}>
          Total Indent for tomorrow
        </BlockTitle>
      </BlockHeadContent>
      <Row className="mt-3 mb-2">
        <DashboardCard countData={categoryCount} icons={ICONS} limit={5} />
      </Row>
      <DashboardItemCountList itemCount={itemCount} />
      <BlockHeadContent>
        <BlockTitle small className="mt-3 mb-3" style={{ color: "deepskyblue" }}>
            Highest Order{" "}
            <span style={{ color: "deepskyblue" }}>(hotelwise)</span>
        </BlockTitle>
      </BlockHeadContent>
      <Row>
        <DashboardCard countData={hotelwiseCount} limit={5} hotelwise />
      </Row>
      <BlockHeadContent>
        <BlockTitle small className="mt-3 mb-3" style={{ color: "deepskyblue" }}>
            {" "}
            Total Indent (Hotel wise category)
        </BlockTitle>
      </BlockHeadContent>
      <Row className="mt-3 mb-2">
        <DashboardCard countData={tierwiseCount} limit={5} tierwise />
      </Row>
      <hr />
      <BlockHeadContent>
        <BlockTitle small className="mt-3 mb-3" style={{ color: "red" }}>
          Invoice OverDue
        </BlockTitle>
      </BlockHeadContent>
      <Row>
        {overDueCount &&
          Object.keys(overDueCount).map((itemKey, index) => {
            return index < 5  && (
              <Col key={itemKey}>
                <Card className="m-0" style={{ borderRadius: "10px" }}>
                  <div className="team ">
                    <div className="user-card user-card-s2 ">
                      <span style={{ fontWeight: "bolder" }} className="">
                        {itemKey}
                      </span>
                      <p
                        className="mb-0"
                        style={{ fontSize: "10px", fontWeight: "bolder" }}
                      >
                        {`Amount overdue: ${overDueCount[itemKey].amountOverDue}`}
                      </p>
                      <p style={{ fontSize: "10px", fontWeight: "bolder" }}>
                        {`Days: ${overDueCount[itemKey].day}`}
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            )
          })
        }
      </Row>
      <Row className="justify-content-end mt-3">
        <Link to="/hotels/dashboard/invoice-over-due-list" style={{ textDecoration: "underline", color: "blue" }}>
          View defaulter list
        </Link>
        <Icon name="arrow-long-right" className="mt-1" />
      </Row>
    </Content>
  );
};

export default withProvider(HotelDashboardProvider, HotelDashboardPage);
