import React, { useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import vehicleMasterRepository from "../repositories/VehicleMasterRepository";
import { emptyHandler } from "../../../utils/Utils";
import stockpointRepository from "../repositories/StockPointRepository";

export const VehicleMasterContext = React.createContext({
  isLoadingVehicles: false,
  vehiclesList: { vehicles: [], total: 0 },
  getVehicles: async () => emptyHandler,
  createVehicle: async () => emptyHandler,
  stockpointOptions: [],
  getStockpointOptions: async () => emptyHandler,
  getVehicle: async () => emptyHandler,
  vehicle: {},
  updateVehicle: async () => emptyHandler,
});

export const VehicleMasterProvider = (props) => {
  const toast = useCustomToast();

  const [isLoadingVehicles, ilvState] = useBoolean(false);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [stockpointOptions, setStockpointOptions] = useState([]);
  const [vehicle, setVehicle] = useState({});

  async function getVehicles(params) {
    try {
      ilvState.on();
      const response = await vehicleMasterRepository.getVehicles(params);

      setVehiclesList({
        vehicles: response.vehicles.items,
        total: response.vehicles.pagination.totalCount,
      });
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilvState.off();
    }
  }

  const createVehicle = async (createParams) => {
    try {
      ilvState.on();
      const res = await vehicleMasterRepository.createVehicle(createParams);
      return res;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilvState.off();
    }
  };

  async function getStockpointOptions() {
    try {
      ilvState.on();
      const response = await stockpointRepository.getStockpointOptions();
      setStockpointOptions(response.stockpointOptions);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ilvState.off();
    }
  }

  async function getVehicle(params) {
    try {
      ilvState.on();
      const response = await vehicleMasterRepository.getVehicle(params);
      setVehicle(response.vehicle);
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilvState.off();
    }
  }

  async function updateVehicle(params, data) {
    try {
      ilvState.on();
      const response = await vehicleMasterRepository.updateVehicle(
        params,
        data
      );
      return response.vehicle;
    } catch (e) {
      toast.errorToast(e.toString());
    } finally {
      ilvState.off();
    }
  }

  const mContext = {
    isLoadingVehicles,
    vehiclesList,
    getVehicles,
    createVehicle,
    stockpointOptions,
    getStockpointOptions,
    getVehicle,
    vehicle,
    updateVehicle,
  };

  return (
    <VehicleMasterContext.Provider value={mContext}>
      {props.children}
    </VehicleMasterContext.Provider>
  );
};

export default VehicleMasterProvider;
