import {
  INDENT_LIST_API,
  INDENT_CHANGE_STATUS_API,
  ACCEPT_ALL_INDENTS,
  INDENT_LIST_API_TODAY,
} from "../../../constants/Endpoints";
import API from "../../../service/ApiService";
class IndentRepository {
  async getIndentOverduesList() {
    const response = [];
    return response;
  }

  async getIndents(params) {
    const response = await API.get(INDENT_LIST_API, {
      queryParams: params,
    });
    return response.data;
  }

  async getIndentsToDownload(params) {
    const response = await API.get(INDENT_LIST_API_TODAY, {
      queryParams: params,
    });
    return response.data;
  }

  async changeStatus(params) {
    const response = await API.post(INDENT_CHANGE_STATUS_API, {
      body: params,
    });
    return response.data;
  }

  async acceptAllIndents(params) {
    const response = await API.post(ACCEPT_ALL_INDENTS, {
      queryParams: { date : params },
    });
    return response.data;
  }
}

const indentRepository = new IndentRepository();
export default indentRepository;
