import React, { useContext } from "react";
import { PERMISSIONS } from "../../../../../config/AppPermission";
import useAuthorization from "../../../../../hooks/useAuthorization";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import CustomerRateCard from "../CustomerRateCard";
import { withProvider } from "../../../../../hoc/withProvider";
import MasterRateCardProvider, {
  MasterRateCardContext,
} from "../../../providers/MasterRateCardProvider";

const CustomerRateCardListContainer = (props) => {
  const masterRateCardContext = useContext(MasterRateCardContext);
  const { getCategories } = masterRateCardContext;

  React.useEffect(() => {
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <Content>
        <CustomerRateCard />
      </Content>
    </React.Fragment>
  );
};
export default withProvider(
  MasterRateCardProvider,
  CustomerRateCardListContainer
);
