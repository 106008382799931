import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import EggTrackingLayout from "./pages/EggTrackingLayout";

const EggTrackingApp = (props) => {
  return (
    <Suspense>
      <Switch>
        <Route exact path="" component={EggTrackingLayout} />
      </Switch>
    </Suspense>
  );
};

export default EggTrackingApp;
