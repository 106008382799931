import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from "reactstrap";
import { Button, Icon } from "../../../../components/Component";
import { APP_MODULE_PREFIX } from "../../../../config/Permission";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { CoreContext } from "../../../../providers/CoreProvider";

const AccessibleAppsPage = ({ ...props }) => {
  const coreContext = useContext(CoreContext);
  const accessibleApps = coreContext.accessibleModules.map((am) => APP_MODULE_PREFIX[am]);

  function renderCardItem({ title, description, iconName, link }) {
    return (
      <div key={`accessible-apps-page-${link}`} className="col-sm-8 col-md-6 col-lg-4">
        <Card className="card-bordered h-100">
          <CardHeader className="px-3">
            <div className="row ml-0 align-items-center">
              {iconName && <Icon style={{ fontSize: 24, color: "secondary" }} name={iconName} />}
              <CardTitle className="ml-2" tag="h5">
                {title || ""}
              </CardTitle>
            </div>
          </CardHeader>
          <CardBody className="px-3 pb-0">{description && <CardText>{description}</CardText>}</CardBody>
          <CardFooter className="bg-white px-3 pt-2">
            {link && (
              <NavLink to={link}>
                <Button color="primary">Go to app</Button>
              </NavLink>
            )}
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Head title="Accessible Apps | Core App" />
      <Content>
        <div className="p-5 bg-white border-radius-sm">
          <p>Accessible apps</p>

          <div className="row g-gs">
            {accessibleApps.map((a) =>
              renderCardItem({
                title: a.title,
                iconName: a.icon,
                description: a.description,
                link: a.link,
              })
            )}
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default AccessibleAppsPage;
