import React from "react";
import { NavLink } from "react-router-dom";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../../components/Component";
import CustomTable from "../../../../components/table/CustomTable";
import { withProvider } from "../../../../hoc/withProvider";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import IndentProvider, { IndentContext } from "../../providers/IndentProvider";
import pdfDownload from "../../../../assets/images/pdfDownload.svg";
import print from "../../../../assets/images/print.svg";

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

const stockPointList = [
  {
    id: 1,
    name: "TT",
    amount: "1233",
    days: 23,
    typeOfCustomer: "hotel",
    edit: "",
  },
  {
    id: 1,
    name: "TT",
    amount: "1233",
    days: 23,
    typeOfCustomer: "hotel",
    edit: "",
  },
];

const IndentPage = (props) => {
  const indentContext = React.useContext(IndentContext);

  const [sm, updateSm] = React.useState(false);

  const indents = indentContext.indentsList.indents || stockPointList;

  /** @type {import("react-data-table-component").TableColumn[]} */
  const dataTableColumns = [
    {
      name: "Slno",
      selector: (row) => row.id,
      width: "8%",
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "Hotel name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Amount Overdue",
      selector: (row) => row.amount,
    },
    {
      name: "Days",
      selector: (row) => row.days,
    },
    {
      name: "Type of Customer",
      selector: (row) => row.typeOfCustomer,
    },
    {
      name: "Edit",
      selector: (row) => row.edit,
      cell: (row) => (
        <NavLink to="#">
          <span>Notify</span>
        </NavLink>
      ),
    },
  ];

  React.useEffect(() => {
    indentContext.getIndents();
  }, []);

  function handlePageChange(page) {
    console.log("page :>>> ", page);
  }

  function handleShowSizeChange(showSize) {
    console.log("showSize :>> ", showSize);
  }

  function renderIndentList() {
    return (
      <Block>
        <CustomTable
          data={indents}
          columns={dataTableColumns}
          pagination
          onPageChange={handlePageChange}
          onShowSizeChange={handleShowSizeChange}
        />
      </Block>
    );
  }

  function renderMainContent() {
    if (indentContext.isLoadingIndents) {
      return <Spinner />;
    }

    return renderIndentList();
  }

  function renderHeaderSection() {
    return (
      <BlockHead>
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Indent Overdue List</BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    );
  }

  return (
    <React.Fragment>
      <Head title="Indent Overdue | Core App" />

      <Content>
        {renderHeaderSection()}
        <div className="d-flex justify-content-end">
          {" "}
          <img className="m-2" src={pdfDownload} alt="" />
          <img className="m-2" src={print} alt="" />
        </div>
        {renderMainContent()}
      </Content>
    </React.Fragment>
  );
};

export default withProvider(IndentProvider, IndentPage);
