import React, { useState } from "react";
import { emptyHandler } from "../utils/Utils";
import useBoolean from "../hooks/useBoolean";
import { ACCESS_TOKEN } from "../constants/PreferenceKeys";
import authRepository from "../repositories/AuthRepository";
import { AUTH_ROUTES } from "../apps/auth/routes/AuthRoute";
import { APP_MODULES, APP_MODULE_PREFIX } from "../config/Permission";
import { CORE_APP_ROUTES } from "../apps/core/routes/CoreAppRoute";
import useCustomToast from "../hooks/useCustomToast";

// Basically auth context
export const CoreContext = React.createContext({
  isAuthDetermined: false,
  isAuthenticated: false,
  accessibleModules: [],
  currentUser: null,

  /**
   *
   * @param {[string]} permittedActions
   * @returns {bool}
   */
  canAccess: (permittedActions) => emptyHandler,

  // login
  isAuthenticating: false,
  onAuthSuccess: async () => emptyHandler,

  // lookup
  isLoadingUser: false,
  onUserLookup: async () => emptyHandler,
});

const CoreProvider = (props) => {
  const toast = useCustomToast();

  const [isAuthDetermined, iadState] = useBoolean(false);
  const [isAuthenticated, iaState] = useBoolean(false);
  const [isAuthenticating] = useBoolean(false);
  const [isLoadingUser, iluState] = useBoolean(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [accessibleModules, setAccessibleModules] = useState([]);

  function canAccess(permittedActions) {
    if (!permittedActions) {
      return true;
    }

    if (permittedActions.length <= 0) {
      return true;
    }

    const isAccessible = currentUser?.actions?.some((a) =>
      permittedActions.includes(a)
    );
    return isAccessible;
  }

  async function onAuthSuccess(response) {
    const token = response.token;
    localStorage.setItem(ACCESS_TOKEN, token.authToken);

    const lookupResponse = await onUserLookup();
    if (!lookupResponse) return;

    let goToLink = CORE_APP_ROUTES.accessibleApps;
    if (lookupResponse.accessibleApps.length === 1) {
      const app = lookupResponse.accessibleApps[0];
      goToLink = APP_MODULE_PREFIX[app].link;
    }

    window.history.pushState(
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      AUTH_ROUTES.login,
      goToLink
    );
    window.location.reload();
  }

  const onUserLookup = async () => {
    try {
      iadState.off();
      iluState.on();

      const response = await authRepository.userLookup();

      setCurrentUser(response.user);

      const accessibleApps = [
        ...Object.keys(APP_MODULE_PREFIX).filter((k) =>
          response.user.accessibleApps.includes(k)
        ),
        APP_MODULES.EGG_TRACKING_APP,
      ];
      setAccessibleModules(accessibleApps);

      iadState.on();
      iluState.off();
      iaState.on();

      return {
        ...response,
        accessibleApps,
      };
    } catch (e) {
      toast.errorToast(e?.message);

      iaState.off();
      iadState.on();
      iluState.off();
    }
  };

  const mContext = {
    isAuthDetermined,
    isAuthenticated,
    canAccess,

    currentUser,
    accessibleModules,

    isAuthenticating,
    onAuthSuccess,

    isLoadingUser,
    onUserLookup,
  };

  return (
    <CoreContext.Provider value={mContext}>
      {props.children}
    </CoreContext.Provider>
  );
};

export default CoreProvider;
