import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import { RedirectAs404 } from "../../../utils/Utils";
import AccessibleAppsPage from "../pages/accessible_apps/AccessibleAppsPage";
import UserManagementContainer from "../pages/user_management/containers/UserManagementContainer";
import UserManagementCreateContainer from "../pages/user_management/containers/UserManagementCreateContainer";

export const CORE_APP_ROUTES = {
  // user management
  userManagement: "/core/users/manage",
  userManageCreate: "/core/users/manage/create",
  userView: "/core/users/manage/:userId",
  userEdit: "/core/users/manage/:userId/edit",

  accessibleApps: "/core/apps/accessible",
};

const CoreAppRoute = (props) => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={CORE_APP_ROUTES.accessibleApps}
          component={AccessibleAppsPage}
        />

        <Route
          exact
          path={CORE_APP_ROUTES.userManagement}
          component={UserManagementContainer}
        />
        <Route
          exact
          path={CORE_APP_ROUTES.userManageCreate}
          component={UserManagementCreateContainer}
        />
        <Route
          exact
          path={CORE_APP_ROUTES.userManagement}
          component={UserManagementContainer}
        />
        <Route
          exact
          path={CORE_APP_ROUTES.userManagement}
          component={UserManagementContainer}
        />

        <Route component={RedirectAs404} />
      </Switch>
    </Suspense>
  );
};

export default CoreAppRoute;
