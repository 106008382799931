import React, { createContext, useState } from "react";
import useBoolean from "../../../hooks/useBoolean";
import useCustomToast from "../../../hooks/useCustomToast";
import { emptyHandler } from "../../../utils/Utils";
import dashboardRepository from "../repositories/DashboardRepository";

export const HotelDashboardContext = createContext({
  isLoadingDashboard: false,
  categoryCount: [],
  hotelwiseCount: [],
  tierwiseCount: [],
  itemData: {},
  overDueCount: {},
  overDueList:[],
  getDashboardDetails: async () => emptyHandler,
  getOverDueList: async () => emptyHandler,
});

const HotelDashboardProvider = (props) => {
  const toast = useCustomToast();
  const [isLoadingDashboard, ildState] = useBoolean(false);
  const [categoryCount, setCategoryCount] = useState([]);
  const [hotelwiseCount, setHotelwiseCount] = useState([]);
  const [tierwiseCount, setTierwiseCount] = useState([]);
  const [itemCount, setItemCount] = useState([]);
  const [overDueCount, setOverDueCount] = useState([]);
  const [overDueList, setOverDueList] = useState([]);

  async function getDashboardDetails(params) {
    try {
      ildState.on();
      const response = await dashboardRepository.getDashboardDetails();
      setCategoryCount(response.dashboardData.categoryCount);
      setHotelwiseCount(response.dashboardData.hotelwiseCount);
      setTierwiseCount(response.dashboardData.tierwiseCount);
      setItemCount(response.dashboardData.itemCount);
      setOverDueCount(response.dashboardData.overDueCount);
    } catch (error) {
      toast.errorToast(error.toString());
    } finally {
      ildState.off();
    }
  }

  async function getOverDueList(params) {
    try {
      ildState.on();
      const response = await dashboardRepository.getOverDueList();
      setOverDueList(response.overDueList)
    } catch (error) {
      toast.errorToast(error.toString());
    } finally{
      ildState.off();
    }
  }

  const mContext = {
    isLoadingDashboard,
    categoryCount,
    hotelwiseCount,
    tierwiseCount,
    itemCount,
    overDueCount,
    overDueList,
    getDashboardDetails,
    getOverDueList,
  };

  return (
    <HotelDashboardContext.Provider value={mContext}>
      {props.children}
    </HotelDashboardContext.Provider>
  );
};

export default HotelDashboardProvider;
