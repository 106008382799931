/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { CoreContext } from "../providers/CoreProvider";

const useAuthorization = (authorizeFor) => {
  const coreContext = useContext(CoreContext);
  const history = useHistory();

  useEffect(() => {
    const canAccess = coreContext.canAccess(authorizeFor);

    if (!canAccess) {
      history.push(`/errors/404-Modern`);
    }
  }, []);

  return null;
};

export default useAuthorization;
