import React, { Suspense } from "react";
import { Route, Switch } from "react-router";
import { RedirectAs404 } from "../../../utils/Utils";
import EggTrackingDashboard from "../pages/dashboard/DashboardPage";

export const EGG_TRACKING_ROUTE = {
  dashboard: "/egg-trackings/dashboard",
};

const EggTrackingRoute = (props) => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={EGG_TRACKING_ROUTE.dashboard}
          component={EggTrackingDashboard}
        />

        <Route component={RedirectAs404} />
      </Switch>
    </Suspense>
  );
};

export default EggTrackingRoute;
